import { useMemo } from "react";
import { useToken } from "@chakra-ui/react";
import { useWfClosePriceIndexQuery } from "src/api/client/price-chart.api";
import { getWfPriceTick } from "src/components/common/benchmark//utils/chart-util";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";

export const useWikifolioClosePriceChart = (wikifolioId: string, isQueryEnabled: boolean) => {
    const [green600] = useToken("colors", ["green.600"]);
    const dateTimeFormatter = useDateTimeFormatter();
    const { isLoading, data } = useWfClosePriceIndexQuery(wikifolioId, isQueryEnabled);

    const wfClosePriceSeries = useMemo(
        () => (data && data.timestamps.length > 0 ? getWfPriceTick(dateTimeFormatter, green600, data) : undefined),
        [data, dateTimeFormatter, green600]
    );

    return {
        wfClosePriceSeries,
        isLoading,
    };
};

export const certificateRatio = (multiplier: number): string => {
    if (multiplier > 1) {
        return `1:${multiplier}`;
    }

    if (multiplier === 1) {
        return "1:1";
    }

    let nominator = 1;

    while (multiplier < 1) {
        nominator *= 10;
        multiplier *= 10;
    }

    return `${nominator}:1`;
};

import { DAY_IN_MS } from "src/consts/time";

export const isSameYear = (date1: Date, date2: Date) => date1.getFullYear() === date2.getFullYear();

export const isSameYearAndMonth = (date1: Date, date2: Date) => isSameYear(date1, date2) && date1.getMonth() === date2.getMonth();

/**
 * Returns false if argument is Invalid Date and true otherwise.
 */
const isValid = (date: unknown): boolean => {
    return !isNaN(Number(date));
};

/**
 * Get the number of full day periods between the given dates.
 *
 * Returns difference in days, positive number, 0 if date are invalid.
 */
export const differenceInDays = (dateLeft: Date, dateRight: Date) => {
    if (isValid(dateLeft) && isValid(dateRight)) {
        const difference = dateLeft.getTime() - dateRight.getTime();
        return Math.abs(Math.ceil(difference / DAY_IN_MS));
    }
    return 0;
};

import { Box, Divider, Text } from "@chakra-ui/react";
import { WfPopover, PopoverButtonIconInfo } from "src/components/base/wf-popover";
import { getGlobals } from "src/consts/globals";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { useCertificateBoxContext } from "../context";
import { CBLabel } from "./label";

const CERTIFICATE_BOX_INVESTED_CAPITAL_TEST_ID = "certificate-box-invested-capital";

export const CBInvestedCapital = () => {
    const { data } = useCertificateBoxContext();
    const { formatNumber } = useNumberFormatter();

    const totalInvestments = `${getGlobals().currency} ${
        data.keyFigures.totalInvestments.ranking.value ? formatNumber(data.keyFigures.totalInvestments.ranking.value) : 0
    }`;

    const { label } = data.keyFigures.totalInvestments.ranking;

    return (
        <>
            <Box w="100%" data-test-id={CERTIFICATE_BOX_INVESTED_CAPITAL_TEST_ID}>
                <CBLabel>
                    <Text lineHeight="short">{label}</Text>
                    <WfPopover {...data.keyFigures.totalInvestments.tooltip!}>
                        <PopoverButtonIconInfo ariaLabel={label} ml={1} />
                    </WfPopover>
                </CBLabel>
                <Text as="strong">{totalInvestments}</Text>
            </Box>
            <Divider borderColor="gray.100" />
        </>
    );
};

import { UseQueryOptions } from "react-query";
import { API_SAVINGS_PLAN_MODAL_URL } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";
import { ISavingsPlanData, ISavingsPlanDict } from "./invest.api";

export interface ISavingsPlanResponse {
    data: ISavingsPlanData;
    dict: ISavingsPlanDict;
}

interface IRequest {
    wikifolioSecurityId: string;
    isin: string;
    wkn: string;
}

export function useSavingsPlanModalQuery({ wikifolioSecurityId, isin, wkn }: IRequest, options: UseQueryOptions<ISavingsPlanResponse>) {
    return useQuery<ISavingsPlanResponse>(API_SAVINGS_PLAN_MODAL_URL, {
        queryParams: new URLSearchParams({
            wikifolioSecurityId,
            isin,
            wkn,
        }),
        ...options,
    });
}

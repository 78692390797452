import { Box, SimpleGrid, Skeleton } from "@chakra-ui/react";

export const LoadingState = () => (
    <Box pb={7}>
        <Skeleton h={3} mt={4} />
        <SimpleGrid columns={[2, 4]}>
            {[1, 2, 3, 4].map(key => (
                <Skeleton key={key} w={10} h={2} mt={2} mx="auto" />
            ))}
        </SimpleGrid>
    </Box>
);

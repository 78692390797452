import { Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useCertificateBoxContext } from "../context";

export const CBHeader = () => {
    const { data } = useCertificateBoxContext();
    const { t } = useTranslation("wf-detail");

    const wikifolioCertificateHeadline = data.certificates.length > 1 ? t("headlines.wikifolio-certificates") : t("headlines.wikifolio-certificate");

    return (
        <Text as="strong" textAlign="center">
            {wikifolioCertificateHeadline}
        </Text>
    );
};

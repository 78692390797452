import { Text, useBoolean } from "@chakra-ui/react";
import { ITradingIdeaSectionDict, IWikifolioDetailProps } from "src/api/server/wf-detail.types";
import { SpanBox } from "src/components/base";
import { ExpandableText } from "src/components/common/expandable-text";

const TRADING_IDEA_CONTENT_TEST_ID = "trading-idea-content";
const TRADING_IDEA_TOGGLE_TRANSLATION_TEST_ID = "trading-idea-toggle-translation";

export interface ITradingIdeaProps {
    dict: ITradingIdeaSectionDict;
    isTranslationNecessary: IWikifolioDetailProps["data"]["isTranslationNecessary"];
    description: IWikifolioDetailProps["data"]["wikifolio"]["description"];
    canCalculateHeight?: boolean;
}

export const TradingIdea = ({ dict, isTranslationNecessary, description, canCalculateHeight }: ITradingIdeaProps) => {
    const [isTranslatedDescriptionDisplayed, setIsTranslatedDescriptionDisplayed] = useBoolean(true);

    return (
        <>
            <ExpandableText
                formattingType="plainText"
                text={isTranslatedDescriptionDisplayed ? description.content : description.originalContent}
                textProps={{ whiteSpace: "pre-line" }}
                noOfLines={4}
                data-test-id={TRADING_IDEA_CONTENT_TEST_ID}
                canCalculateHeight={canCalculateHeight}
            />

            {isTranslationNecessary &&
                (description.isTranslationAvailable ? (
                    <>
                        <Text color="gray.400" whiteSpace="pre-line">
                            {isTranslatedDescriptionDisplayed ? dict.contentTranslatedByWikifolio : dict.originalWikifolioDescriptionContent}
                        </Text>
                        <SpanBox
                            role="button"
                            fontWeight="bold"
                            fontSize="sm"
                            onClick={setIsTranslatedDescriptionDisplayed.toggle}
                            data-test-id={TRADING_IDEA_TOGGLE_TRANSLATION_TEST_ID}
                        >
                            {isTranslatedDescriptionDisplayed ? dict.showOriginalText : dict.showTranslatedText}
                        </SpanBox>
                    </>
                ) : (
                    <Text color="gray.400">{dict.contentNotAvailableInLanguage}</Text>
                ))}
        </>
    );
};

import { Text } from "@chakra-ui/react";
import { WfLinkGhost } from "src/components/base/wf-link";

export interface ITradernameProfileLinkProps {
    href: string;
    name: string;
    isDeleted: boolean | undefined;
}

export const WfTradernameProfileLink = ({ href, name, isDeleted }: ITradernameProfileLinkProps) => {
    return isDeleted ? (
        <Text fontWeight="bold">{name}</Text>
    ) : (
        <WfLinkGhost noOfLines={1} wordBreak="break-all" href={href}>
            {name}
        </WfLinkGhost>
    );
};

import { useRef } from "react";
import { Box } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { IPortfolioTableDict } from "src/api/server/wf-detail.types";
import { ErrorState } from "../shared/error-state";
import { LoadingState } from "./loading-state";
import { ISortTableWrapper } from "./sort-table-wrapper";
import { usePortfolioTable } from "./use-portfolio-table";

const SortTableWrapper = dynamic<ISortTableWrapper>(() => import("./sort-table-wrapper").then(component => component.SortTableWrapper), {
    loading: () => <LoadingState />,
});

interface IPortfolioTableProps {
    fullName: string;
    dict: IPortfolioTableDict;
}

export const PortfolioTable = ({ fullName, dict }: IPortfolioTableProps) => {
    const sectionRef = useRef<HTMLDivElement>(null);
    /**
     * Component starts to fetch data when it is in the screen view.
     */
    const { isLoading, isError, data, onSortName, onSortPerfSinceBuy, onSortPerfToday, onSortWeighting } = usePortfolioTable(fullName, sectionRef);

    return (
        <Box ref={sectionRef} aria-live="polite" aria-busy={isLoading}>
            {isError && (
                <ErrorState>
                    <LoadingState sx={{ div: { animation: "none" } }} />
                </ErrorState>
            )}
            {!isError && !data && <LoadingState sx={{ div: { animation: isLoading ? "initial" : "none" } }} />}
            {data && (
                <SortTableWrapper
                    dict={dict}
                    data={data}
                    onSortName={onSortName}
                    onSortPerfSinceBuy={onSortPerfSinceBuy}
                    onSortPerfToday={onSortPerfToday}
                    onSortWeighting={onSortWeighting}
                />
            )}
        </Box>
    );
};

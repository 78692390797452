import { useEffect, useRef } from "react";
import { QueryObserver } from "react-query";
import useViewportSpy from "src/hooks/dom/use-viewport-spy";
import { EnumJumpMarkSection } from "../jump-marks/consts";
import { useJumpMarkAnchorRefs } from "../jump-marks/jump-mark-anchor-refs-provider";

type IProps = {
    refetch: QueryObserver["refetch"];
    sectionRef: { current: HTMLDivElement | null };
};
export function useCallbackOnVisibility({ refetch, sectionRef }: IProps) {
    const { scrollName } = useJumpMarkAnchorRefs();
    const isFirstFetch = useRef<boolean>(false);

    const isReadyToLoadFeed = scrollName === null || scrollName === EnumJumpMarkSection.Feed;
    const isVisible = useViewportSpy(isFirstFetch.current ? { current: null } : sectionRef, { threshold: 0.1 });

    useEffect(() => {
        if (isVisible && !isFirstFetch.current && isReadyToLoadFeed) {
            refetch();
            isFirstFetch.current = true;
        }
    }, [refetch, isVisible, isReadyToLoadFeed]);
}

import { Flex, Text, VStack } from "@chakra-ui/react";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { IconCertificate } from "src/components/icons";
import { useCertificateBoxContext } from "../context";

const CERTIFICATE_COLLATERAL_INFO_TEST_ID = "certificate-collateral-info";

export const Certificate = () => {
    const { dict } = useCertificateBoxContext();

    return (
        <VStack w="100%" spacing={1}>
            <Flex w="100%" justify="space-between" align="center" fontSize="sm">
                <Text as="strong">{dict.collateralization}</Text>
                <IconCertificate boxSize={4} />
            </Flex>
            <SimpleRichText fontSize="xs" text={dict.collateralInfo} data-test-id={CERTIFICATE_COLLATERAL_INFO_TEST_ID} />
        </VStack>
    );
};

import { useState } from "react";
import { Box, Divider, Flex, Text, VStack } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "next-i18next";
import { IErrorResponse } from "src/api/client/types";
import { useAddWatchlistWikifoliosMutation, useRemoveWatchlistWikifoliosMutation } from "src/api/client/watchlist.api";
import { MotionBox, MotionBoxProps } from "src/components/base";
import { Button } from "src/components/base/button";
import { OverlayLoadingSpinner } from "src/components/base/overlay-loading-spinner";
import { WfPopover, PopoverButtonIconInfo } from "src/components/base/wf-popover";
import { WfSlider } from "src/components/base/wf-slider";
import { getGlobals } from "src/consts/globals";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { useUserStore } from "src/stores/user-store";
import { useWikifolioStore } from "src/stores/wikifolio-store";
import { CBDetailsAccordion } from "../components/details-accordion";
import { CBHeader } from "../components/header";
import { CBPrices } from "../components/prices";
import { CBProgressBar } from "../components/progress-bar";
import { CBRatioTabBar } from "../components/ratio-tab-bar";
import { CBCertificateFee, CBPerformanceFee } from "../components/statistics";
import { useCertificateBoxContext } from "../context";

const INVESTMENT_MIN = 100;
const INVESTMENT_MAX = 5000;
const INVESTMENT_STEP = 100;

const PREBOOK_MOTION_BOX_NOTWATCHLISTED_TEST_ID = "prebook-motion-box-notwatchlisted";
const PREBOOK_MOTION_BOX_WATCHLISTED_TEST_ID = "prebook-motion-box-watchlisted";

const PrebookMotionBox = (props: MotionBoxProps) => (
    <MotionBox
        pos="relative"
        overflow="hidden"
        mx={-2}
        px={2}
        variants={{
            open: {
                height: "auto",
                opacity: 1,
            },
            closed: {
                height: "0px",
                opacity: 0,
            },
        }}
        initial="closed"
        animate="open"
        exit="closed"
        transition={{ bounce: 0, duration: 0.4 }}
        {...props}
    />
);

export const CertificateBoxPublished = () => {
    const { t } = useTranslation("common");
    const { openLoginModal } = useLinkBuilder();
    const toast = useWfToast();
    const { isCurrentUser, isLoggedIn } = useUserStore();
    const {
        dict,
        data: { trader, simplifiedExchangeRate, wikifolio, certificates, tooltips },
        selectedCertificate: certificate,
    } = useCertificateBoxContext();

    const { watchlist, setOnWatchlist } = useWikifolioStore();
    const isOnWatchlist = watchlist[wikifolio.id] ?? false;

    const { formatNumber } = useNumberFormatter();

    const prebookValues = {
        min: INVESTMENT_MIN * simplifiedExchangeRate,
        max: INVESTMENT_MAX * simplifiedExchangeRate,
        step: INVESTMENT_STEP * simplifiedExchangeRate,
    };

    const [buyingInterests, setBuyingInterests] = useState(certificate.buyingInterests!.currentValue);
    const [reservedCapital, setReservedCapital] = useState(certificate.reservedCapital!.currentValue);
    const [intendedInvestment, setIntendedInvestment] = useState((wikifolio.intendedInvestment || INVESTMENT_MIN) * simplifiedExchangeRate);
    const { mutatePromise: addWatchlist } = useAddWatchlistWikifoliosMutation(wikifolio.id);
    const { mutatePromise: removeWatchlist } = useRemoveWatchlistWikifoliosMutation(wikifolio.id);
    const [isLoading, setLoading] = useState(false);

    const alwaysShowOperationalMessage = certificate.hasBlockedReason || !trader.isLegitimized;
    const showOperationalMessage = dict.operationalMessage && (alwaysShowOperationalMessage || !isOnWatchlist);

    const onPrebook = async () => {
        if (!isLoggedIn) {
            openLoginModal();
            return;
        }

        try {
            setLoading(true);
            await addWatchlist({ intendedInvestment: intendedInvestment / simplifiedExchangeRate });
            setReservedCapital(reservedCapital + intendedInvestment);
            setBuyingInterests(buyingInterests + 1);
            setOnWatchlist(wikifolio.id, true);
            toast({ status: EnumToastStatus.Success, title: t("add-wikifolio-success") });
        } catch (result) {
            setLoading(false);
            toast({ status: EnumToastStatus.Error, title: (result as IErrorResponse)?.message || t("general-error") });
        }
    };

    const onUndoPrebook = async () => {
        try {
            setLoading(true);
            await removeWatchlist();
            setReservedCapital(reservedCapital - intendedInvestment);
            setBuyingInterests(buyingInterests - 1);
            setOnWatchlist(wikifolio.id, false);
            toast({ status: EnumToastStatus.Success, title: t("remove-wikifolio-success") });
        } catch (result) {
            setLoading(false);
            toast({ status: EnumToastStatus.Error, title: (result as IErrorResponse)?.message || t("general-error") });
        }
    };

    return (
        <>
            <CBHeader />
            {certificates.length > 1 && <CBRatioTabBar />}
            <CBPrices />
            <CBDetailsAccordion>
                <VStack spacing={2} mb={1}>
                    <VStack w="100%" px={2} spacing={2}>
                        <CBCertificateFee />
                        <CBPerformanceFee />
                    </VStack>
                    <Box w="100%" px={2}>
                        <Divider borderColor="gray.100" />
                    </Box>
                    <Box w="100%" px={2} fontSize="xs">
                        {dict.publishedCriteriaParagraph}
                    </Box>
                    <VStack w="100%" spacing={0.5}>
                        <CBProgressBar {...certificate.testPhaseDays!} description={dict.publishedTestPhase} />
                        <CBProgressBar
                            currentValue={buyingInterests}
                            requiredValue={certificate.buyingInterests!.requiredValue}
                            description={dict.publishedBuyingInterests}
                        />
                        <CBProgressBar
                            prefix={getGlobals().currency}
                            currentValue={reservedCapital}
                            requiredValue={certificate.reservedCapital!.requiredValue}
                            description={dict.publishedReservedCapital}
                        />
                    </VStack>
                    {showOperationalMessage && (
                        <Box w="100%" px={2} fontSize="xs">
                            {dict.operationalMessage}
                        </Box>
                    )}
                </VStack>
            </CBDetailsAccordion>
            {!isCurrentUser(trader) && (
                <Box w="100%">
                    <AnimatePresence mode="wait" onExitComplete={() => setLoading(false)}>
                        {!isOnWatchlist && (
                            <PrebookMotionBox key="notwatchlisted" data-test-id={PREBOOK_MOTION_BOX_NOTWATCHLISTED_TEST_ID}>
                                <VStack spacing={3} w="100%">
                                    <VStack spacing={1} w="100%">
                                        <Flex align="center" fontSize="sm" fontWeight="bold" w="100%">
                                            {dict.prebookTitle}
                                            <WfPopover {...tooltips.emissionRequired!}>
                                                <PopoverButtonIconInfo ariaLabel={dict.prebookTitle} ml={1} />
                                            </WfPopover>
                                        </Flex>
                                        <Text w="100%" fontSize="xs">
                                            {dict.prebookParagraph}
                                        </Text>
                                    </VStack>
                                    <VStack w="100%" spacing={2}>
                                        <Text w="100%">
                                            {getGlobals().currency} <strong>{formatNumber(intendedInvestment)}</strong>
                                        </Text>
                                        <Box w="100%">
                                            <WfSlider size="sm" {...prebookValues} value={intendedInvestment} onChange={setIntendedInvestment} />
                                        </Box>
                                    </VStack>
                                    <Button w="100%" colorScheme="green" size="sm" onClick={onPrebook}>
                                        {dict.prebookButton}
                                    </Button>
                                </VStack>
                                {isLoading && <OverlayLoadingSpinner />}
                            </PrebookMotionBox>
                        )}
                        {isOnWatchlist && (
                            <PrebookMotionBox key="watchlisted" data-test-id={PREBOOK_MOTION_BOX_WATCHLISTED_TEST_ID}>
                                <VStack spacing={3} w="100%">
                                    <VStack spacing={1} w="100%">
                                        <Flex align="center" fontSize="sm" fontWeight="bold" w="100%">
                                            {dict.prebookDoneTitle}
                                        </Flex>
                                        <Text w="100%" fontSize="xs">
                                            {dict.prebookDoneParagraph}
                                        </Text>
                                    </VStack>
                                    <Button w="100%" size="sm" variant="outline" onClick={onUndoPrebook}>
                                        {dict.prebookDoneButton}
                                    </Button>
                                </VStack>
                                {isLoading && <OverlayLoadingSpinner />}
                            </PrebookMotionBox>
                        )}
                    </AnimatePresence>
                </Box>
            )}
        </>
    );
};

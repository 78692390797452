import { BoxProps, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FullSizeFlexCentered } from "src/components/base";
import { IconHidden } from "src/components/icons";
import { ChartLoadingState } from "./chart-loading-state";

export const ChartNoDataState = (boxProps: BoxProps) => {
    const { t } = useTranslation("common");

    return (
        <ChartLoadingState {...boxProps}>
            <FullSizeFlexCentered direction="column" align="center">
                <IconHidden boxSize="136px" />
                <Text fontSize="lg" fontWeight="semibold" mt={2}>
                    {t("no-data")}
                </Text>
            </FullSizeFlexCentered>
        </ChartLoadingState>
    );
};

import { UseQueryOptions } from "react-query";
import { IFeedWikifolioNewsResponse } from "src/api/client/feed-wikifolio-news.api.types";
import { API_FEED_V3_NEWS_WIKIFOLIO } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";

export const useFeedWikifolioNewsQuery = (wikifolioId: string, options: UseQueryOptions<IFeedWikifolioNewsResponse>) => {
    return useQuery<IFeedWikifolioNewsResponse>(API_FEED_V3_NEWS_WIKIFOLIO, {
        queryParams: new URLSearchParams({
            wikifolioId,
        }),
        ...options,
    });
};

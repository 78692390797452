import { Fragment } from "react";
import { Divider, Stack, VStack } from "@chakra-ui/react";
import { IWikifolioKeyFigureGroup } from "src/api/server/wf-detail.types";
import { WfHeading } from "src/components/base/wf-heading";
import { KeyFigure } from "src/components/common/key-figure";

interface IKeyFigureGroupProps {
    keyFigureGroup: IWikifolioKeyFigureGroup;
    showHeadline?: boolean;
}

export const KeyFigureGroup = ({ keyFigureGroup, showHeadline }: IKeyFigureGroupProps) => {
    const { label, rankings } = keyFigureGroup;

    const pivot = Math.ceil(rankings.length / 2);
    const firstColumnRankings = rankings.slice(0, pivot);
    const secondColumnRankings = rankings.slice(pivot);

    return (
        <>
            {showHeadline && (
                <WfHeading as="h3" fontSize="md" mb={1}>
                    {label}
                </WfHeading>
            )}
            <Stack direction={["column", "row"]} spacing={[0, 5]} w="100%" fontSize="sm">
                <VStack w="100%" spacing={0}>
                    {firstColumnRankings.map((ranking, index) => (
                        <Fragment key={index}>
                            <KeyFigure {...ranking} />
                            <Divider borderColor="gray.100" />
                        </Fragment>
                    ))}
                </VStack>
                <VStack w="100%" spacing={0}>
                    {secondColumnRankings.map((ranking, index) => (
                        <Fragment key={index}>
                            <KeyFigure key={index} {...ranking} />
                            <Divider borderColor="gray.100"></Divider>
                        </Fragment>
                    ))}
                </VStack>
            </Stack>
        </>
    );
};

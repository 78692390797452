import { Box, BoxProps, Flex, Skeleton } from "@chakra-ui/react";

interface ILoadingStateProps extends BoxProps {}
export const LoadingState = (props: ILoadingStateProps) => {
    return (
        <Box aria-hidden={true} {...props}>
            <Flex align="center" justify="space-between" h={8}>
                <Box flexGrow={1}>
                    <Skeleton h={1} w={8} />
                </Box>
                <Skeleton h={1} w={8} flexShrink={0} ml={4} />
                <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
                <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
                <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
            </Flex>
            <Flex align="center" justify="space-between" h={8} borderY="2px solid" borderColor="gray.100">
                <Skeleton w="200px" h={2} />
                <Skeleton w={8} h={2} />
            </Flex>
            {[1, 2, 3, 4, 5].map(i => (
                <Flex key={i} align="center" justify="space-between" h={8} borderBottom={i !== 5 ? "1px" : undefined} borderColor="gray.100">
                    <Box flexGrow={1}>
                        <Skeleton h={1} w={15} />
                    </Box>
                    <Skeleton h={1} w={8} flexShrink={0} ml={4} />
                    <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
                    <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
                    <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
                </Flex>
            ))}
            <Flex align="center" justify="space-between" h={8} borderY="2px solid" borderColor="gray.100">
                <Skeleton w={14} h={2} />
                <Skeleton w={8} h={2} />
            </Flex>
        </Box>
    );
};

import { Box, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button } from "src/components/base/button";
import { WfPopover, PopoverButtonIconInfo } from "src/components/base/wf-popover";
import { useCertificateBoxContext } from "../context";
import { certificateRatio } from "../utils";
import { CBLabel } from "./label";

const RATIO_TAB_BAR_TEST_ID = "ratio-tab-bar";
const RATIO_TAB_BAR_BUTTON_TEST_ID = "ratio-tab-bar-button";

export const CBRatioTabBar = () => {
    const { data, selectedCertificate, updateSelectedCertificate } = useCertificateBoxContext();
    const { certificates } = data;
    const { t } = useTranslation("wf-detail");

    return (
        <Box w="100%" my={2} data-test-id={RATIO_TAB_BAR_TEST_ID}>
            <CBLabel mb={1} justifyContent="center">
                <Text color="gray.800">{t("certificate.ratio")}</Text>
                <WfPopover {...data.tooltips.ratio!}>
                    <PopoverButtonIconInfo ariaLabel={t("certificate.ratio")} ml={1} />
                </WfPopover>
            </CBLabel>
            <Stack spacing={1} direction="row" align="center" backgroundColor="gray.100" h={5} borderRadius="6px" p={1 / 2}>
                {certificates.map(certificate => (
                    <Button
                        key={certificate.isin}
                        colorScheme="teal"
                        h={4}
                        borderRadius="4px"
                        w="full"
                        backgroundColor={certificate.isin === selectedCertificate.isin ? "white" : "gray.100"}
                        fontSize="sm"
                        fontWeight="bold"
                        onClick={() => updateSelectedCertificate(certificate.isin)}
                        data-test-id={RATIO_TAB_BAR_BUTTON_TEST_ID}
                    >
                        {certificateRatio(certificate.exchangeRatioMultiplier)}
                    </Button>
                ))}
            </Stack>
        </Box>
    );
};

import { ISearchUnderlying, ISearchWikifolio } from "src/api/client/search-autocomplete.api";
import { IBenchmarkIndexListDynamic } from "src/components/common/benchmark/hooks/use-benchmark-state";
import { DYNAMIC_BENCHMARK_COLORS, IBenchmarkIndexListStatic } from "../configs";

export function isDynamicBenchmark(b: IBenchmarkIndexListDynamic | IBenchmarkIndexListStatic): b is IBenchmarkIndexListDynamic {
    return "isDynamic" in b && "wikifolioId" in b;
}

export function isSearchWikifolio(w: ISearchWikifolio | ISearchUnderlying): w is ISearchWikifolio {
    return "id" in w;
}

/**
 * Resolves the color scheme for benchmark checkbox
 */
export const getColorScheme = (benchmarkListDynamic: IBenchmarkIndexListDynamic[]): string =>
    DYNAMIC_BENCHMARK_COLORS.reduce((acc, color) => {
        if (benchmarkListDynamic.length > 0 && benchmarkListDynamic.some(b => b.colorScheme === color)) {
            return acc;
        } else {
            return color;
        }
    }, DYNAMIC_BENCHMARK_COLORS[benchmarkListDynamic.length]);

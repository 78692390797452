import { Stack, StackDivider } from "@chakra-ui/react";
import { IWikifolioDetailProps } from "src/api/server/wf-detail.types";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { useDetailPageStore } from "src/stores/detail-page-store";
import { MasterDataItem } from "./master-data-item";

const MASTER_DATA_TEST_ID = "master-data";

interface IMasterDataProps {
    masterData: IWikifolioDetailProps["data"]["masterData"];
}

export const MasterData = ({ masterData }: IMasterDataProps) => {
    const { formatNumber } = useNumberFormatter();
    const { formatDateShort } = useDateTimeFormatter();

    const { indexLevel } = useDetailPageStore();
    const formattedIndexLevel = indexLevel ? formatNumber(indexLevel, 1) : "-";

    return (
        <Stack divider={<StackDivider borderColor="gray.100" />} spacing={2} data-test-id={MASTER_DATA_TEST_ID}>
            <MasterDataItem {...masterData.symbol} />
            <MasterDataItem {...masterData.creationDate} value={formatDateShort(new Date(masterData.creationDate.value))} />
            <MasterDataItem {...masterData.indexLevel} value={formattedIndexLevel} />
            {masterData.highWatermark && <MasterDataItem {...masterData.highWatermark} value={formatNumber(masterData.highWatermark.value, 1)} />}
        </Stack>
    );
};

import { useRef } from "react";
import { Box, Stack } from "@chakra-ui/react";
import { IWikifolioDetailData, IWikifolioDetailDict } from "src/api/server/wf-detail.types";
import { WfHeading } from "src/components/base/wf-heading";
import useViewportSpy from "src/hooks/dom/use-viewport-spy";
import { useBenchmarkReadyStore } from "../store/benchmark-ready-store";
import { InvestmentUniverse } from "./investment-universe";
import { MasterData } from "./master-data";
import { TradingIdea } from "./trading-idea";

export interface ITradingIdeaSectionProps {
    isTranslationNecessary: IWikifolioDetailData["isTranslationNecessary"];
    description: IWikifolioDetailData["wikifolio"]["description"];
    masterData: IWikifolioDetailData["masterData"];
    investmentUniverseData: IWikifolioDetailData["investmentUniverseData"];
    dict: Pick<IWikifolioDetailDict, "tradingIdeaSection" | "investmentUniverseTitle" | "superWikifolioInvestmentUniverseParagraph">;
}

export const TradingIdeaSection = (props: ITradingIdeaSectionProps) => {
    const { dict, isTranslationNecessary, description, masterData, investmentUniverseData } = props;
    const { isBenchmarkReady } = useBenchmarkReadyStore();
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useViewportSpy(isBenchmarkReady ? { current: null } : ref, { visibleOnce: true });

    return (
        <Stack ref={ref} as="section" spacing={3} mb={4}>
            <WfHeading as="h2" fontSize="2xl">
                {dict.tradingIdeaSection.tradingIdeaTitle}
            </WfHeading>
            <TradingIdea
                canCalculateHeight={isVisible || isBenchmarkReady}
                dict={dict.tradingIdeaSection}
                isTranslationNecessary={isTranslationNecessary}
                description={description}
            />

            <Stack spacing={5} direction={["column", "row"]} justifyContent="space-between" fontSize="sm">
                <Box width="100%">
                    <Box as="h3" mb={3} pt={2} fontWeight="bold" fontSize="lg">
                        {dict.tradingIdeaSection.masterDataTitle}
                    </Box>
                    <MasterData masterData={masterData} />
                </Box>
            </Stack>
            {investmentUniverseData && <InvestmentUniverse data={investmentUniverseData} dict={dict} />}
        </Stack>
    );
};

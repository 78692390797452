import { RefObject, useEffect, useState } from "react";

interface IViewportSpy extends Pick<IntersectionObserverInit, "rootMargin" | "threshold"> {
    visibleOnce?: boolean;
    onIntersecting?: (isIntersecting: boolean) => void;
}

const defaultOptions: IViewportSpy = {
    rootMargin: "0px",
    threshold: 0,
    visibleOnce: false,
};

/*
    Disclaimer:
    This hooks is a part of the 'beautiful-react-hooks' lib.
    Since it was not possible to only import this specific hook from the lib,
    we extracted it in our code base with small adaption

    See source code: https://github.com/antonioru/beautiful-react-hooks/blob/master/src/useViewportSpy.ts
*/

/**
 * Uses the IntersectionObserverMock API to tell whether the given DOM Element (from useRef) is visible within the
 * viewport.
 */
const useViewportSpy = <T extends HTMLElement | null>(elementRef: RefObject<T>, options: IViewportSpy = defaultOptions) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const { visibleOnce, threshold, rootMargin, onIntersecting } = options;

    useEffect(() => {
        if (!elementRef.current || (isVisible && visibleOnce)) {
            return;
        }

        const observer = new window.IntersectionObserver(
            entries =>
                entries.forEach(item => {
                    onIntersecting?.(item.isIntersecting);
                    setIsVisible(item.isIntersecting);
                }),
            {
                threshold,
                rootMargin,
            }
        );
        observer.observe(elementRef.current);

        return () => observer.disconnect();
    }, [elementRef, isVisible, rootMargin, threshold, visibleOnce, onIntersecting]);

    return isVisible;
};

export default useViewportSpy;

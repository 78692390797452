import { Box, BoxProps, Flex, Text } from "@chakra-ui/react";
import { IInvestmentUniverse, IWikifolioDetailDict } from "src/api/server/wf-detail.types";
import { PopoverButtonIconInfo, WfPopover } from "src/components/base/wf-popover";
import { InvestmentUniverseGroup } from "./investment-universe-group";

const INVESTMENT_UNIVERSE_TEST_ID = "investment-universe";

interface IProps extends BoxProps {
    data: IInvestmentUniverse;
    dict: Pick<IWikifolioDetailDict, "investmentUniverseTitle" | "superWikifolioInvestmentUniverseParagraph">;
}

export const InvestmentUniverse = ({ data, dict, ...boxProps }: IProps) => {
    const { universeGroups, tooltip } = data;

    return (
        <Box data-test-id={INVESTMENT_UNIVERSE_TEST_ID} {...boxProps}>
            <Flex align="center" pt={2}>
                <Box as="h3" fontWeight="bold" fontSize="lg">
                    {dict.investmentUniverseTitle}
                </Box>
                <WfPopover {...tooltip}>
                    <PopoverButtonIconInfo ariaLabel={dict.investmentUniverseTitle} ml={1} />
                </WfPopover>
            </Flex>
            {dict.superWikifolioInvestmentUniverseParagraph && <Text mt={1}>{dict.superWikifolioInvestmentUniverseParagraph}</Text>}
            <Flex mt={3} flexWrap="wrap" gap={2}>
                {universeGroups.map(data => (
                    <InvestmentUniverseGroup key={data.universeGroupId} {...data} />
                ))}
            </Flex>
        </Box>
    );
};

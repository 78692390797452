import { ChangeEvent, useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useToken } from "@chakra-ui/react";
import { SeriesLineOptions } from "highcharts/highstock";
import { IPriceIndexResponse, useBenchmarkQueries } from "src/api/client/price-chart.api";
import { getBenchmarkPriceTick, mapBenchmarkNamesToBenchmarkSeries } from "src/components/common/benchmark//utils/chart-util";
import { isDynamicBenchmark } from "src/components/common/benchmark//utils/utils";
import { IBenchmarkIndexListStatic } from "src/components/common/benchmark/configs";
import { BENCHMARKS } from "src/consts/local-storage";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { useUserStore } from "src/stores/user-store";
import { localStorage } from "src/utils/storage";

const MAX_ITEMS_TO_COMPARE = 3;

export interface IBenchmarkIndexListDynamic {
    isin: string;
    wikifolioId: string;
    label: string;
    colorScheme: string;
    /** The benchmark was added by typeahead selection */
    isDynamic: boolean;
}

interface IBenchmarksLocalStorage {
    selected?: string[];
    dynamicIndexList?: IBenchmarkIndexListDynamic[];
    userId: null;
}

export const useBenchmarkState = (defaultBenchmarksList: IBenchmarkIndexListStatic[]) => {
    const preselectedBenchmarks = defaultBenchmarksList.filter(item => item.isSelectedByDefault).map(item => item.label);
    const [selectedBenchmarks, setSelectedBenchmarks] = useState(new Set<string>(preselectedBenchmarks));
    const [isPublishChecked, setPublishChecked] = useState(false);
    const [isEmissionChecked, setEmissionChecked] = useState(false);
    const [selectedBenchmarksSeries, setSelectedBenchmarksSeries] = useState<SeriesLineOptions[] | null>(null);
    const userStore = useUserStore();
    const [benchmarkListDynamic, setBenchmarkListDynamic] = useState<IBenchmarkIndexListDynamic[] | []>([]);

    const benchmarkIndexList: (IBenchmarkIndexListDynamic | IBenchmarkIndexListStatic)[] = useMemo(
        () => [...defaultBenchmarksList, ...benchmarkListDynamic],
        [benchmarkListDynamic, defaultBenchmarksList]
    );

    const benchmarkColors = useToken(
        "colors",
        benchmarkIndexList.map(b => b.colorScheme + ".600")
    );
    const dateTimeFormatter = useDateTimeFormatter();

    const benchmarkQueries = useBenchmarkQueries(
        benchmarkIndexList.map(b => {
            if (isDynamicBenchmark(b)) {
                return {
                    isin: b.isin,
                    wikifolioId: b.wikifolioId,
                    enabled: selectedBenchmarks.has(b.label),
                    isDynamic: b.isDynamic,
                };
            }

            return {
                isin: b.isin,
                wikifolioId: "",
                enabled: selectedBenchmarks.has(b.label),
                isDynamic: false,
            };
        })
    );
    const benchmarkSeriesMap = useRef<Map<string, SeriesLineOptions>>(new Map()).current;
    benchmarkQueries.forEach((query, index) => {
        if (!query.data) {
            return;
        }

        const { label } = benchmarkIndexList[index];
        if (benchmarkSeriesMap.has(label)) {
            return;
        }

        benchmarkSeriesMap.set(label, getBenchmarkPriceTick(dateTimeFormatter, label, benchmarkColors[index], query.data as IPriceIndexResponse));
    });

    useEffect(() => {
        setSelectedBenchmarksSeries(mapBenchmarkNamesToBenchmarkSeries(selectedBenchmarks, benchmarkSeriesMap));
    }, [benchmarkSeriesMap.size, benchmarkSeriesMap, selectedBenchmarks]);

    const removeSelectedBenchmark = useCallback(
        (name: string) => {
            selectedBenchmarks.delete(name);
            setSelectedBenchmarks(new Set(selectedBenchmarks));
        },
        [selectedBenchmarks]
    );

    const addSelectedBenchmarkLocalStorage = (benchmarkDynamic: IBenchmarkIndexListDynamic) => {
        const localStorageBenchmarks: IBenchmarksLocalStorage | null = localStorage.get(BENCHMARKS);
        const userId = userStore?.user?.id;

        localStorage.set(BENCHMARKS, {
            userId,
            dynamicIndexList: [...(localStorageBenchmarks?.dynamicIndexList ?? []), ...(benchmarkDynamic?.isDynamic ? [benchmarkDynamic] : [])],
        });
    };

    const addSelectedBenchmark = useCallback(
        (name: string) => {
            selectedBenchmarks.add(name);
            setSelectedBenchmarks(new Set(selectedBenchmarks));
        },
        [selectedBenchmarks]
    );

    // Remove from dynamic BenchmarkList
    const onRemove = ({ isin, wikifolioId, label }: { isin: string; label: string; wikifolioId: string }) => {
        const newBenchmarkListDynamic = benchmarkListDynamic.filter(benchmark => benchmark.isin !== isin || benchmark.wikifolioId !== wikifolioId);

        setBenchmarkListDynamic(newBenchmarkListDynamic);
        removeSelectedBenchmark(label);

        const localStorageBenchmarks: IBenchmarksLocalStorage | null = localStorage.get(BENCHMARKS);
        const dynamicIndexList = (localStorageBenchmarks?.dynamicIndexList || []).filter(benchmark => benchmark.label !== label);
        const userId = userStore?.user?.id;

        localStorage.set(BENCHMARKS, {
            userId,
            dynamicIndexList,
        });
    };

    const onChange = useCallback(
        (name: string, isChecked: boolean) => {
            if (isChecked) {
                addSelectedBenchmark(name);
            } else {
                removeSelectedBenchmark(name);
            }
        },
        [addSelectedBenchmark, removeSelectedBenchmark]
    );

    const onReset = () => {
        setSelectedBenchmarks(new Set());
        setPublishChecked(false);
        setEmissionChecked(false);
    };

    const handlePublishOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPublishChecked(event.target.checked);
    };

    const handleEmissionChecked = (event: ChangeEvent<HTMLInputElement>) => {
        setEmissionChecked(event.target.checked);
    };

    useEffect(() => {
        benchmarkQueries.forEach((query, index) => {
            if (query.isError) {
                // Un-select checkbox if data fetch failed
                onChange(benchmarkIndexList[index].label, false);
            }
        });
    }, [benchmarkIndexList, benchmarkQueries, onChange]);

    useEffect(() => {
        const userId = userStore?.user?.id;

        const localStorageBenchmarks: IBenchmarksLocalStorage | null = localStorage.get(BENCHMARKS);

        if (userId && localStorageBenchmarks?.userId && localStorageBenchmarks.userId !== userId) {
            localStorage.set(BENCHMARKS, null);
            return;
        }

        if (localStorageBenchmarks?.dynamicIndexList?.length) {
            setBenchmarkListDynamic(localStorageBenchmarks.dynamicIndexList);
        }
    }, [userStore?.user?.id]);

    return {
        selectedBenchmarksSeries,
        selectedBenchmarks,
        isLoading: benchmarkQueries.some(query => query.isLoading),
        onChange,
        onRemove,
        setBenchmarkListDynamic,
        addSelectedBenchmark,
        addSelectedBenchmarkLocalStorage,
        benchmarkListDynamic,
        isLimitReached: selectedBenchmarks.size === MAX_ITEMS_TO_COMPARE,
        publishProps: {
            isChecked: isPublishChecked,
            onChange: handlePublishOnChange,
        },
        emissionProps: {
            isChecked: isEmissionChecked,
            onChange: handleEmissionChecked,
        },
        resetButtonProps: {
            isDisabled: selectedBenchmarks.size === 0 && !isPublishChecked && !isEmissionChecked,
            onClick: onReset,
        },
    };
};
export type IBenchmarkState = ReturnType<typeof useBenchmarkState>;

import { useEffect } from "react";
import { GetServerSideProps } from "next";
import { useDetailPageActivityMutation } from "src/api/client/detail-page-activity.api";
import { fetchWikifolioDetail } from "src/api/server/wf-detail";
import { IWikifolioDetailProps } from "src/api/server/wf-detail.types";
import { WikifolioDetailPage } from "src/components/pages/detail/wf-detail-page";
import { serverSideTranslations } from "src/i18n/server-side-translations";
import { useAuthModalStore } from "src/stores/auth-modal-store";
import { useUserStore } from "src/stores/user-store";
import { useWikifolioStore } from "src/stores/wikifolio-store";
import { IBaseParam } from "src/types/common";
import { addServerTiming } from "src/utils/nextjs/add-server-timing";

type IsolateEffects = {
    wikifolioId: string;
    isOnWatchlist: boolean;
};

function IsolateEffects({ wikifolioId, isOnWatchlist }: IsolateEffects) {
    // DEV-note effects are isolated from WikifolioDetailPage
    // in order to prevent un-needed re-render on initial page render.
    const trackVisitDetailPageActivity = useDetailPageActivityMutation();
    const { setOnWatchlist } = useWikifolioStore();
    const { setReturnUrl } = useAuthModalStore();

    useEffect(() => {
        trackVisitDetailPageActivity({
            parameter: `wikifolioId=${wikifolioId}`,
            referrer: document?.referrer ? document.referrer : null,
        });

        if (!window.location) {
            return;
        }

        setReturnUrl(window.location.pathname);
    }, [setReturnUrl, trackVisitDetailPageActivity, wikifolioId]);

    useEffect(() => {
        setOnWatchlist(wikifolioId, isOnWatchlist);
    }, [wikifolioId, isOnWatchlist, setOnWatchlist]);

    return null;
}

function Page({ data, dict, head }: IWikifolioDetailProps) {
    const { isLoggedIn } = useUserStore();
    const wikifolioId = data.wikifolio.id;
    const isOnWatchlist = data.wikifolio.isOnWatchlist;

    return (
        <>
            <IsolateEffects wikifolioId={wikifolioId} isOnWatchlist={isOnWatchlist} />
            <WikifolioDetailPage wikifolioId={wikifolioId} isLoggedIn={isLoggedIn} data={data} dict={dict} head={head} />
        </>
    );
}

type IParam = IBaseParam & {
    symbol: string;
};

export const getServerSideProps: GetServerSideProps<IWikifolioDetailProps, IParam> = async context => {
    const { symbol } = context.params!;
    const adId = context.query?.adId as string;

    const [props, translations] = await addServerTiming(context.res, "ssprops", async () => {
        const props = await fetchWikifolioDetail(symbol, context, adId);
        const translations = await serverSideTranslations(context.locale as string, [
            "common",
            "feed",
            "wf-detail",
            "date-range",
            "reactions",
            "comment",
        ]);

        return [props, translations];
    });

    if (!props) {
        return { notFound: true };
    }

    return {
        props: {
            ...props,
            head: {
                ...props.head,
                chartImageUrl: props.data?.wikifolio?.chartImageUrl || "",
                shortDescription: props.global?.gtmData?.wikifolio?.wikifolioShortDescription || "",
            },
            ...translations,
        },
    };
};

export default Page;

import { VStack } from "@chakra-ui/react";
import { ICertificateDict } from "src/api/server/wf-detail.types";
import { EnumWikifolioStatus } from "src/types/common-enum";
import { logger } from "src/utils/logger-util";
import { CERTIFICATE_BOX_TEST_ID } from "./certificate-box.test-ids";
import { CertificateBoxProvider, ICertificateBoxData, useCertificateBoxContext } from "./context";
import { useCertificate } from "./hooks/use-certificate";
import { CertificateBoxBarred } from "./variants/barred";
import { CertificateBoxClosed } from "./variants/closed";
import { CertificateBoxClosingInProgress } from "./variants/closing-in-progress";
import { CertificateBoxTest } from "./variants/in-test";
import { CertificateBoxInvestable } from "./variants/investable";
import { CertificateBoxNotLicensedAll } from "./variants/not-licensed-all";
import { CertificateBoxNotLicensedSingle } from "./variants/not-licensed-single";
import { CertificateBoxPublished } from "./variants/published";

const NO_CERTIFICATE_BOX_VARIANT_ERROR_MESSAGE = "No CertificateBox variant for status code";

export interface ICertificateBoxProps {
    dict: ICertificateDict;
    data: ICertificateBoxData;
}

const CertificateBoxContent = ({ data }: ICertificateBoxProps) => {
    const { selectedCertificate } = useCertificateBoxContext();

    switch (data.wikifolio.status) {
        case EnumWikifolioStatus.Investable:
            const notLicensedCertificates = data.certificates.filter(certificate => !certificate.isLicensed);

            if (notLicensedCertificates.length === data.certificates.length) {
                return <CertificateBoxNotLicensedAll />;
            }

            return selectedCertificate.isLicensed ? <CertificateBoxInvestable /> : <CertificateBoxNotLicensedSingle />;
        case EnumWikifolioStatus.ClosingInProgress:
            return <CertificateBoxClosingInProgress />;
        case EnumWikifolioStatus.Barred:
            return <CertificateBoxBarred />;
        case EnumWikifolioStatus.Closed:
            return <CertificateBoxClosed />;
        case EnumWikifolioStatus.Test:
        case EnumWikifolioStatus.PublishingRequested:
            return <CertificateBoxTest />;
        case EnumWikifolioStatus.Published:
        case EnumWikifolioStatus.IssuingRequested:
        case EnumWikifolioStatus.EmissionProcessStarted:
        case EnumWikifolioStatus.ReadyForIssuing:
            return <CertificateBoxPublished />;
        default:
            logger.error({ message: NO_CERTIFICATE_BOX_VARIANT_ERROR_MESSAGE, wikifolioStatus: data.wikifolio.status });
            return null;
    }
};

export const CertificateBox = (props: ICertificateBoxProps) => {
    const { selectedCertificate, updateSelectedCertificate } = useCertificate(props.data.certificates);

    return (
        <VStack
            as="section"
            h="auto"
            w={["100%", "400px", "100%"]}
            p={2}
            mx="auto"
            boxShadow="base"
            borderRadius="8px"
            spacing={2}
            data-test-id={CERTIFICATE_BOX_TEST_ID}
        >
            <CertificateBoxProvider
                value={{
                    dict: props.dict,
                    data: props.data,
                    selectedCertificate,
                    updateSelectedCertificate,
                }}
            >
                <CertificateBoxContent {...props} />
            </CertificateBoxProvider>
        </VStack>
    );
};

import { Box } from "@chakra-ui/react";
import { ITradeHistoryOrder } from "src/api/client/trade-history.api.types";
import { SpanBox } from "src/components/base";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { isSameYear, isSameYearAndMonth } from "src/utils/date-util";

export const TimeSpan = ({ orders }: { orders: ITradeHistoryOrder[] }) => {
    const { formatMonthShort, formatMonthShortYear } = useDateTimeFormatter();

    const endDate = new Date(orders[0].executionDate);
    const startDate = new Date(orders[orders.length - 1].executionDate);

    if (isSameYearAndMonth(startDate, endDate)) {
        return (
            <Box fontSize="sm" fontWeight="semibold" textAlign="center">
                {formatMonthShortYear(endDate)}
            </Box>
        );
    }

    if (!isSameYear(startDate, endDate)) {
        return (
            <Box fontSize="sm" fontWeight="semibold" textAlign="center">
                <SpanBox whiteSpace="nowrap">{formatMonthShortYear(startDate)}</SpanBox>
                {" - "}
                <SpanBox whiteSpace="nowrap">{formatMonthShortYear(endDate)}</SpanBox>
            </Box>
        );
    }

    return (
        <Box fontSize="sm" fontWeight="semibold" textAlign="center">
            <SpanBox whiteSpace="nowrap">{formatMonthShort(startDate)}</SpanBox>
            {" - "}
            <SpanBox whiteSpace="nowrap">{formatMonthShortYear(endDate)}</SpanBox>
        </Box>
    );
};

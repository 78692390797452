import { Box, BoxProps, Flex, Skeleton } from "@chakra-ui/react";

interface ILoadingStateProps extends BoxProps {}
export const LoadingState = (props: ILoadingStateProps) => {
    return (
        <Box aria-hidden={true} {...props}>
            <Flex align="center" justify="space-between" h={9} borderY="2px solid" borderColor="gray.100">
                <Box flexGrow={1}>
                    <Skeleton h={1} w={8} />
                </Box>
                <Skeleton h={1} w={8} flexShrink={0} ml={4} />
                <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
                <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
                <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
            </Flex>
            {[1, 2, 3, 4, 5, 6, 7].map(i => (
                <Flex key={i} align="center" justify="space-between" h={8} borderBottom="1px" borderColor="gray.100">
                    <Box flexGrow={1}>
                        <Skeleton h={1} w={15} />
                    </Box>
                    <Skeleton h={1} w={8} flexShrink={0} ml={4} />
                    <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
                    <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
                    <Skeleton h={1} w={8} flexShrink={0} ml={4} display={["none", "block"]} />
                </Flex>
            ))}
        </Box>
    );
};

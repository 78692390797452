import { useMemo } from "react";
import { useToken } from "@chakra-ui/react";
import { useWfPriceIndexQuery } from "src/api/client/price-chart.api";
import { getWfPriceTick } from "src/components/common/benchmark/utils/chart-util";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";

export const useWikifolioPriceChart = (wikifolioId: string) => {
    const [green600] = useToken("colors", ["green.600"]);
    const dateTimeFormatter = useDateTimeFormatter();
    const { formatNumber } = useNumberFormatter();
    const { data, isLoading } = useWfPriceIndexQuery(wikifolioId);
    const wfPriceSeries = useMemo(
        () => (data && data.timestamps.length > 0 ? getWfPriceTick(dateTimeFormatter, green600, data) : undefined),
        [data, dateTimeFormatter, green600]
    );
    const currentPrice = data && formatNumber(data.values[data.values.length - 1], 2);

    return useMemo(
        () => ({
            wfPriceSeries,
            currentPrice,
            isLoading,
            publishDate: data?.publishDate ? new Date(data?.publishDate).getTime() : undefined,
            emissionDate: data?.emissionDate ? new Date(data?.emissionDate).getTime() : undefined,
            todaysFirstTick: data?.todaysFirstTick,
        }),
        [currentPrice, data?.emissionDate, data?.publishDate, data?.todaysFirstTick, isLoading, wfPriceSeries]
    );
};

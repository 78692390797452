import { MutableRefObject, useEffect } from "react";
import { useToken } from "@chakra-ui/react";
import { Chart as IChart } from "highcharts/highstock";
import { getPlotLineConfig } from "src/components/common/benchmark/configs";

const ID_EMISSION = "emission";
const ID_PUBLISH = "publish";

interface IProps {
    chartRef: MutableRefObject<IChart | null>;
    emissionDate?: number;
    publishDate?: number;
    dict: {
        emission: string;
        publish: string;
    };
}
/**
 * Handles adding/removing of 'emission' or 'publish' plot-line to/from the chart.
 */
export const useEffectChartPlotLines = (props: IProps, [showEmissionLine, showPublishLine]: [boolean, boolean]) => {
    const { chartRef, emissionDate, publishDate, dict } = props;
    const [gray500] = useToken("colors", ["gray.500"]);

    useEffect(() => {
        if (!chartRef.current || !emissionDate) {
            return;
        }

        if (showEmissionLine) {
            chartRef.current.xAxis[0].addPlotLine(getPlotLineConfig({ id: ID_EMISSION, timestamp: emissionDate, label: dict.emission, gray500 }));
        } else {
            chartRef.current.xAxis[0].removePlotLine(ID_EMISSION);
        }
    }, [chartRef, dict.emission, emissionDate, gray500, showEmissionLine]);

    useEffect(() => {
        if (!chartRef.current || !publishDate) {
            return;
        }

        if (showPublishLine) {
            chartRef.current.xAxis[0].addPlotLine(getPlotLineConfig({ id: ID_PUBLISH, timestamp: publishDate, label: dict.publish, gray500 }));
        } else {
            chartRef.current.xAxis[0].removePlotLine(ID_PUBLISH);
        }
    }, [chartRef, dict.publish, gray500, publishDate, showPublishLine]);
};

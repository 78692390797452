import { AxisPlotLinesOptions, ChartSelectionCallbackFunction, Options, SeriesOptionsType } from "highcharts/highstock";
import { TFunction } from "next-i18next";
import { DAY_IN_MS, MONTH_IN_MS, YEAR_IN_MS } from "src/consts/time";
import { IDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { INumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { getBenchmarkTooltip, getChartTooltip, IBenchmarkPoint, IChartPoint } from "./utils/chart-util";

export const getChartGlobalConfig = (t: TFunction, chartAriaLabel?: string) => ({
    lang: {
        accessibility: {
            svgContainerLabel: chartAriaLabel,
            chartContainerLabel: undefined,
        },
        decimalPoint: t("decimal-separator"),
        thousandsSep: t("thousand-separator"),
        months: [
            t("january"),
            t("february"),
            t("march"),
            t("april"),
            t("may"),
            t("june"),
            t("july"),
            t("august"),
            t("september"),
            t("october"),
            t("november"),
            t("december"),
        ],
        weekdays: [t("sunday"), t("monday"), t("tuesday"), t("wednesday"), t("thursday"), t("friday"), t("saturday")],
        shortMonths: [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"), t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")],
    },
});

interface IParam {
    gray200: string;
    gray500: string;
    gray800: string;
    series: SeriesOptionsType;
    onZoom: () => void;
    baseCurrency?: string;
}

export interface IFormatters {
    dateTimeFormatter: IDateTimeFormatter;
    numberFormatter: INumberFormatter;
}

export const getChartConfig = (formatters: IFormatters, { gray200, gray500, gray800, series, onZoom, baseCurrency }: IParam): Options => ({
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    navigator: { enabled: false },
    exporting: { enabled: false },
    title: { text: undefined },
    credits: { enabled: false },
    legend: { enabled: false },

    time: {
        useUTC: false,
    },
    chart: {
        spacing: [30, 5, 0, 5],
        // Make x axis zoomable
        zooming: {
            type: "x",
        },
        resetZoomButton: { theme: { style: { display: "none" } } },
        selectionMarkerFill: "rgba(149, 193, 31, 0.16)",
        events: {
            selection: onZoom as ChartSelectionCallbackFunction,
        },
    },
    xAxis: {
        type: "datetime",
        tickLength: 0,
        lineWidth: 0,
        labels: {
            y: 26,
            style: {
                color: gray500,
                fontSize: "12px",
                fontWeight: "600",
            },
        },
        dateTimeLabelFormats: {
            second: "%H:%M:%S",
            minute: "%H:%M",
            hour: "%H:%M",
            day: "%e %b",
            week: "%e %b",
            month: "%b %y",
        },
        crosshair: {
            label: {
                enabled: false,
            },
            dashStyle: "Dot",
            width: 2,
            color: gray800,
        },
        events: {
            setExtremes: function (extremes) {
                const minTickInterval = extremes.max - extremes.min >= DAY_IN_MS * 7 ? DAY_IN_MS : undefined;
                this.update({ minTickInterval });
            },
        },
    },
    yAxis: {
        title: { text: null },
        opposite: false,
        showLastLabel: true,
        gridLineColor: gray200,
        gridLineDashStyle: "Dot",
        gridLineWidth: 2,
        labels: {
            align: "right",
            x: -16,
            y: 3,
            style: {
                padding: "0 40px 0 0",
                color: gray500,
                fontSize: "12px",
                fontWeight: "600",
            },
        },
    },
    plotOptions: {
        line: {
            marker: {
                enabled: false,
                radius: 3,
            },
            accessibility: {
                descriptionFormat: "",
            },
        },
        series: {
            states: {
                hover: {
                    lineWidthPlus: 0,
                },
            },
            connectNulls: true,
            turboThreshold: 0,
            accessibility: {
                descriptionFormat: "",
            },
        },
    },
    tooltip: {
        useHTML: true,
        borderWidth: 0,
        padding: 2,
        distance: 14,
        shadow: false,
        style: {
            color: "white",
            textAlign: "center",
            fill: "transparent",
        },
        headerFormat: "",
        shape: "square",
        split: true,
        pointFormatter: function () {
            if (this.series.chart.series.length === 1) {
                return getChartTooltip(formatters, this as unknown as IChartPoint, baseCurrency);
            }

            return getBenchmarkTooltip(formatters.numberFormatter, this as unknown as IBenchmarkPoint);
        },
    },
    series: [series],
});

export const getWikifolioTimeRangeItems = (t: TFunction, isIntraDayDisabled: boolean) => {
    const perfSince = t("perf-since");

    return [
        {
            value: DAY_IN_MS,
            label: t("range-1-d"),
            labelLong: `${perfSince} ${t("range-1-d-long")}`,
            isDisabled: isIntraDayDisabled,
        },
        {
            value: DAY_IN_MS * 7,
            label: t("range-7-d"),
            labelLong: `${perfSince} ${t("range-7-d-long")}`,
        },
        {
            value: MONTH_IN_MS,
            label: t("range-1-m"),
            labelLong: `${perfSince} ${t("range-1-m-long")}`,
        },
        {
            value: MONTH_IN_MS * 6,
            label: t("range-6-m"),
            labelLong: `${perfSince} ${t("range-6-m-long")}`,
        },
        {
            value: YEAR_IN_MS,
            label: t("range-1-y"),
            labelLong: `${perfSince} ${t("range-1-y-long")}`,
        },
        {
            value: YEAR_IN_MS * 3,
            label: t("range-3-y"),
            labelLong: `${perfSince} ${t("range-3-y-long")}`,
        },
        {
            value: YEAR_IN_MS * 5,
            label: t("range-5-y"),
            labelLong: `${perfSince} ${t("range-5-y-long")}`,
        },
        {
            value: Infinity,
            label: t("max"),
            labelLong: t("perf-since-begin"),
        },
    ];
};

export const getUnderlyingsTimeRangeItems = (t: TFunction) => {
    const perfSince = t("perf-since");

    return [
        {
            value: DAY_IN_MS,
            label: t("range-intraday"),
            labelLong: `${t("range-intraday")}`,
        },
        {
            value: DAY_IN_MS * 7,
            label: t("range-7-d"),
            labelLong: `${perfSince} ${t("range-7-d-long")}`,
        },
        {
            value: MONTH_IN_MS,
            label: t("range-1-m"),
            labelLong: `${perfSince} ${t("range-1-m-long")}`,
        },
        {
            value: MONTH_IN_MS * 6,
            label: t("range-6-m"),
            labelLong: `${perfSince} ${t("range-6-m-long")}`,
        },
        {
            value: YEAR_IN_MS,
            label: t("range-1-y"),
            labelLong: `${perfSince} ${t("range-1-y-long")}`,
        },
        {
            value: YEAR_IN_MS * 3,
            label: t("range-3-y"),
            labelLong: `${perfSince} ${t("range-3-y-long")}`,
        },
        {
            value: Infinity,
            label: t("max"),
            labelLong: t("perf-since-begin"),
        },
    ];
};

export const DYNAMIC_BENCHMARK_COLORS = ["gray", "blush", "pear"];

export interface IBenchmarkIndexListStatic {
    isin: string;
    label: string;
    colorScheme: string;
    isSelectedByDefault?: boolean;
}

export const defaultBenchmarkIndexList: IBenchmarkIndexListStatic[] = [
    {
        isin: "DE0008469008",
        label: "DAX",
        colorScheme: "lilac",
    },
    {
        isin: "DE0008467416",
        label: "MDAX",
        colorScheme: "blue",
    },
    {
        isin: "DE0009653386",
        label: "SDAX",
        colorScheme: "orange",
    },
    {
        isin: "DE0008469602",
        label: "CDAX",
        colorScheme: "sky",
    },
    {
        isin: "EU0009658145",
        label: "EURO STOXX 50",
        colorScheme: "teal",
    },
    {
        isin: "US6311011026",
        label: "Nasdaq 100",
        colorScheme: "yellow",
    },
];

interface IPlotLineArgs {
    id: "emission" | "publish";
    timestamp: number;
    label: string;
    gray500: string;
}
export const getPlotLineConfig = ({ id, timestamp, label, gray500 }: IPlotLineArgs): AxisPlotLinesOptions => ({
    id,
    dashStyle: "Dot",
    width: 2,
    color: gray500,
    value: timestamp,
    zIndex: 1,
    label: {
        text: label,
        style: {
            backgroundColor: gray500,
            padding: "4px 8px",
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            borderRadius: "4px" as any,
            color: "white",
            fontWeight: "bold",
            fontFamily: "inherit",
        },
        useHTML: true,
        rotation: 0,
        x: 8,
        y: id === "emission" ? 15 : 45,
    },
});

export const CLASS_BENCHMARK_HEADER = "wf-chart-header";
export const getBenchmarkTooltipUpdateConfig = () => ({
    plotOptions: {
        series: {
            compareStart: true,
        },
    },
    tooltip: {
        split: true,
        headerFormat: `<div class="${CLASS_BENCHMARK_HEADER}">{point.formattedDate}</div>`,
    },
});

export const getBenchmarkTooltipResetConfig = () => ({
    plotOptions: {
        series: {
            compareStart: false,
        },
    },
    tooltip: {
        split: false,
        headerFormat: "",
    },
});

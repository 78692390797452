import { Menu, MenuProps, MenuItemProps, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { IInvestmentUniverseGroup } from "src/api/server/wf-detail.types";
import { SpanBox, SpanFlex } from "src/components/base";
import { IconCaret } from "src/components/icons";

const InvestmentUniverseItem = (props: MenuItemProps) => (
    <MenuItem py={1} height="auto" fontSize="sm" _hover={{ bg: "white" }} _focus={{ bg: "white" }} {...props} />
);

interface IInvestmentUniverseGroupProps extends Omit<MenuProps, "children">, IInvestmentUniverseGroup {}

export const InvestmentUniverseGroup = ({ universeGroupName, universes, ...props }: IInvestmentUniverseGroupProps) => {
    const isCrossedOut = universes.every(universe => universe.isCrossedOut);
    const availableCount = universes.length;
    const assignedCount = universes.filter(universe => !universe.isCrossedOut).length;

    return (
        <Menu {...props}>
            {({ isOpen, onClose }) => (
                <>
                    <MenuButton
                        pos="relative"
                        disabled={false}
                        border="1px"
                        borderColor={isCrossedOut ? "gray.50" : "gray.800"}
                        bg={isCrossedOut ? "gray.50" : undefined}
                        color={isCrossedOut ? "gray.200" : undefined}
                        borderRadius="16px"
                        py={1}
                        px={2}
                        _hover={{
                            bg: "gray.50",
                        }}
                    >
                        <SpanFlex align="center" lineHeight="normal">
                            <SpanBox
                                h={2}
                                lineHeight="16px"
                                fontSize="sm"
                                fontWeight="bold"
                                mr={0.5}
                                textDecor={isCrossedOut ? "line-through" : undefined}
                            >
                                {universeGroupName}
                            </SpanBox>
                            <SpanBox borderRadius="16px" px={1} py="2px" bg="gray.500" color="white" fontSize="2xs" fontWeight="semibold" mr={1}>
                                {assignedCount} / {availableCount}
                            </SpanBox>
                            <IconCaret boxSize={2} transition="transform 0.3s" transform={isOpen ? "rotate(180deg)" : undefined} />
                        </SpanFlex>
                    </MenuButton>
                    <MenuList onClick={onClose} cursor="pointer">
                        {universes.map(({ universeId, name, isCrossedOut }) => (
                            <InvestmentUniverseItem
                                key={universeId}
                                sx={isCrossedOut ? { opacity: 1, color: "gray.200", textDecor: "line-through" } : undefined}
                                onClick={onClose}
                            >
                                {name}
                            </InvestmentUniverseItem>
                        ))}
                    </MenuList>
                </>
            )}
        </Menu>
    );
};

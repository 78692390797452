import { Text, VStack } from "@chakra-ui/react";
import { Button } from "src/components/base/button";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { IconClosed } from "src/components/icons";
import { useCertificateBoxContext } from "../context";

const CLOSED_TITLE_TEST_ID = "closed-title";
const CLOSED_DESCRIPTION_TEST_ID = "closed-description";
const CLOSED_BUTTON_TEST_ID = "closed-button";

export const CertificateBoxClosed = () => {
    const { dict, selectedCertificate: certificate } = useCertificateBoxContext();

    return (
        <>
            <VStack spacing={3}>
                <IconClosed boxSize={10} />
                <Text fontWeight="bold" textAlign="center" data-test-id={CLOSED_TITLE_TEST_ID}>
                    {dict.closedTitle}
                </Text>
                <Text textAlign="center" data-test-id={CLOSED_DESCRIPTION_TEST_ID}>
                    {dict.closedDescription}
                </Text>
                <Button
                    as={WfLinkUnstyled}
                    href={certificate.closingClosedButtonUrl}
                    w="100%"
                    colorScheme="green"
                    size="sm"
                    data-test-id={CLOSED_BUTTON_TEST_ID}
                >
                    {dict.closingClosedButton}
                </Button>
            </VStack>
        </>
    );
};

import { Text, VStack } from "@chakra-ui/react";
import { Button } from "src/components/base/button";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { IconNotAvailable } from "src/components/icons";
import { useUserStore } from "src/stores/user-store";
import { EnumWikifolioStatus } from "src/types/common-enum";
import { CBHeader } from "../components/header";
import { CBPrices } from "../components/prices";
import { CBRatioTabBar } from "../components/ratio-tab-bar";
import { CBCertificateFee, CBPerformanceFee } from "../components/statistics";
import { useCertificateBoxContext } from "../context";

const PUBLISH_BUTTON_TEST_ID = "publish-button";

export const CertificateBoxTest = () => {
    const userStore = useUserStore();
    const { dict, data } = useCertificateBoxContext();
    const isPublishRequested = data.wikifolio.status === EnumWikifolioStatus.PublishingRequested;

    return (
        <>
            <CBHeader />
            {data.certificates.length > 1 && <CBRatioTabBar />}
            <CBPrices />
            <VStack w="100%" spacing={2}>
                <CBCertificateFee />
                <CBPerformanceFee />
            </VStack>
            <VStack spacing={2} pt={1}>
                <IconNotAvailable boxSize={10} />
                <Text fontSize="sm" textAlign="center">
                    {dict.testMessage}
                </Text>
            </VStack>
            {userStore.isCurrentUser(data.trader) && (
                <Button
                    {...(isPublishRequested ? {} : { as: WfLinkUnstyled, href: data.editLink })}
                    isDisabled={isPublishRequested}
                    w="100%"
                    colorScheme="green"
                    size="sm"
                    data-test-id={PUBLISH_BUTTON_TEST_ID}
                >
                    {dict.publishButton}
                </Button>
            )}
        </>
    );
};

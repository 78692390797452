import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import Typography from "src/theme/typography";
import { ITooltip } from "src/types/common";
import { PopoverButtonIconInfo, WfPopover } from "../base/wf-popover";
import { RankingValue } from "./ranking/ranking-value";
import { EnumRankingType, IRankingData } from "./ranking/types";

const KEY_FIGURE_TEST_ID = "key-figure";

interface IKeyFigureProps extends FlexProps {
    ranking: IRankingData;
    value?: string;
    tooltip?: ITooltip | null;
    /* On true removes  `- place` in EnumRankingType.RankingPlace */
    hidePoints?: boolean;
    rankingValueFontWeight?: keyof (typeof Typography)["fontWeights"];
}

export const KeyFigure = ({ ranking, value, tooltip, hidePoints, rankingValueFontWeight, ...flexProps }: IKeyFigureProps) => {
    const { formatDateShort } = useDateTimeFormatter();

    return (
        <Flex justify="space-between" w="100%" h={7} data-test-id={KEY_FIGURE_TEST_ID} {...flexProps}>
            <Flex align="center">
                <Flex align="center" flexFlow="column">
                    <Text lineHeight="short">{ranking.label}</Text>
                    {ranking.type === EnumRankingType.Performance && ranking?.creationDate && (
                        <Text as="span" noOfLines={1} wordBreak="break-all" fontSize="xs" alignSelf="flex-start" color="gray.500">
                            {formatDateShort(ranking.creationDate)}
                        </Text>
                    )}
                </Flex>
                {tooltip && (
                    <WfPopover {...tooltip}>
                        <PopoverButtonIconInfo ariaLabel={ranking.label} ml={1} />
                    </WfPopover>
                )}
            </Flex>
            <Flex align="center">
                <RankingValue fontWeight={rankingValueFontWeight ?? "semibold"} size="xs" data={ranking} hidePoints={hidePoints} value={value} />
            </Flex>
        </Flex>
    );
};

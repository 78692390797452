import { NAV_HEIGHT } from "src/components/layout/default-layout";

export const DATA_ATTR_JUMPMARK_SECTION = "data-jumpmark";

export const THROTTLE_SCROLL_EVENT_MS = 100;
export const THROTTLE_RESIZE_EVENT_MS = 300;

export const JUMPMARK_HEIGHT = [5, 7];
const STICKY_HEADERS_HEIGHT = [
    JUMPMARK_HEIGHT[0] + NAV_HEIGHT[0] + 1,
    JUMPMARK_HEIGHT[1] + NAV_HEIGHT[1] + 2,
    JUMPMARK_HEIGHT[1] + NAV_HEIGHT[2] + 2,
];

// Because we have sticky headers we have to calculate the offset where an anchor should be visible from
export const ANCHOR_OFFSET = STICKY_HEADERS_HEIGHT.map(value => value * 8);
export const ANCHOR_OFFSET_NEGATIVE_PX = ANCHOR_OFFSET.map(value => `-${value}px`);
export const ANCHOR_OFFSET_PX = ANCHOR_OFFSET.map(value => `${value}px`);

export enum EnumJumpMarkSection {
    Overview = "overview",
    Feed = "feed",
    TradingIdea = "tradingidea",
    Portfolio = "portfolio",
    KeyFigures = "keyfigures",
    Trades = "trades",
}

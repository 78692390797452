import { AspectRatio, Flex, Text } from "@chakra-ui/react";
import { useGenericActivityFromElysiumMutation } from "src/api/client/generic-activity-from-elysium.api";
import { EnumActivityType } from "src/api/client/generic-activity.api";
import { ArrowLink } from "src/components/base/arrow-link";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { IImage, ILink } from "src/types/common";
import { triggerCallbackOnMiddleClickEvent } from "src/utils/dom/mouse-util";

const AD_MODULE_TEST_ID = "ad-module";

export interface IAdProps {
    headline: string;
    description: string;
    link: ILink;
    nodeId: number;
    image?: IImage;
}

export const Ad = (props: IAdProps) => {
    const { headline, description, link, nodeId, image } = props;
    const genericActivity = useGenericActivityFromElysiumMutation();

    const onLinkClick = () => {
        genericActivity({
            activityTypeId: EnumActivityType.AdClicked,
            parameter: JSON.stringify({ nodeId, targetUrl: link.url }),
            referrer: window.location.href,
        });
    };

    return (
        <Flex
            direction="column"
            border="1px"
            borderRadius="8px"
            p={2}
            borderColor="gray.200"
            w={["312px", "400px", "278px", "290px"]}
            data-test-id={AD_MODULE_TEST_ID}
        >
            {image && (
                <AspectRatio w="100%" ratio={21 / 9} mb={2}>
                    <ResponsiveImage
                        h={["120px", "157px", "105px", "110px"]}
                        w="100%"
                        borderRadius="4px"
                        baseUrl={image?.url}
                        alt={image?.alt}
                        loading="eager"
                    />
                </AspectRatio>
            )}
            <Text fontSize="md" fontWeight="semibold" pb={1}>
                {headline}
            </Text>
            <Text fontSize="xs" pb={2}>
                {description}
            </Text>
            <ArrowLink
                href={link.url}
                target={link.target}
                colorScheme="green"
                fontSize="xs"
                onClick={onLinkClick}
                onAuxClick={triggerCallbackOnMiddleClickEvent(onLinkClick)}
            >
                {link.name}
            </ArrowLink>
        </Flex>
    );
};

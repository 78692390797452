import { createContext, useContext } from "react";
import { ICertificateData, ICertificateDict, IWikifolioDetailProps } from "src/api/server/wf-detail.types";

export type ICertificateBoxData = IWikifolioDetailProps["data"];

type CertificateBoxState = {
    dict: ICertificateDict;
    data: ICertificateBoxData;
    selectedCertificate: ICertificateData;
    updateSelectedCertificate: (isin: string) => void;
};

const CertificateBoxContext = createContext<CertificateBoxState | null>(null);

export const CertificateBoxProvider = CertificateBoxContext.Provider;

export const useCertificateBoxContext = () => useContext(CertificateBoxContext)!;

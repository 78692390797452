import { MouseEvent } from "react";
import { useBoolean, useDisclosure } from "@chakra-ui/react";
import { Button, ButtonProps } from "src/components/base/button";
import { DeclareInterestModal } from "src/components/modals/declare-interest-modal/declare-interest-modal";

const DECLARE_INTEREST_BUTTON_TEST_ID = "declare-interest-button";

interface IProps extends ButtonProps {
    wikifolioId: string;
}

export const DeclareInterestButton = ({ wikifolioId, onClick, children, ...buttonProps }: IProps) => {
    const modalDisclosure = useDisclosure();
    const [isLoading, setLoading] = useBoolean();

    const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        setLoading.on();
        modalDisclosure.onOpen();
        onClick?.(event);
    };

    return (
        <>
            <Button onClick={handleOnClick} {...buttonProps} isLoading={isLoading} data-test-id={DECLARE_INTEREST_BUTTON_TEST_ID}>
                {children}
            </Button>
            <DeclareInterestModal wikifolioId={wikifolioId} onRequestSettled={setLoading.off} {...modalDisclosure} />
        </>
    );
};

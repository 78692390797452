import { useEffect, ReactNode, startTransition } from "react";
import { Box, Flex, Divider, HStack, Skeleton, StackDivider, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { IPriceResponse, useWikifolioPriceQuery } from "src/api/client/wikifolio-price.api";
import { PopoverButtonIconInfo, WfPopover } from "src/components/base/wf-popover";
import { getGlobals } from "src/consts/globals";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { useDetailPageStore } from "src/stores/detail-page-store";
import { useCertificateBoxContext } from "../context";

const CERTIFICATE_BOX_MID_PRICE_TEST_ID = "certificate-box-mid-price";
const CERTIFICATE_BOX_BID_PRICE_TEST_ID = "certificate-box-bid-price";
const CERTIFICATE_BOX_ASK_PRICE_TEST_ID = "certificate-box-ask-price";
const CERTIFICATE_BOX_PRICE_INFO_TEST_ID = "certificate-box-price-info";

// on Desktop we want to have a fixes size so we can calculate the height even if no data has been loaded yet
const PRICES_CONTAINER_HEIGHT = ["auto", "auto", "88px"];

interface IPriceProps {
    label: string;
    value?: number | null;
    isLoading: boolean;
    fontSize?: string;
}

const Price = ({ value, label, isLoading, fontSize, ...boxProps }: IPriceProps) => {
    const { formatNumber } = useNumberFormatter();

    let renderValue: ReactNode = <Skeleton display="inline-block" h="22px" my={0.5} w={10} />;
    if (!isLoading) {
        renderValue = (
            <Text as="strong" fontSize={fontSize || "2xl"}>
                {value ? formatNumber(value, 2) : "-"}
            </Text>
        );
    }

    return (
        <Box textAlign="center" minW={9} w="100%" {...boxProps}>
            {renderValue}
            <Text fontSize="sm" color="gray.400">
                {label}
            </Text>
        </Box>
    );
};

// depending on how large the ask or big price is, we calculate the font size so that it fits into the UI
function getAskBidFontSize(ask: number, bid: number) {
    const maxValue = Math.max(ask, bid);

    let fontSize = "2xl";
    if (maxValue >= 1000000) {
        fontSize = "lg";
    } else if (maxValue >= 10000) {
        fontSize = "xl";
    }
    return fontSize;
}

function getFormattedPriceData(isLoading: boolean, priceData: IPriceResponse | undefined) {
    if (isLoading) {
        return {
            showMidPrice: false,
            isTicking: true,
        };
    }

    const { ask, bid, midPrice, isTicking: _isTicking, showMidPrice, calculationDate: _calcDate, isCurrencyConverted } = priceData!;

    let isTicking = true;
    if (!midPrice && !_isTicking) {
        isTicking = false;
    }

    return {
        ask,
        bid,
        midPrice,
        showMidPrice,
        calculationDate: new Date(_calcDate),
        isTicking,
        isCurrencyConverted,
    };
}

function calculateIndexLevel(bid: number, ask: number, exchangeRatioMultiplier: number): number {
    return (bid + ask) / 2.0 / exchangeRatioMultiplier;
}

export const CBPrices = () => {
    const { dict, data, selectedCertificate: certificate } = useCertificateBoxContext();
    const { setIndexLevel } = useDetailPageStore();
    const exchangeRatioMultiplier = certificate.exchangeRatioMultiplier;
    const nonPrimaryIsin = certificate.isPrimary ? "" : certificate.isin;
    const numOfRetries = 3;

    const { data: priceData, isError, isLoading } = useWikifolioPriceQuery(data.wikifolio.id, nonPrimaryIsin, numOfRetries);
    const { t } = useTranslation("wf-detail");
    const { formatDateShort, formatTimeShort } = useDateTimeFormatter();
    const { isTicking, bid, ask, calculationDate, showMidPrice, midPrice, isCurrencyConverted } = getFormattedPriceData(isLoading, priceData);

    useEffect(() => {
        if (bid && ask && exchangeRatioMultiplier) {
            startTransition(() => {
                setIndexLevel(calculateIndexLevel(bid, ask, exchangeRatioMultiplier));
            });
        }
    }, [bid, ask, exchangeRatioMultiplier, setIndexLevel]);

    if ((!isLoading && !priceData) || isError) {
        return <CBPricesNotTicking />;
    }

    if (!isTicking) {
        return <CBPricesNotTicking />;
    }

    const askBidFontSize = getAskBidFontSize(ask || 0, bid || 0);

    return (
        <VStack spacing={1} pb={2} w="100%" h={PRICES_CONTAINER_HEIGHT}>
            {showMidPrice ? (
                <Price label={dict.prices!.midRate} value={midPrice} isLoading={isLoading} data-test-id={CERTIFICATE_BOX_MID_PRICE_TEST_ID} />
            ) : (
                <HStack spacing={1} divider={<StackDivider borderColor="gray.200" />} w="100%">
                    <Price
                        label={dict.prices!.sell}
                        value={bid}
                        isLoading={isLoading}
                        fontSize={askBidFontSize}
                        data-test-id={CERTIFICATE_BOX_BID_PRICE_TEST_ID}
                    />
                    <Price
                        label={dict.prices!.buy}
                        value={ask}
                        isLoading={isLoading}
                        fontSize={askBidFontSize}
                        data-test-id={CERTIFICATE_BOX_ASK_PRICE_TEST_ID}
                    />
                </HStack>
            )}
            <Flex align="center" color="gray.300" fontSize="2xs" data-test-id={CERTIFICATE_BOX_PRICE_INFO_TEST_ID}>
                <Flex align="center">
                    {isLoading ? (
                        <Skeleton w="120px" h="14px" />
                    ) : (
                        t("prices-per-xat-y", { 0: formatDateShort(calculationDate!), 1: formatTimeShort(calculationDate!) })
                    )}
                    <Box mx="5px" borderRadius="full" boxSize="2px" bg="currentColor" />
                    in {getGlobals().currency}
                </Flex>
                {!isLoading && isCurrencyConverted && (
                    <WfPopover {...data.tooltips.currencyConverted!}>
                        <PopoverButtonIconInfo ariaLabel={t("currency-converted")} ml={0.5} />
                    </WfPopover>
                )}
            </Flex>
        </VStack>
    );
};

export const CBPricesNotTicking = () => {
    const { t } = useTranslation("wf-detail");
    return (
        <Box h={PRICES_CONTAINER_HEIGHT}>
            <Text fontSize="xs">{t("certificate.currently-no-price-available")}</Text>
            <Divider borderColor="gray.100" mt={2} />
        </Box>
    );
};

import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { IWikifolioKeyFigureGroup } from "src/api/server/wf-detail.types";
import { WfHeading } from "src/components/base/wf-heading";
import { KeyFigureGroup } from "./key-figure-group";

interface IOtherRiskIndicatorsProps {
    keyFigureGroups: IWikifolioKeyFigureGroup[];
}

export const OtherRiskIndicators = ({ keyFigureGroups }: IOtherRiskIndicatorsProps) => {
    const { t } = useTranslation("wf-detail");

    return (
        <>
            <WfHeading as="h3" fontSize="md" mb={2}>
                {t("headlines.other-key-risk-indicators")}
            </WfHeading>
            <Tabs variant="full">
                <TabList>
                    {keyFigureGroups.map(keyFigureGroup => (
                        <Tab key={keyFigureGroup.label}>{keyFigureGroup.label}</Tab>
                    ))}
                </TabList>
                <TabPanels mt={2}>
                    {keyFigureGroups.map(keyFigureGroup => (
                        <TabPanel key={keyFigureGroup.label} p={0}>
                            <KeyFigureGroup keyFigureGroup={keyFigureGroup} />
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </>
    );
};

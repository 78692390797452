import { useRef } from "react";
import { BoxProps, HStack } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { ITradeHistoryTableDict } from "src/api/server/wf-detail.types";
import { Section } from "src/components/base";
import { Button } from "src/components/base/button";
import { WfHeading } from "src/components/base/wf-heading";
import { EmptyState } from "src/components/common/empty-state";
import { ErrorState } from "../shared/error-state";
import { LoadingState } from "./loading-state";
import { TimeSpan } from "./time-span";
import { ITradeHistoryTableProps } from "./trade-history-table";
import { useTradeHistoryTable } from "./use-trade-history-table";

const TradeHistoryTable = dynamic<ITradeHistoryTableProps>(() => import("./trade-history-table").then(component => component.TradeHistoryTable), {
    loading: () => <LoadingState />,
});

interface ITradeHistorySectionProps extends BoxProps {
    wikifolioId: string;
    dict: ITradeHistoryTableDict;
}

export const TradeHistorySection = ({ wikifolioId, dict, ...boxProps }: ITradeHistorySectionProps) => {
    const { t } = useTranslation("wf-detail");
    const sectionRef = useRef<HTMLDivElement>(null);
    const { isLoading, isError, data, newerBtnProps, olderBtnProps } = useTradeHistoryTable(wikifolioId, sectionRef);

    return (
        <Section ref={sectionRef} {...boxProps}>
            <WfHeading as="h2" fontSize="2xl" mb={3}>
                {dict.title}
            </WfHeading>
            {!data && <LoadingState animation={isLoading ? "initial" : "none"} />}
            {isError && (
                <ErrorState>
                    <LoadingState sx={{ div: { animation: "none" } }} />
                </ErrorState>
            )}
            {data &&
                (data.tradeHistory.orders.length > 0 ? (
                    <>
                        <TradeHistoryTable orders={data.tradeHistory.orders} isSuperWikifolio={data.tradeHistory.isSuperWikifolio} />
                        <HStack justify="space-between" align="center" spacing={1} mt={[3, 3, 5]}>
                            <Button variant="outline" size={["xs", "sm"]} className="gtm-newer-history" {...newerBtnProps}>
                                {t("newer")}
                            </Button>
                            <TimeSpan orders={data.tradeHistory.orders} />
                            <Button variant="outline" size={["xs", "sm"]} className="gtm-older-history" {...olderBtnProps}>
                                {t("older")}
                            </Button>
                        </HStack>
                    </>
                ) : (
                    <EmptyState {...dict.empty} />
                ))}
        </Section>
    );
};

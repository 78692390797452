// eslint-disable-next-line no-restricted-imports
import { Box, Slider, SliderFilledTrack, SliderProps, SliderThumb, SliderTrack } from "@chakra-ui/react";
import { SLIDER_THUMB_SIZE } from "src/theme/components/slider";
import { numberToPx } from "src/utils/number-format-utils";

const SLIDER_THUMB_SIZE_HALF = SLIDER_THUMB_SIZE / 2;

export const WfSlider = (props: SliderProps) => (
    <Box w="100%" px={numberToPx(SLIDER_THUMB_SIZE_HALF)}>
        <Slider {...props}>
            <Box pos="relative" mx={numberToPx(-SLIDER_THUMB_SIZE_HALF)}>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
            </Box>
            <SliderThumb />
        </Slider>
    </Box>
);

import { BoxProps, Flex } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { IPortfolioChartDict } from "src/api/server/wf-detail.types";
import { Section } from "src/components/base";
import { WfHeading } from "src/components/base/wf-heading";
import { EnumJumpMarkSection } from "../jump-marks/consts";
import { JumpMarkLink } from "../jump-marks/jump-mark-link";
import { useBenchmarkReadyStore } from "../store/benchmark-ready-store";
import { LoadingState } from "./loading-state";

interface IPortfolioChartProps extends BoxProps {
    wikifolioId: string;
    dict: IPortfolioChartDict;
}

interface IPortfolioChartWrapper extends IPortfolioChartProps {
    isLoggedIn: boolean;
}

export const PortfolioChartWrapper = ({ wikifolioId, isLoggedIn, dict, ...boxProps }: IPortfolioChartWrapper) => {
    const PortfolioChart = dynamic<IPortfolioChartProps>(
        () => import("src/components/pages/detail/portfolio-chart/portfolio-chart").then(component => component.PortfolioChart),
        {
            loading: () => <LoadingState />,
        }
    );

    const { t } = useTranslation("common");
    const { isBenchmarkReady } = useBenchmarkReadyStore();

    return (
        <Section pos="relative" {...boxProps}>
            <Flex justify="space-between" align="center">
                <WfHeading as="h2" fontSize="2xl">
                    {dict.title}
                </WfHeading>
                <JumpMarkLink section={EnumJumpMarkSection.Portfolio}>{t("details")}</JumpMarkLink>
            </Flex>
            {isBenchmarkReady || !isLoggedIn ? <PortfolioChart wikifolioId={wikifolioId} dict={dict} {...boxProps} /> : <LoadingState />}
        </Section>
    );
};

import { Box, BoxProps } from "@chakra-ui/react";
import { IWikifolioWatchlistCtaData } from "src/api/server/wf-detail.types";
import { Container } from "src/components/layout/container";
import { WatchlistCta } from "./watchlist-cta";

interface IWatchlistCtaSectionProps extends BoxProps {
    wikifolioId: string;
    wikifolioShortDescription: string;
    data: IWikifolioWatchlistCtaData;
}
const WATCHLIST_CTA_SECTION_TEST_ID = "watchlist-cta-section-test-id";

export const WatchlistCtaSection = ({ wikifolioId, wikifolioShortDescription, data, ...boxProps }: IWatchlistCtaSectionProps) => {
    return (
        <Box data-test-id={WATCHLIST_CTA_SECTION_TEST_ID} backgroundColor="gray.50" mb={[10, 10, 14]} {...boxProps}>
            <Container>
                <WatchlistCta wikifolioId={wikifolioId} wikifolioShortDescription={wikifolioShortDescription} data={data}></WatchlistCta>
            </Container>
        </Box>
    );
};

import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Checkbox, useDisclosure, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Box, Skeleton, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useCopyIsinModalQuery } from "src/api/client/copy-isin-modal.api";
import { Span } from "src/components/base";
import { WfHeading } from "src/components/base/wf-heading";
import { WfModalCloseButton } from "src/components/base/wf-modal-close-button";
import { WatchlistButton } from "src/components/common/buttons/watchlist-button/watchlist-button";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { HIDE_MODALS_KEY, COPY_ISIN_MODAL } from "src/consts/local-storage";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { useUserStore } from "src/stores/user-store";
import { useWikifolioStore } from "src/stores/wikifolio-store";
import { localStorage } from "src/utils/storage";
import { COPY_ISIN_MODAL_LOADING_STATE_TEST_ID } from "./copy-isin-modal.test-ids";

interface ICopyIsinModalProps {
    wikifolioId: string;
    wikifolioShortDescription: string;
    children: (openModal: () => void) => ReactNode;
}

export const CopyIsinModal = ({ wikifolioId, wikifolioShortDescription, children }: ICopyIsinModalProps) => {
    const { t } = useTranslation("common");
    const toast = useWfToast();
    const { isLoggedIn } = useUserStore();
    const { watchlist, setOnWatchlist } = useWikifolioStore();

    const [hideModal, setHideModal] = useState(false);

    const onCloseModal = () => {
        if (hideModal) {
            const hideModals: Array<string | null> = localStorage.get(HIDE_MODALS_KEY) || [];
            localStorage.set(HIDE_MODALS_KEY, [...hideModals, COPY_ISIN_MODAL]);
        }
    };

    const { isOpen, onOpen, onClose } = useDisclosure({
        onClose: onCloseModal,
        defaultIsOpen: false,
    });

    const { data: modalText, isLoading, isError } = useCopyIsinModalQuery(isOpen);
    const { headline1, description1, headline2, description2, hideModalCheckbox } = modalText || {};
    const isOnWatchlist = watchlist[wikifolioId] ?? false;

    const openModal = () => {
        const hideModals: Array<string> | null = localStorage.get(HIDE_MODALS_KEY);

        if (!hideModals?.includes(COPY_ISIN_MODAL) && isLoggedIn && !isOnWatchlist) {
            onOpen();
        }
    };

    useEffect(() => {
        if (isError) {
            onClose();
            toast({ status: EnumToastStatus.Error, title: t("general-error") });
        }
    }, [isError, onClose, t, toast]);

    return (
        <>
            {children(openModal)}
            <Modal blockScrollOnMount={true} isOpen={isOpen} autoFocus={false} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent maxW={["full", "750px", "750px", "708px"]} pr={[3, 4]}>
                    {isLoading ? (
                        <>
                            <Span data-test-id={COPY_ISIN_MODAL_LOADING_STATE_TEST_ID} />
                            <ModalHeader>
                                <Skeleton height={4} width="80%" />
                                <Skeleton mt={2} height={4} width="60%" />
                            </ModalHeader>
                            <WfModalCloseButton />
                            <Box pl={[3, 5]}>
                                <Skeleton height={4} width="80%" />
                                <Skeleton mt={2} height={4} width="40%" />
                            </Box>
                            <ModalFooter flexFlow="column" alignItems="flex-start">
                                <Skeleton height={4} width="30%" />
                            </ModalFooter>
                        </>
                    ) : (
                        <>
                            <ModalHeader>
                                <WfHeading as="h2" fontSize={["xl", "2xl"]}>
                                    {headline1}
                                </WfHeading>
                                <SimpleRichText mt={2} fontWeight="normal" fontSize="md" text={description1 || ""} />
                            </ModalHeader>
                            <WfModalCloseButton />
                            <Box mt={2} pl={[3, 5]}>
                                <Text fontWeight="bold" fontSize={["xl", "2xl"]}>
                                    {headline2}
                                </Text>
                                <SimpleRichText mt={2} text={description2 || ""} />
                                <Box mt={2}>
                                    <WatchlistButton
                                        width={["100%", "auto"]}
                                        size="sm"
                                        wikifolioId={wikifolioId}
                                        wikifolioShortDescription={wikifolioShortDescription}
                                        isOnWatchlist={isOnWatchlist}
                                        setOnWatchlist={setOnWatchlist}
                                    />
                                </Box>
                            </Box>

                            <ModalFooter alignSelf="start" marginTop={["auto", "unset"]}>
                                <Checkbox onChange={(event: ChangeEvent<HTMLInputElement>) => setHideModal(event.target.checked)}>
                                    {hideModalCheckbox}
                                </Checkbox>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

import { HOUR_IN_MS } from "src/consts/time";
import {
    createApiWikifolioPriceIndexUrl,
    createApiWikifolioClosePriceIndexUrl,
    createApiWikifolioBenchmarkIndexUrl,
    createApiWikifolioBenchmarkUnderlyingUrl,
} from "src/consts/urls";
import { useQueries, useQuery } from "src/hooks/react-query/use-query";

export interface IPriceIndexResponse {
    timestamps: string[];
    values: number[];
    volumes: number[];
    creationDate: string;
    publishDate: string | null;
    emissionDate: string | null;
    todaysFirstTick: string | null;
}

export function useWfPriceIndexQuery(wikifolioId: string, retry = 0) {
    return useQuery<IPriceIndexResponse>(createApiWikifolioPriceIndexUrl(wikifolioId), {
        staleTime: HOUR_IN_MS,
        retry: retry,
    });
}

export function useWfClosePriceIndexQuery(wikifolioId: string, enabled: boolean) {
    return useQuery<IPriceIndexResponse>(createApiWikifolioClosePriceIndexUrl(wikifolioId), {
        staleTime: HOUR_IN_MS,
        enabled,
    });
}

interface IBenchmarksRequest {
    isin: string;
    wikifolioId: string;
    enabled: boolean;
    isDynamic: boolean;
}

const getBenchmarkQueriesUrl = ({ isDynamic, isin, wikifolioId }: Omit<IBenchmarksRequest, "enabled">): string => {
    if (isDynamic) {
        return wikifolioId ? createApiWikifolioClosePriceIndexUrl(wikifolioId) : createApiWikifolioBenchmarkUnderlyingUrl(isin);
    }
    return createApiWikifolioBenchmarkIndexUrl(isin);
};
export function useBenchmarkQueries(benchmarks: IBenchmarksRequest[]) {
    return useQueries(
        benchmarks.map(({ isin, wikifolioId, enabled, isDynamic }) => {
            const url = getBenchmarkQueriesUrl({ isDynamic, wikifolioId, isin });

            return {
                url,
                options: {
                    enabled,
                    staleTime: HOUR_IN_MS,
                },
            };
        })
    );
}

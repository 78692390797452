import { useCallback, useEffect, useRef, useState } from "react";
import { BoxProps, Flex, useDisclosure } from "@chakra-ui/react";
import { Chart as IChart, PointOptionsObject } from "highcharts/highstock";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { Section } from "src/components/base";
import { Button } from "src/components/base/button";
import { TimeRangeSelect } from "src/components/common/time-range-select/time-range-select";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { IWikifolio } from "src/types/common";
import { EnumWikifolioStatus } from "src/types/common-enum";
import { differenceInDays } from "src/utils/date-util";
import { IBenchmarkOverlayProps } from "./benchmark-settings-modal";
import { IBenchmarkDict } from "./benchmark.types";
import { Chart } from "./chart";
import { defaultBenchmarkIndexList, getBenchmarkTooltipResetConfig, getBenchmarkTooltipUpdateConfig, getWikifolioTimeRangeItems } from "./configs";
import { useBenchmarkState } from "./hooks/use-benchmark-state";
import { useEffectChartPlotLines } from "./hooks/use-effect-chart-plotlines";
import { useWikifolioClosePriceChart } from "./hooks/use-wikifolio-close-price-chart";
import { useWikifolioPriceChart } from "./hooks/use-wikifolio-price-chart";
import { drawChartSeries, getLastEmissionDateFromBenchmarkSeries, removeUnselectedBenchmarkSeries } from "./utils/chart-util";

const BenchmarkSettingsModal = dynamic<IBenchmarkOverlayProps>(
    () => import("./benchmark-settings-modal").then(component => component.BenchmarkSettingsModal),
    {
        ssr: false,
    }
);

export interface IBenchmarkProps extends BoxProps {
    wikifolioId: IWikifolio["id"];
    wikifolioStatus: EnumWikifolioStatus;
    creationDate: string;
    dict: IBenchmarkDict;
    baseCurrency: string;
    // Callback when chart finishes animation or has no data
    onChartReady?: () => void;
}

const CHART_HEIGHT = "390px";
const WF_MIN_DAYS_OLD = 7;

export const WikifolioDetailBenchmark = ({
    wikifolioId,
    wikifolioStatus,
    creationDate,
    dict,
    baseCurrency,
    onChartReady,
    ...boxProps
}: IBenchmarkProps) => {
    const overlay = useDisclosure();
    const { t } = useTranslation(["date-range", "wf-detail"]);
    const { formatDateShort } = useDateTimeFormatter();
    const benchmarkState = useBenchmarkState(defaultBenchmarkIndexList);
    const { selectedBenchmarksSeries, selectedBenchmarks } = benchmarkState;
    const isBenchmarkActive = selectedBenchmarks.size > 0;
    const timeRangeItems = getWikifolioTimeRangeItems(t, isBenchmarkActive);
    const { isLoading: isWfPriceLoading, wfPriceSeries, currentPrice, emissionDate, publishDate } = useWikifolioPriceChart(wikifolioId);
    const { isLoading: isWfClosedPriceLoading, wfClosePriceSeries } = useWikifolioClosePriceChart(wikifolioId, isBenchmarkActive);
    const [selectedTime, setSelectedTime] = useState(Infinity);
    const chartRef = useRef<IChart | null>(null);

    const isLoading = isWfPriceLoading || benchmarkState.isLoading || isWfClosedPriceLoading;
    const isOldEnoughToAnalyze = differenceInDays(new Date(creationDate), new Date()) > WF_MIN_DAYS_OLD;
    const isAnalysisBtnDisabled = wikifolioStatus === EnumWikifolioStatus.Closed || isLoading || !wfPriceSeries || !isOldEnoughToAnalyze;

    useEffectChartPlotLines({ chartRef, emissionDate, publishDate, dict: dict.overlay }, [
        benchmarkState.emissionProps.isChecked,
        benchmarkState.publishProps.isChecked,
    ]);

    const onChangeTimeRange = useCallback(
        (value: number, extremes?: { min: number; max: number }) => {
            if (!chartRef.current || !wfPriceSeries) {
                return;
            }

            if (extremes) {
                chartRef.current.xAxis[0].setExtremes(extremes.min, extremes.max);
                setSelectedTime(-1);
                return;
            }

            const currentWfPriceData = (chartRef.current.series.length === 1 ? wfPriceSeries.data : wfClosePriceSeries!.data) as PointOptionsObject[];
            const max = currentWfPriceData[currentWfPriceData.length - 1].x!;

            const min = value === Infinity ? currentWfPriceData[0].x : max - value;
            chartRef.current.xAxis[0].setExtremes(min, max);

            setSelectedTime(value);
        },
        [wfClosePriceSeries, wfPriceSeries]
    );

    useEffect(() => {
        if (!chartRef.current || !wfPriceSeries?.data || !wfClosePriceSeries?.data || !selectedBenchmarksSeries) {
            return;
        }

        const { series } = chartRef.current;

        const wfClosePriceStartDate: number = (wfClosePriceSeries.data as PointOptionsObject[])[0].x!;
        const lastEmissionDate = getLastEmissionDateFromBenchmarkSeries(selectedBenchmarksSeries, wfClosePriceStartDate);

        drawChartSeries(chartRef.current, selectedBenchmarksSeries, series, lastEmissionDate);

        if (series.length === 1) {
            // Reset wikifolio chart to initial state
            chartRef.current.update(getBenchmarkTooltipResetConfig());
            chartRef.current.yAxis[0].setCompare(undefined);
            series[0].setData(wfPriceSeries.data);
        } else {
            // Switch to benchmark comparison mode
            chartRef.current.update(getBenchmarkTooltipUpdateConfig());
            chartRef.current.yAxis[0].setCompare("percent");

            // Cut the wf close price to align with all series
            if (lastEmissionDate) {
                const index = (wfClosePriceSeries.data as PointOptionsObject[]).findIndex(s => s?.x === lastEmissionDate);
                const wfClosePriceSeriesAdjusted = wfClosePriceSeries.data.slice(index);

                series[0].setData(wfClosePriceSeriesAdjusted);
                const max = (wfClosePriceSeriesAdjusted as PointOptionsObject[])[wfClosePriceSeriesAdjusted.length - 1].x!;
                onChangeTimeRange(Infinity, { min: (wfClosePriceSeriesAdjusted as PointOptionsObject[])[0].x!, max });
            } else {
                series[0].setData(wfClosePriceSeries.data);
                onChangeTimeRange(Infinity);
            }
        }

        if (series.length && series.length - 1 > selectedBenchmarks.size) {
            removeUnselectedBenchmarkSeries(series, selectedBenchmarks);
        }
    }, [onChangeTimeRange, selectedBenchmarks, selectedBenchmarksSeries, wfClosePriceSeries, wfClosePriceSeries?.data, wfPriceSeries?.data]);

    const onZoom = useCallback(() => {
        setSelectedTime(-1);
    }, []);

    const chartAriaLabel =
        creationDate && currentPrice
            ? t(
                  "labels.benchmark-chart-aria-label",

                  {
                      ns: "wf-detail",
                      creationDate: formatDateShort(new Date(creationDate)),
                      currency: baseCurrency,
                      currentPrice: currentPrice,
                  }
              )
            : undefined;

    return (
        <Section pos="relative" w="100%" maxW="100%" {...boxProps}>
            <Flex mb={4}>
                <Flex flexGrow={1} align="center" mr={1}>
                    <TimeRangeSelect
                        isDisabled={isLoading || !wfPriceSeries}
                        value={selectedTime}
                        onChange={value => onChangeTimeRange(value)}
                        items={timeRangeItems}
                    />
                </Flex>
                <Button
                    size={["sm", "xs"]}
                    variant={isBenchmarkActive ? "solid" : "outline"}
                    flexShrink={0}
                    isDisabled={isAnalysisBtnDisabled}
                    onClick={overlay.onOpen}
                >
                    {dict.analysisBtn}
                </Button>
            </Flex>
            <Chart
                chartRef={chartRef}
                series={wfPriceSeries}
                isLoading={isLoading}
                onZoom={onZoom}
                onChartReady={onChartReady}
                h={CHART_HEIGHT}
                baseCurrency={baseCurrency}
                chartAriaLabel={chartAriaLabel}
            />
            {overlay.isOpen && (
                <BenchmarkSettingsModal
                    dict={dict.overlay}
                    state={benchmarkState}
                    onClose={overlay.onClose}
                    isOpen={overlay.isOpen}
                    defaultBenchmarksList={defaultBenchmarkIndexList}
                    showWikifolioDatesSection
                />
            )}
        </Section>
    );
};

import { useNavHeight } from "src/components/layout/hooks/use-nav-height";
import { JUMPMARK_HEIGHT } from "../consts";

export const useAnchorOffset = () => {
    const { NAV_HEIGHT_VARIATION } = useNavHeight();

    const STICKY_HEADERS_HEIGHT = [
        JUMPMARK_HEIGHT[0] + NAV_HEIGHT_VARIATION[0] + 1,
        JUMPMARK_HEIGHT[1] + NAV_HEIGHT_VARIATION[1] + 2,
        JUMPMARK_HEIGHT[1] + NAV_HEIGHT_VARIATION[2] + 2,
    ];
    const ANCHOR_OFFSET = STICKY_HEADERS_HEIGHT.map(value => value * 8);
    const ANCHOR_OFFSET_NEGATIVE_PX = ANCHOR_OFFSET.map(value => `-${value}px`);
    const ANCHOR_OFFSET_PX = ANCHOR_OFFSET.map(value => `${value}px`);

    return { ANCHOR_OFFSET, ANCHOR_OFFSET_NEGATIVE_PX, ANCHOR_OFFSET_PX } as const;
};

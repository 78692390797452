import { proxy, useSnapshot } from "valtio";

export interface IDetailPageStore {
    indexLevel: number;
}

export const detailPageStore = proxy({
    indexLevel: 0,

    setIndexLevel(indexLevel: number) {
        detailPageStore.indexLevel = indexLevel;
    },
});

export function useDetailPageStore() {
    return useSnapshot(detailPageStore);
}

import { ReactNode } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfPopover, PopoverButtonIconInfo } from "src/components/base/wf-popover";
import { getGlobals } from "src/consts/globals";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { ITooltip } from "src/types/common";
import { useCertificateBoxContext } from "../context";

const CERTIFICATE_BOX_STATISTIC_TEST_ID = "certificate-box-statistic";

interface ICBStatisticProps {
    label: string;
    value: ReactNode;
    tooltip: ITooltip;
}

const CBStatistic = ({ label, tooltip, value }: ICBStatisticProps) => (
    <Flex w="100%" justify="space-between" fontSize="xs" data-test-id={CERTIFICATE_BOX_STATISTIC_TEST_ID}>
        <Flex align="center">
            <Text lineHeight="short">{label}</Text>
            <WfPopover {...tooltip}>
                <PopoverButtonIconInfo ariaLabel={label} ml={1} />
            </WfPopover>
        </Flex>
        <Text as="strong">{value}</Text>
    </Flex>
);

export const CBEmission = () => {
    const { dict, data, selectedCertificate: certificate } = useCertificateBoxContext();
    const { formatDateShort } = useDateTimeFormatter();

    return (
        <CBStatistic label={dict.emissionDate} tooltip={data.tooltips.emissionDate!} value={formatDateShort(new Date(certificate.emissionDate))} />
    );
};

export const CBCertificateFee = () => {
    const { dict, data } = useCertificateBoxContext();
    const { formatPercent } = useNumberFormatter();

    return (
        <CBStatistic
            label={dict.base!.certificateFeePerYear}
            tooltip={data.tooltips.certificateFeePerYear}
            value={formatPercent(data.wikifolio.dailyFee, 2)}
        />
    );
};

export const CBPerformanceFee = () => {
    const { dict, data } = useCertificateBoxContext();
    const { formatPercent } = useNumberFormatter();

    return (
        <CBStatistic label={dict.base!.performanceFee} tooltip={data.tooltips.performanceFee} value={formatPercent(data.wikifolio.performanceFee)} />
    );
};

export const CBLiquidationFigure = () => {
    const { data } = useCertificateBoxContext();
    const { t } = useTranslation("wf-detail");
    const { formatNumber } = useNumberFormatter();

    let liquidationFigure = "-";
    if (data.keyFigures.liquidationFigure.ranking.value) {
        liquidationFigure = formatNumber(data.keyFigures.liquidationFigure.ranking.value, 1) + " " + t("days");
    }

    return (
        <CBStatistic
            label={data.keyFigures.liquidationFigure.ranking.label}
            tooltip={data.keyFigures.liquidationFigure.tooltip!}
            value={liquidationFigure}
        />
    );
};

export const CBTradingVolume = () => {
    const { data } = useCertificateBoxContext();
    const { formatNumber } = useNumberFormatter();

    let tradingVolume = "-";
    if (data.keyFigures.tradingVolume.ranking.value) {
        tradingVolume = `${getGlobals().currency} ${formatNumber(data.keyFigures.tradingVolume.ranking.value)}`;
    }

    return <CBStatistic label={data.keyFigures.tradingVolume.ranking.label} tooltip={data.keyFigures.tradingVolume.tooltip!} value={tradingVolume} />;
};

export const CBExchangeRatio = () => {
    const { dict, data, selectedCertificate: certificate } = useCertificateBoxContext();
    const { formatNumber } = useNumberFormatter();

    const ratio = `1 : ${formatNumber(certificate.exchangeRatioMultiplier)}`;

    return <CBStatistic label={dict.base!.exchangeRatio} tooltip={data.tooltips.exchangeRatio} value={ratio} />;
};

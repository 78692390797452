import { useRef } from "react";
import { BoxProps, Flex, AspectRatio, useBoolean } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ISalesContentData } from "src/api/server/wf-detail.types";
import { Section, FullSizeFlexCentered } from "src/components/base";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { WfHeading } from "src/components/base/wf-heading";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { IconPlay } from "src/components/icons";

const SALES_SECTION_TEST_ID = "sales-section";

export interface ISalesSectionProps extends BoxProps {
    data: ISalesContentData;
}

export const SalesSection = ({ data, ...boxProps }: ISalesSectionProps) => {
    const { t } = useTranslation("common");
    const [isPreviewImageVisible, setPreviewImageVisible] = useBoolean(true);
    const playerRef = useRef<HTMLIFrameElement>(null);

    return (
        <Section {...boxProps} data-test-id={SALES_SECTION_TEST_ID}>
            <Flex align="center" mb={3}>
                <WfHeading as="h2" fontSize="2xl">
                    {data.title}
                </WfHeading>
            </Flex>
            {data?.description && <SimpleRichText mb={5} fontSize="md" text={data.description} />}
            {data?.video && (
                <AspectRatio pos="relative" flexShrink={0} w={["100%", 670, 604, 767]} ratio={16 / 9} bg="gray.800" cursor="pointer">
                    {isPreviewImageVisible && data.video.previewImage?.url ? (
                        <>
                            <ResponsiveImage
                                position="absolute"
                                srcSet={[
                                    { queryParams: { w: 312, h: 176, fit: "crop" } },
                                    { queryParams: { w: 670, h: 378, fit: "crop" } },
                                    { queryParams: { w: 604, h: 341, fit: "crop" } },
                                    { queryParams: { w: 767, h: 432, fit: "crop" } },
                                ]}
                                baseUrl={data.video.previewImage.url}
                                alt={data.video.previewImage?.alt}
                                rounded="4px"
                                w="100%"
                            />
                            <FullSizeFlexCentered
                                onClick={setPreviewImageVisible.off}
                                _hover={{ bg: "whiteAlpha.300" }}
                                role="button"
                                aria-label={t("click-to-play-video")}
                            >
                                <IconPlay boxSize={10} color="white" border="4px" />
                            </FullSizeFlexCentered>
                        </>
                    ) : (
                        <iframe
                            ref={playerRef}
                            width="100%"
                            height="100%"
                            src={data.video.url}
                            title={data.video.alt ?? ""}
                            frameBorder="0"
                            allow="autoplay; fullscreen; encrypted-media"
                        />
                    )}
                </AspectRatio>
            )}
        </Section>
    );
};

import { MouseEvent } from "react";
import { useBoolean, useDisclosure } from "@chakra-ui/react";
import { Button, ButtonProps } from "src/components/base/button";
import { SavingsPlanModal } from "src/components/modals/savings-plan-modal/savings-plan-modal";

const SAVINGS_PLAN_BUTTON_TEST_ID = "savings-plan-button";

export interface ISavingsPlanButtonProps extends ButtonProps {
    wikifolioId: string;
    isin: string;
    wikifolioSecurityId: string;
    wkn: string;
}

export const SavingsPlanButton = ({ isin, wikifolioId, wikifolioSecurityId, wkn, onClick, children, ...buttonProps }: ISavingsPlanButtonProps) => {
    const modalDisclosure = useDisclosure();
    const [isLoading, setLoading] = useBoolean();

    const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        setLoading.on();
        modalDisclosure.onOpen();
        onClick?.(event);
    };

    return (
        <>
            <Button onClick={handleOnClick} {...buttonProps} isLoading={isLoading} data-test-id={SAVINGS_PLAN_BUTTON_TEST_ID}>
                {children}
            </Button>
            <SavingsPlanModal
                isin={isin}
                wikifolioId={wikifolioId}
                wikifolioSecurityId={wikifolioSecurityId}
                wkn={wkn}
                onRequestSettled={setLoading.off}
                {...modalDisclosure}
            />
        </>
    );
};

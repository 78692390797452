import { PropsWithChildren } from "react";
import { Flex, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { AnimatePresence, Variants } from "framer-motion";
import { MotionBox } from "src/components/base";
import { Button } from "src/components/base/button";
import { IconCaret } from "src/components/icons";
import { useCertificateBoxContext } from "../context";
import { DETAILS_ACCORDION_SHOW_BUTTON_TEST_ID } from "./details-accordion.test-ids";

const buttonVariants: Variants = {
    visible: {
        height: "40px",
        opacity: 1,
    },
    hidden: {
        height: "0px",
        opacity: 0,
    },
};

const childVariants: Variants = {
    open: {
        height: "auto",
    },
    closed: {
        height: "0px",
    },
};

export const CBDetailsAccordion = ({ children }: PropsWithChildren<unknown>) => {
    const { dict } = useCertificateBoxContext();
    let isDesktop = useBreakpointValue([false, false, true], { fallback: "md" });
    if (typeof isDesktop === "undefined") {
        isDesktop = true;
    }

    const _detailsState = useDisclosure();
    const state = {
        ..._detailsState,
        isOpen: isDesktop || _detailsState.isOpen,
        isDesktop,
    };

    // Requirement: if the accordion was opened manually once, the user can't close it again
    const showButton = !state.isDesktop && !state.isOpen;

    return (
        <Flex flexDir="column" w="100%">
            <AnimatePresence>
                {showButton && (
                    <MotionBox
                        textAlign="right"
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={buttonVariants}
                        transition={{ bounce: 0 }}
                    >
                        <Button
                            display="inline-flex"
                            variant="unstyled"
                            size="sm"
                            rightIcon={<IconCaret boxSize={3} transition="transform 0.3s" transform={state.isOpen ? "rotate(180deg)" : undefined} />}
                            onClick={state.onToggle}
                            data-test-id={DETAILS_ACCORDION_SHOW_BUTTON_TEST_ID}
                        >
                            {dict.show}
                        </Button>
                    </MotionBox>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {state.isOpen && (
                    <MotionBox
                        initial="open"
                        animate="open"
                        exit="closed"
                        variants={childVariants}
                        transition={{ bounce: 0 }}
                        overflow="hidden"
                        mx={-2}
                        pt={showButton ? 2 : 0}
                    >
                        {children}
                    </MotionBox>
                )}
            </AnimatePresence>
        </Flex>
    );
};

import { EnumAssetType, IPortfolioGroup, IPortfolioItem, IPortfolioResponse } from "src/api/client/portfolio-table.api";
import { ISortDirection } from "src/components/table/sort-table";
import { compareNumber, compareString } from "src/utils/compare-util";
import { getPerfSinceBuy, getPerfToday } from "./portfolio-table-util";

export type Sorting = {
    column: "name" | "performanceSinceBuy" | "performanceToday" | "weighting";
    direction: ISortDirection;
};

export const sortPortfolioTableDataByColumn = (data: IPortfolioResponse, { column, direction }: Sorting): IPortfolioResponse => {
    const compareName = (itemA: IPortfolioItem, itemB: IPortfolioItem) => compareString(itemA.name, itemB.name, direction);

    const comparePerformanceSinceBuy = (itemA: IPortfolioItem, itemB: IPortfolioItem) => {
        const perfSinceBuyA = getPerfSinceBuy(itemA);
        const perfSinceBuyB = getPerfSinceBuy(itemB);
        return compareNumber(perfSinceBuyA, perfSinceBuyB, direction);
    };

    const comparePerformanceToday = (itemA: IPortfolioItem, itemB: IPortfolioItem) => {
        const perfTodayA = getPerfToday(itemA);
        const perfTodayB = getPerfToday(itemB);
        return compareNumber(perfTodayA, perfTodayB, direction);
    };

    const compareWeighting = (itemA: IPortfolioItem, itemB: IPortfolioItem) => compareNumber(itemA.percentage, itemB.percentage, direction);

    const chooseCompareFunction = () => {
        switch (column) {
            case "name":
                return compareName;
            case "performanceSinceBuy":
                return comparePerformanceSinceBuy;
            case "performanceToday":
                return comparePerformanceToday;
            case "weighting":
                return compareWeighting;
        }
    };

    return {
        ...data,
        groups: data?.groups?.map(group => ({
            ...group,
            items: group.items.sort(chooseCompareFunction()),
        })),
    };
};

export const sortPortfolioTableDataGroups = (data: IPortfolioResponse) => {
    const dataToSort = { ...data, groups: [...data.groups] };
    dataToSort.groups.sort((a, b) => a.type - b.type);

    if (dataToSort.groups.length > 0 && dataToSort.groups[0].type === EnumAssetType.Cash) {
        dataToSort.groups.push(dataToSort.groups.shift() as IPortfolioGroup);
    }

    return dataToSort;
};

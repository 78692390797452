import { Box, BoxProps } from "@chakra-ui/react";
import { IKeyFigures, IWikifolioDetailProps } from "src/api/server/wf-detail.types";
import { Section } from "src/components/base";
import { WfHeading } from "src/components/base/wf-heading";
import { KeyFigureGroup } from "./key-figure-group";
import { OtherRiskIndicators } from "./other-risk-indicators";

interface IKeyFiguresProps extends BoxProps {
    keyFigures: IKeyFigures;
    dict: IWikifolioDetailProps["dict"];
}

export const KeyFigures = ({ keyFigures, dict, ...boxProps }: IKeyFiguresProps) => {
    return (
        <Section {...boxProps}>
            <WfHeading as="h2" fontSize="2xl" mb={[3, 4]}>
                {dict.keyfiguresTitle}
            </WfHeading>
            {keyFigures.kpis.map((keyFigureGroup, index) => (
                <Box key={index} _notLast={{ mb: 5 }}>
                    <KeyFigureGroup keyFigureGroup={keyFigureGroup} showHeadline={true}></KeyFigureGroup>
                </Box>
            ))}
            <OtherRiskIndicators keyFigureGroups={keyFigures.otherKeyRiskIndicators}></OtherRiskIndicators>
        </Section>
    );
};

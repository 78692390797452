import { BoxProps, Divider, Flex, Grid, HStack, IconButton, Image, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { IWikifolioDetailProps } from "src/api/server/wf-detail.types";
import { Section } from "src/components/base";
import { ArrowLink } from "src/components/base/arrow-link";
import { Button } from "src/components/base/button";
import { WfHeading } from "src/components/base/wf-heading";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { ShareWikifolioButton } from "src/components/common/buttons/share/share-wikifolio-button";
import { WatchlistButton } from "src/components/common/buttons/watchlist-button/watchlist-button";
import { Tag } from "src/components/common/tag/tag";
import { TraderAvatar } from "src/components/common/trader-avatar";
import { WfTradernameProfileLink } from "src/components/common/wf-tradername-profile-link";
import { IconPencil, IconTrade } from "src/components/icons";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { useGetUserName } from "src/hooks/use-get-user-name";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { useUserStore } from "src/stores/user-store";
import { useWikifolioStore } from "src/stores/wikifolio-store";
import { EnumTagColor, EnumWikifolioStatus } from "src/types/common-enum";
import { isServerSide } from "src/utils/common-util";
import { getFullName } from "src/utils/user-name-util";

interface IProps extends BoxProps {
    headData: {
        metaTitle: string;
        metaDescription: string;
        url: string;
    };
    data: Pick<IWikifolioDetailProps["data"], "wikifolio" | "trader" | "tradeLink" | "editLink" | "myWikifoliosLink">;
}

export const TopSection = ({ headData, data, ...boxProps }: IProps) => {
    const { wikifolio, trader, tradeLink, editLink, myWikifoliosLink } = data;
    const { buildTraderProfileLink } = useLinkBuilder();
    const { formatDateShort } = useDateTimeFormatter();
    const userStore = useUserStore();
    const { watchlist, setOnWatchlist } = useWikifolioStore();
    const isOnWatchlist = watchlist[wikifolio.id] ?? false;
    const { t } = useTranslation(["common", "wf-detail"]);
    const getUserName = useGetUserName();

    const isInvestable = wikifolio.status === EnumWikifolioStatus.Investable;
    const showShareButton = wikifolio.status !== EnumWikifolioStatus.Test && wikifolio.status !== EnumWikifolioStatus.PublishingRequested;

    const currentUrl = isServerSide() ? "" : `${window.location.origin}${window.location.pathname}`;

    const hasFullNameAndNickName = !trader.isDeleted && !trader.companyName && Boolean(getFullName(trader) && trader.nickName);
    const traderProfileLink = buildTraderProfileLink(trader.nickName);

    return (
        <Section {...boxProps}>
            {userStore.isCurrentUser(trader) && (
                <Stack mb={3} direction={["column", "row"]} align={["initial", "center"]} justifyContent="space-between" spacing={3}>
                    <ArrowLink href={myWikifoliosLink} colorScheme="green">
                        {t("to-my-wikifolios")}
                    </ArrowLink>
                    <Grid templateColumns={["1fr 1fr", "auto auto"]} gridColumnGap={2}>
                        <IconButton
                            as={WfLinkUnstyled}
                            href={editLink}
                            size="sm"
                            variant="outline"
                            aria-label={t("edit-wikifolio")}
                            icon={<IconPencil />}
                        />
                        <Button
                            as={WfLinkUnstyled}
                            href={tradeLink}
                            size="sm"
                            variant="outline"
                            leftIcon={<IconTrade />}
                            className="gtm-wf-dbcard__trade"
                        >
                            Trade
                        </Button>
                    </Grid>
                </Stack>
            )}
            <WfHeading as="h1" mb={3} fontSize={["2xl", "3xl", "4xl"]}>
                {wikifolio.shortDescription}
            </WfHeading>
            <Grid templateColumns={["1fr", "1fr auto"]} gridRowGap={4} gridColumnGap={1}>
                <HStack spacing={0}>
                    <TraderAvatar href={traderProfileLink} mr={[1, 2]} size={["xl", "2xl"]} trader={trader} loading="eager" />
                    <Flex flexDir="column" overflow="hidden">
                        <Grid templateColumns="auto 1fr" fontSize={["md", "lg", "xl"]} mb={0.5}>
                            <WfTradernameProfileLink
                                href={traderProfileLink}
                                name={trader.companyName || getUserName(trader)}
                                isDeleted={trader.isDeleted}
                            />

                            {hasFullNameAndNickName && (
                                <Text display={["none", "none", "block"]} noOfLines={1} wordBreak="break-all">
                                    &nbsp;{`| ${trader.nickName}`}
                                </Text>
                            )}
                        </Grid>
                        <Text fontSize="xs" color="gray.400">
                            {t("last-login")}: {formatDateShort(trader.lastLogin)}
                        </Text>
                        {trader.isSelfRegulatedAssetManager && (
                            <Tag
                                label={t("sro")}
                                tooltip={trader.selfRegulatedAssetManagerTooltip}
                                color={EnumTagColor.Gray}
                                alignSelf={"flex-start"}
                                mt={"0.5"}
                                px={1}
                                py={0}
                                h={"auto"}
                                borderColor={"gray.400"}
                                fontSize="2xs"
                            />
                        )}
                    </Flex>
                    {trader.profileLogoData && (
                        <Image
                            pl={3}
                            maxH="48px"
                            maxW="128px"
                            src={trader.profileLogoData}
                            alt={t("company-logo-of", { ns: "wf-detail", companyName: trader?.companyName })}
                        />
                    )}
                </HStack>
                <Grid alignItems="center" templateColumns={isInvestable ? ["1fr 1fr", "auto auto"] : ["1fr", "auto"]} gridColumnGap={2}>
                    {showShareButton && (
                        <ShareWikifolioButton url={currentUrl} metaTitle={headData.metaTitle} metaDescription={headData.metaDescription} />
                    )}
                    {isInvestable && (
                        <WatchlistButton
                            size="sm"
                            wikifolioId={wikifolio.id}
                            wikifolioShortDescription={wikifolio.shortDescription}
                            isOnWatchlist={isOnWatchlist}
                            setOnWatchlist={setOnWatchlist}
                        />
                    )}
                </Grid>
            </Grid>
            <Divider mt={3} borderColor="gray.100" display={["block", "none"]} />
        </Section>
    );
};

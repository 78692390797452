import { Chart, PointOptionsObject, Series, SeriesLineOptions } from "highcharts/highstock";
import { IPriceIndexResponse } from "src/api/client/price-chart.api";
import { IFormatters } from "src/components/common/benchmark/configs";
import { IDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { INumberFormatter } from "src/hooks/formatters/use-number-formatter";

const WIKI = "wiki";

export const toChartTicks = (formatter: IDateTimeFormatter, data: IPriceIndexResponse) => {
    return data.values.map((value, index) => {
        const date = new Date(data.timestamps[index]);
        const x = date.getTime();
        return {
            x: x,
            y: value,
            formattedDate: formatter.formatDateWithWeekday(x),
            // Comment from old code:
            // | Workaround to format 1440 ticks and 60/15 ticks separately
            // | WikifolioTickdataAggregated Duration = 1440 ticks have a BeginDate with the time set to 01:00 or 02:00
            showShortDateFormat: (date.getHours() === 1 || date.getHours() === 2) && date.getMinutes() === 0,
        };
    });
};

const getChartSeries = (formatter: IDateTimeFormatter, id: string, color: string, data: IPriceIndexResponse): SeriesLineOptions => ({
    type: "line",
    id,
    name: id,
    lineWidth: 2,
    data: toChartTicks(formatter, data),
    color,
    marker: { symbol: "circle" },
});

export const getWfPriceTick = (formatter: IDateTimeFormatter, color: string, data: IPriceIndexResponse) =>
    getChartSeries(formatter, WIKI, color, data);
export const getBenchmarkPriceTick = (formatter: IDateTimeFormatter, id: string, color: string, data: IPriceIndexResponse) =>
    getChartSeries(formatter, id, color, data);

const getChartTooltipDate = (formatter: IDateTimeFormatter, showShortDateFormat: boolean, dateInMilSec: number) => {
    if (showShortDateFormat) {
        return formatter.formatDateWithWeekday(dateInMilSec);
    } else {
        return formatter.formatDateTimeWithWeekday(dateInMilSec);
    }
};

export const CLASS_BENCHMARK_TOOLTIP = "benchmark-tooltip";
export interface IBenchmarkPoint {
    color: string;
    series: { name: string };
    change: number;
}
export const getBenchmarkTooltip = (numberFormatter: INumberFormatter, { color, series, change }: IBenchmarkPoint) => {
    let label = series.name;
    if (series.name === WIKI) {
        label = `<span class="chart-wf-chart-icon"></span>`;
    }
    return `<div class="${CLASS_BENCHMARK_TOOLTIP}" style="background-color: ${color}">
        <span>${label}</span><strong>${numberFormatter.formatPerformance(change / 100, 2)}</strong>
    </div>`;
};

export interface IChartPoint {
    x: number;
    y: number;
    showShortDateFormat: boolean;
}
export const CLASS_CHART_TOOLTIP = "chart-tooltip";
export const getChartTooltip = (
    { numberFormatter, dateTimeFormatter }: IFormatters,
    { x, y, showShortDateFormat }: IChartPoint,
    baseCurrency?: string
) => {
    return `<div class="${CLASS_CHART_TOOLTIP}">
        <strong>${baseCurrency} ${numberFormatter.formatNumber(y, 2)}</strong>
        <div>${getChartTooltipDate(dateTimeFormatter, showShortDateFormat, x)}</div>
    </div>`;
};

export const removeUnselectedBenchmarkSeries = (series: Series[], selectedBenchmarks: Set<string>) => {
    const unselectedBenchmarks: Series[] = [];
    series.forEach((item, index) => {
        if (index === 0) {
            // Wikifolio price series
            return;
        }
        if (!selectedBenchmarks.has(item.name)) {
            unselectedBenchmarks.push(item);
        }
    });
    unselectedBenchmarks.forEach(item => item.remove());
};

export const mapBenchmarkNamesToBenchmarkSeries = (benchmarkNames: Set<string>, benchmarkSeriesMap: Map<string, SeriesLineOptions>) => {
    return Array.from(benchmarkNames)
        .map(benchmarkName => benchmarkSeriesMap.get(benchmarkName))
        .filter(series => Boolean(series)) as SeriesLineOptions[];
};

export const getLastEmissionDateFromBenchmarkSeries = (series: SeriesLineOptions[], closePriceStartDate: number) =>
    series
        .reduce<number[]>(
            (acc, b) => {
                const startDate: number | undefined = (b?.data as PointOptionsObject[])[0]?.x;
                if (startDate) {
                    acc.push(startDate);
                }

                return acc;
            },
            [closePriceStartDate]
        )
        .sort((a, b) => b - a)[0];

export const drawChartSeries = (chart: Chart, seriesToDraw: SeriesLineOptions[], currentSeries: Series[], lastEmissionDate: number) => {
    seriesToDraw.forEach(benchmark => {
        if (!benchmark?.data) {
            return null;
        }

        // Replace series if lastEmissionDate is changed
        const activeBenchmark = currentSeries.find(b => b.name === benchmark.name);
        if (activeBenchmark?.data[0]?.x) {
            if (activeBenchmark.name === benchmark.name) {
                const activeBenchmarkEmissionDate = activeBenchmark.data[0].x;
                if (activeBenchmarkEmissionDate === lastEmissionDate) {
                    return;
                }
                activeBenchmark.remove();
            }
        }

        const index = (benchmark.data as PointOptionsObject[]).findIndex(series => series.x === lastEmissionDate);
        if (index > 0) {
            // Add to chart series with modified length
            chart.addSeries({
                ...benchmark,
                data: benchmark.data.slice(index),
            });
        } else {
            chart.addSeries(benchmark);
        }
    });
};

import { RefObject, useEffect, useState } from "react";
import { useTradeHistoryTableQuery } from "src/api/client/trade-history.api";
import useViewportSpy from "src/hooks/dom/use-viewport-spy";
import { scrollIntoView } from "src/utils/dom";
import { EnumJumpMarkSection } from "../jump-marks/consts";
import { useJumpMarkAnchorRefs } from "../jump-marks/jump-mark-anchor-refs-provider";

export const useTradeHistoryTable = (wikifolioId: string, sectionRef: RefObject<HTMLDivElement>) => {
    const isVisible = useViewportSpy(sectionRef, { threshold: 0.1 });
    const [enabled, setEnabled] = useState(false);
    const { getRef } = useJumpMarkAnchorRefs();

    const [page, setPage] = useState(0);
    const { isLoading, isError, data, isFetching, isPreviousData } = useTradeHistoryTableQuery(wikifolioId, page, { enabled: isVisible });

    useEffect(() => {
        if (isVisible && !enabled) {
            setEnabled(true);
        }
    }, [enabled, isVisible]);

    const handleScrollIntoView = () => {
        const jumpMarkAnchorRef = getRef(EnumJumpMarkSection.Trades);
        const isLastPage = data?.tradeHistory.pageCount && data.tradeHistory.pageCount - 1 === page + 1;

        if (jumpMarkAnchorRef && isLastPage) {
            scrollIntoView(jumpMarkAnchorRef, { behavior: "smooth" });
        }
    };

    return {
        isLoading,
        isError,
        data,
        newerBtnProps: {
            onClick: () => setPage(old => Math.max(old - 1, 0)),
            isDisabled: isFetching || page === 0,
        },
        olderBtnProps: {
            onClick: () => {
                if (!isPreviousData && data && data.tradeHistory.pageCount > page) {
                    setPage(old => old + 1);
                }

                handleScrollIntoView();
            },
            isDisabled:
                isFetching ||
                // Dev-Note: In case there is no trade-history -> pageCount is 0.
                data?.tradeHistory.pageCount === 0 ||
                data?.tradeHistory.pageCount === page + 1,
        },
    };
};

import { UseQueryOptions } from "react-query";
import { createApiLeverageProductContentUrl, API_DISABLE_LEVERAGE_PRODUCTS_DISCLAMER_URL } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { useQuery } from "src/hooks/react-query/use-query";
import { useRouterLocale } from "src/utils/router/use-router-locale";

export interface ILeverageProductsResponse {
    message: string;
    redirectUrl: string;
}

export function useLeverageProductsQuery(options?: UseQueryOptions<ILeverageProductsResponse>) {
    const { language, country } = useRouterLocale();

    return useQuery<ILeverageProductsResponse>(createApiLeverageProductContentUrl(country, language), {
        ...options,
    });
}

interface IRequestDisableLeverageProducts {
    onError: (error: unknown, variables: unknown, context: unknown) => void | Promise<unknown>;
}

export function useDisableLeverageProductsMutation(options?: IRequestDisableLeverageProducts) {
    const { mutate } = useMutation<ILeverageProductsResponse>(API_DISABLE_LEVERAGE_PRODUCTS_DISCLAMER_URL, {
        ...options,
        method: "PUT",
    });
    return () => mutate({});
}

import { ArrowLink, IArrowLinkProps } from "src/components/base/arrow-link";
import { EnumJumpMarkSection } from "./consts";
import { useJumpToSection } from "./use-jump-to-section";

interface IProps extends Omit<IArrowLinkProps, "href"> {
    section: EnumJumpMarkSection;
}

export const JumpMarkLink = ({ section, ...arrowLinkProps }: IProps) => {
    const jumpToSection = useJumpToSection();

    return (
        <ArrowLink
            href={"#" + EnumJumpMarkSection.Portfolio}
            onClick={event => {
                event.preventDefault();
                jumpToSection(section);
            }}
            {...arrowLinkProps}
        />
    );
};

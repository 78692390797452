import { useRef, useState } from "react";
import { Box, Flex, Radio, RadioGroup, SelectProps, Text, useBoolean, VStack } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { useTranslation } from "next-i18next";
import { EnumTimeRange, useDownloadMutation } from "src/api/client/detail-page-download.api";
import { IErrorResponse } from "src/api/client/types";
import { IWikifolioDetailData, IWikifolioDetailDict } from "src/api/server/wf-detail.types";
import { MotionBox } from "src/components/base";
import { Button } from "src/components/base/button";
import { WfHeading } from "src/components/base/wf-heading";
import { WfPopover, PopoverButtonIconInfo } from "src/components/base/wf-popover";
import { WfSelect } from "src/components/base/wf-select";
import { CONTAINER_PX_NEGATIVE } from "src/components/layout/container";
import { EnumToastStatus, useWfToast } from "src/hooks/use-wf-toast";
import { EnumDownloadType, EnumWikifolioStatus } from "src/types/common-enum";

interface ITimeRangeSelectProps extends Omit<SelectProps, "value" | "onChange"> {
    value: EnumTimeRange;
    onChange: (value: EnumTimeRange) => void;
    dict: IWikifolioDetailDict["downloadArea"];
    wikifolio: IWikifolioDetailData["wikifolio"];
}

const TimeRangeSelect = ({ value, onChange, dict, wikifolio, ...selectProps }: ITimeRangeSelectProps) => {
    const isWikifolioInvestable = wikifolio.status === EnumWikifolioStatus.Investable;

    return (
        <MotionBox
            m={-1}
            p={1}
            transition={{ bounce: 0 }}
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            overflow="hidden"
        >
            <WfSelect mt={2} value={value} onChange={e => onChange(Number(e.target.value))} {...selectProps}>
                <option value={EnumTimeRange.Today}>{dict.today}</option>
                <option value={EnumTimeRange.Last7Days}>{dict.last7Days}</option>
                <option value={EnumTimeRange.Last30Days}>{dict.last30Days}</option>
                <option value={EnumTimeRange.Last90Days}>{dict.last90Days}</option>
                <option value={EnumTimeRange.LastYear}>{dict.lastYear}</option>
                <option value={EnumTimeRange.YTD}>{dict.ytd}</option>
                {isWikifolioInvestable && <option value={EnumTimeRange.SinceEmission}>{dict.sinceEmission}</option>}
                <option value={EnumTimeRange.SinceCreation}>{dict.sinceCreation}</option>
            </WfSelect>
        </MotionBox>
    );
};

interface IDownloadSectionProps {
    data: Pick<IWikifolioDetailData, "wikifolio" | "downloadArea">;
    dict: IWikifolioDetailDict["downloadArea"];
}

export const DownloadSection = ({ data: { wikifolio, downloadArea: tooltips }, dict }: IDownloadSectionProps) => {
    const [downloadType, setDownloadType] = useState<EnumDownloadType>(EnumDownloadType.Portfolio);
    const [timeRangePriceData, setTimeRangePriceData] = useState<EnumTimeRange>(EnumTimeRange.SinceCreation);
    const [timeRangeAccountStatement, setTimeRangeAccountStatement] = useState<EnumTimeRange>(EnumTimeRange.SinceCreation);
    const toast = useWfToast();
    const toastVisible = useRef(false);
    const downloadMutation = useDownloadMutation();
    const { t } = useTranslation("common");
    const [isLoading, setLoading] = useBoolean();

    const onError = (title: string) => {
        if (!toastVisible.current) {
            toastVisible.current = true;
            toast({ status: EnumToastStatus.Error, duration: 5000, title: title });
            setTimeout(() => {
                toastVisible.current = false;
            }, 5000);
        }
    };

    const onDownloadClick = async () => {
        let range: EnumTimeRange | null;
        switch (downloadType) {
            case EnumDownloadType.PriceData:
                range = timeRangePriceData;
                break;
            case EnumDownloadType.AccountStatement:
                range = timeRangeAccountStatement;
                break;
            default:
                range = null;
                break;
        }

        try {
            setLoading.on();
            await downloadMutation({
                wikifolioId: wikifolio.id.toString(),
                type: downloadType,
                range: range,
            });
        } catch (error) {
            onError((error as IErrorResponse)?.message || t("general-error"));
        }
        setLoading.off();
    };

    const inflowOutflowDownloadAllowed = [EnumWikifolioStatus.Investable, EnumWikifolioStatus.Barred, EnumWikifolioStatus.ClosingInProgress].includes(
        wikifolio.status
    );

    return (
        <Box bg="gray.50" p={3} borderRadius={[null, "8px"]} mx={[CONTAINER_PX_NEGATIVE[0], 0]}>
            <WfHeading as="h2" fontSize="2xl" mb={2}>
                {dict.title}
            </WfHeading>
            <Text mb={3}>{dict.description}</Text>
            <RadioGroup
                mb={4}
                value={String(downloadType)}
                onChange={(value: string) => {
                    setDownloadType(Number(value));
                }}
            >
                <VStack align="flex-start" spacing={2}>
                    <Radio value={String(EnumDownloadType.Portfolio)}>{dict.portfolio}</Radio>
                    <Flex flexDir="column">
                        <Flex as="span" align="center">
                            <Radio value={String(EnumDownloadType.PriceData)}>{dict.priceData}</Radio>
                            <WfPopover {...tooltips.priceData}>
                                <PopoverButtonIconInfo ariaLabel={dict.priceData} ml={1} colorScheme="black" />
                            </WfPopover>
                        </Flex>
                        <AnimatePresence>
                            {downloadType === EnumDownloadType.PriceData && (
                                <TimeRangeSelect
                                    value={timeRangePriceData}
                                    onChange={value => setTimeRangePriceData(value)}
                                    dict={dict}
                                    wikifolio={wikifolio}
                                    mb={1}
                                />
                            )}
                        </AnimatePresence>
                    </Flex>
                    <Flex flexDir="column">
                        <Flex as="span" align="center">
                            <Radio value={String(EnumDownloadType.AccountStatement)}>{dict.accountStatement}</Radio>
                            <WfPopover {...tooltips.accountStatement}>
                                <PopoverButtonIconInfo ariaLabel={dict.accountStatement} ml={1} colorScheme="black" />
                            </WfPopover>
                        </Flex>
                        <AnimatePresence>
                            {downloadType === EnumDownloadType.AccountStatement && (
                                <TimeRangeSelect
                                    value={timeRangeAccountStatement}
                                    onChange={value => setTimeRangeAccountStatement(value)}
                                    dict={dict}
                                    wikifolio={wikifolio}
                                />
                            )}
                        </AnimatePresence>
                    </Flex>
                    {inflowOutflowDownloadAllowed && (
                        <Flex as="span" align="center">
                            <Radio value={String(EnumDownloadType.InflowOutflow)}>{dict.inflowOutflow}</Radio>
                            <WfPopover {...tooltips.inflowOutflow}>
                                <PopoverButtonIconInfo ariaLabel={dict.inflowOutflow} ml={1} colorScheme="black" />
                            </WfPopover>
                        </Flex>
                    )}
                </VStack>
            </RadioGroup>
            <Button w={["full", "fit-content"]} onClick={onDownloadClick} isLoading={isLoading} _disabled={{ opacity: 0.75 }}>
                {dict.downloadButton}
            </Button>
        </Box>
    );
};

import { StackDivider, VStack } from "@chakra-ui/react";
import { WfLinkGhost } from "src/components/base/wf-link";
import { InvestButton } from "src/components/common/buttons/innvest-button/invest-button";
import { SavingsPlanButton } from "src/components/common/buttons/savings-plan-button/savings-plan-button";
import { Certificate } from "../components/certificate";
import { CBDetailsAccordion } from "../components/details-accordion";
import { CBHeader } from "../components/header";
import { CBInvestedCapital } from "../components/invested-capital";
import { CBIsin } from "../components/isin";
import { CBPrices } from "../components/prices";
import { CBRatioTabBar } from "../components/ratio-tab-bar";
import { CBCertificateFee, CBEmission, CBLiquidationFigure, CBPerformanceFee, CBTradingVolume, CBExchangeRatio } from "../components/statistics";
import { useCertificateBoxContext } from "../context";

const END_AGREEMENT_TEST_ID = "end-agreement";
const LINK_PROSPECT_TEST_ID = "link-prospect";

export const CertificateBoxInvestable = () => {
    const { dict, data, selectedCertificate: certificate } = useCertificateBoxContext();
    const isHiddenCertificate = certificate.isHidden;
    const isPrimaryCertificate = certificate.isPrimary;

    return (
        <>
            <CBHeader />
            {data.certificates.length > 1 && <CBRatioTabBar />}
            <CBPrices />
            <CBIsin />
            <CBInvestedCapital />
            <CBDetailsAccordion>
                <VStack spacing={2} divider={<StackDivider borderColor="gray.100" />} px={2}>
                    <Certificate />
                    <VStack w="100%">
                        <CBEmission />
                        <CBCertificateFee />
                        <CBPerformanceFee />
                        <CBLiquidationFigure />
                        <CBTradingVolume />
                        {isHiddenCertificate && <CBExchangeRatio />}
                    </VStack>
                    <VStack w="100%" fontSize="2xs" align="flex-start">
                        <WfLinkGhost
                            href={certificate.endAgreementUrl!}
                            target="_blank"
                            rel="noreferrer noopener"
                            data-test-id={END_AGREEMENT_TEST_ID}
                        >
                            {dict.endAgreementText}
                        </WfLinkGhost>
                        <WfLinkGhost
                            href={certificate.linkProspectUrl!}
                            target="_blank"
                            rel="noreferrer noopener"
                            data-test-id={LINK_PROSPECT_TEST_ID}
                        >
                            {dict.linkProspectText}
                        </WfLinkGhost>
                    </VStack>
                </VStack>
            </CBDetailsAccordion>
            <InvestButton
                wikifolioId={data.wikifolio.id}
                isin={certificate.isin}
                wikifolioSecurityId={certificate.wikifolioSecurityId}
                w="100%"
                colorScheme="green"
                size="sm"
            >
                {dict.investNow}
            </InvestButton>
            {certificate.showSavingsPlanButton && isPrimaryCertificate && (
                <SavingsPlanButton
                    wikifolioId={data.wikifolio.id}
                    isin={certificate.isin}
                    wikifolioSecurityId={certificate.wikifolioSecurityId!}
                    wkn={certificate.wkn}
                    w="100%"
                    variant="outline"
                    size="sm"
                >
                    {dict.createSavingPlan}
                </SavingsPlanButton>
            )}
        </>
    );
};

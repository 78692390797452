import { useCallback, useEffect, useState } from "react";
import {
    Checkbox,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    useDisclosure,
    UseDisclosureReturn,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useTranslation } from "next-i18next";
import { ILeverageProductsResponse, useDisableLeverageProductsMutation, useLeverageProductsQuery } from "src/api/client/leverage-products.api";
import { Button } from "src/components/base/button";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { useCookieBotConsentReady } from "src/hooks/cookie-bot/use-cookie-bot-consent-ready";
import { useFirstInteraction } from "src/hooks/use-first-interaction";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { useDisclaimerStore } from "src/stores/disclaimer-store";
import { isServerSide } from "src/utils/common-util";
import { logger } from "src/utils/logger-util";

const FAILED_DISABLE_DISCLAMER_ERROR_MESSAGE = "Failed to disable leverage products disclaimer";

interface ILeverageProductsModalProps {
    containsLeverageProducts: boolean;
}

export const LeverageProductsModal = ({ containsLeverageProducts }: ILeverageProductsModalProps) => {
    const disclaimerStore = useDisclaimerStore();
    const isCookieBotConsentReady = useCookieBotConsentReady();
    const modalProps = useDisclosure();
    const { t } = useTranslation("common");
    const toast = useWfToast();

    const { onOpen } = modalProps;

    useEffect(() => {
        if (modalProps.isOpen && !disclaimerStore.showDisclaimer) {
            onOpen();
        }
    }, [onOpen, modalProps.isOpen, disclaimerStore.showDisclaimer]);

    const { data, isLoading, isError } = useLeverageProductsQuery({
        enabled: modalProps.isOpen,
        onError: () => {
            toast({ status: EnumToastStatus.Error, title: t("general-error") });
            modalProps.onClose();
        },
    });

    const handleOnFirstInteraction = useCallback(() => {
        // the leveraged products disclaimer should only be shown after the first user interaction
        onOpen();
    }, [onOpen]);

    useFirstInteraction({
        // we don't even listen for the first user interaction if we know that the disclaimer doesn't need to be shown anyway
        isDisabled: !containsLeverageProducts || isServerSide() || Cookies.get("_lvrg_wrn") === "False" || !isCookieBotConsentReady,
        onFirstInteraction: handleOnFirstInteraction,
    });

    if (!modalProps.isOpen || isLoading || isError || !data) {
        return null;
    }

    return <LeverageProductsModalContent responseData={data} {...modalProps} />;
};

interface IContentProps extends UseDisclosureReturn {
    responseData: ILeverageProductsResponse;
}

export const LeverageProductsModalContent = ({ responseData, ...modalProps }: IContentProps) => {
    const { t } = useTranslation("common");
    const disableLeverageProductsDisclaimer = useDisableLeverageProductsMutation({
        onError: error => {
            logger.error({ message: FAILED_DISABLE_DISCLAMER_ERROR_MESSAGE, error });
        },
    });

    const [isLoading, setLoading] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);

    const onAcceptClick = () => {
        setLoading(true);

        if (dontShowAgain) {
            disableLeverageProductsDisclaimer();
        }

        modalProps.onClose();
    };

    return (
        <Modal {...modalProps} closeOnEsc={false} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent maxW={["full", "750px", "750px", "708px"]}>
                <ModalHeader pb={1} color="red.600">
                    {t("important-notice")}
                </ModalHeader>
                <ModalBody>
                    <SimpleRichText text={responseData.message} mt={1} mb={3} />
                    <Checkbox isChecked={dontShowAgain} onChange={e => setDontShowAgain(e.target.checked)}>
                        {t("dont-show-notice")}
                    </Checkbox>
                </ModalBody>
                <ModalFooter>
                    <Stack direction={["column", "row-reverse"]} w={["100%", null]} spacing={2}>
                        <Button isLoading={isLoading} onClick={onAcceptClick}>
                            {t("display-wikifolio")}
                        </Button>
                        <Button
                            as={WfLinkUnstyled}
                            href={responseData.redirectUrl}
                            isLoading={isLoading}
                            onClick={() => setLoading(true)}
                            variant="outline"
                        >
                            {t("search-wikifolios")}
                        </Button>
                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

import { MotionValue } from "framer-motion";
import { EnumJumpMarkSection } from "./consts";

export function getJumpMarkHash(section: EnumJumpMarkSection) {
    return section === EnumJumpMarkSection.Overview ? "#" : `#${section}`;
}

// is used for scrolling a tab into the viewport, not for scrolling to a section!
export const scrollTabIntoViewport = (tabScrollX: MotionValue, tabElement?: HTMLDivElement) => {
    if (!tabElement) {
        return;
    }

    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    // let's create two rects so we can easier calculate the viewport
    const tabBarRect = {
        x1: -tabScrollX.get(),
        x2: -tabScrollX.get() + windowWidth,
    };
    const tabElementRect = {
        x1: tabElement.offsetLeft,
        x2: tabElement.offsetLeft + tabElement.offsetWidth,
    };

    const isTabElementOutside = tabElementRect.x1 < tabBarRect.x1 || tabElementRect.x2 > tabBarRect.x2;

    // jumpmark tab is not visible in the tabbar yet, let's scroll it into the viewport
    if (isTabElementOutside) {
        if (tabElementRect.x1 > tabBarRect.x1) {
            // if it is hidden behind the right side of the current viewport, scroll it to be the last visible item
            tabScrollX.set(-tabElement.offsetLeft + windowWidth - tabElement.offsetWidth);
        } else {
            // if it is hidden behind the left side of the current viewport, scroll it to be the first visible item
            tabScrollX.set(-tabElement.offsetLeft);
        }
    }
};

export function isElementInViewport(el: HTMLElement, anchorOffset = 0) {
    const rect = el.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    // Dev-Note: we add 2 pixels to the anchorOffset so that the element is really out of the viewport
    const vertInView = rect.top <= windowHeight && rect.top + rect.height - (anchorOffset + 2) >= 0;
    const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

    return vertInView && horInView;
}

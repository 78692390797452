import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import {
    IDeclareInterestMutationError,
    IDeclareInterestResponse,
    useDeclareInterestMutation,
    useDeclareInterestQuery,
} from "src/api/client/declare-interest.api";
import { Button } from "src/components/base/button";
import { WfModalCloseButton } from "src/components/base/wf-modal-close-button";
import { WfSelect } from "src/components/base/wf-select";
import { useFormValidator } from "src/hooks/form-validator/use-form-validator";
import { useForm } from "src/hooks/use-form";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { useUserStore } from "src/stores/user-store";
import { logger } from "src/utils/logger-util";
import { useRouterLocale } from "src/utils/router/use-router-locale";
import { DECLARE_INTEREST_FORM_TEST_ID } from "./declare-interest-modal.test-ids";

const ID_DECLARE_INTEREST_FORM = "declare-interest-form";
const FAILED_DECLARE_INTEREST_ERROR_MESSAGE = "Failed to declare interest";

interface IDeclareInterestModalProps extends Omit<ModalProps, "children"> {
    wikifolioId: string;
    onRequestSettled?: () => void;
}

export const DeclareInterestModal = (props: IDeclareInterestModalProps) => {
    const { wikifolioId, onRequestSettled, ...modalProps } = props;
    const toast = useWfToast();
    const { country } = useRouterLocale();
    const { t } = useTranslation("common");

    const userStore = useUserStore();
    const {
        data: responseData,
        isLoading,
        isError,
    } = useDeclareInterestQuery({
        enabled: modalProps.isOpen,
        onError: () => {
            toast({ status: EnumToastStatus.Error, title: t("general-error") });
            modalProps.onClose();
        },
        onSettled: onRequestSettled,
    });

    if (!modalProps.isOpen || isLoading || isError || !responseData) {
        return null;
    }

    const prefilledEmail = userStore.user?.email ?? "";
    const prefilledCountry = country === "int" ? responseData.data.countries[0].value : country;

    return <DeclareInterestModalContent {...{ wikifolioId, prefilledEmail, prefilledCountry, responseData }} {...modalProps} />;
};

interface IFormValues {
    email: string;
    country: string;
}

interface IContentProps extends Omit<ModalProps, "children"> {
    wikifolioId: string;
    prefilledEmail: string;
    prefilledCountry: string;
    responseData: IDeclareInterestResponse;
}

export const DeclareInterestModalContent = (props: IContentProps) => {
    const {
        wikifolioId,
        prefilledEmail,
        prefilledCountry,
        responseData: { data, dict },
        ...modalProps
    } = props;

    const { t } = useTranslation("common");
    const toast = useWfToast();
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, errors },
        setError,
        isFieldInvalid,
    } = useForm<IFormValues>({
        mode: "onSubmit",
        defaultValues: { email: prefilledEmail, country: prefilledCountry },
    });

    const validators = useFormValidator();
    const declareInterest = useDeclareInterestMutation();

    const onSubmit = handleSubmit(async values => {
        try {
            const response = await declareInterest({
                wikifolioId,
                email: values.email,
                country: values.country,
            });
            toast({ status: EnumToastStatus.Success, title: response.message });
            modalProps.onClose();
        } catch (error) {
            logger.error({ message: FAILED_DECLARE_INTEREST_ERROR_MESSAGE, error });
            Object.entries(error as IDeclareInterestMutationError).forEach(([fieldName, errorMessage]) => {
                setError(fieldName as keyof IFormValues, { message: errorMessage });
            });
        }
    });

    return (
        <Modal {...modalProps}>
            <ModalOverlay />
            <ModalContent maxW={["full", "750px", "750px", "708px"]}>
                <ModalHeader pb={1}>{dict.headline}</ModalHeader>
                <WfModalCloseButton />
                <ModalBody pt={1}>
                    <Text mb={4}>{dict.description}</Text>
                    <VStack
                        as="form"
                        id={ID_DECLARE_INTEREST_FORM}
                        data-test-id={DECLARE_INTEREST_FORM_TEST_ID}
                        spacing={3}
                        noValidate
                        onSubmit={onSubmit}
                    >
                        <FormControl id="declare-interest-email" isInvalid={isFieldInvalid("email")}>
                            <FormLabel>{t("email")}</FormLabel>
                            <Input placeholder={t("email")} type="email" autoFocus {...register("email", { ...validators.email() })} />
                            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                        </FormControl>
                        <FormControl id="declare-interest-country" isInvalid={isFieldInvalid("country")}>
                            <FormLabel>{t("country")}</FormLabel>
                            <WfSelect {...register("country", { required: true })}>
                                {data.countries.map(({ label, value }) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </WfSelect>
                            <FormErrorMessage>{errors.country?.message}</FormErrorMessage>
                        </FormControl>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button isLoading={isSubmitting} type="submit" form={ID_DECLARE_INTEREST_FORM} w={["full", "fit-content"]}>
                        {dict.sendButton}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

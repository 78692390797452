import { Text, VStack } from "@chakra-ui/react";
import { IconClosed } from "src/components/icons";
import { CBDetailsAccordion } from "../components/details-accordion";
import { CBHeader } from "../components/header";
import { CBInvestedCapital } from "../components/invested-capital";
import { CBPricesNotTicking } from "../components/prices";
import { CBCertificateFee, CBExchangeRatio, CBEmission, CBLiquidationFigure, CBPerformanceFee, CBTradingVolume } from "../components/statistics";
import { useCertificateBoxContext } from "../context";

const BARRED_MESSAGE_TEST_ID = "barred-message";

export const CertificateBoxBarred = () => {
    const { dict, selectedCertificate: certificate } = useCertificateBoxContext();
    const isHiddenCertificate = certificate.isHidden;

    return (
        <>
            <CBHeader />
            <CBPricesNotTicking />
            <CBInvestedCapital />
            <CBDetailsAccordion>
                <VStack w="100%" px={2}>
                    <CBEmission />
                    <CBCertificateFee />
                    <CBPerformanceFee />
                    <CBLiquidationFigure />
                    <CBTradingVolume />
                    {isHiddenCertificate && <CBExchangeRatio />}
                </VStack>
                <VStack spacing={2} mt={4} px={2}>
                    <IconClosed boxSize={10} />
                    <Text fontSize="sm" textAlign="center" data-test-id={BARRED_MESSAGE_TEST_ID}>
                        {dict.barredMessage}
                    </Text>
                </VStack>
            </CBDetailsAccordion>
        </>
    );
};

import { MutableRefObject, useEffect, useRef } from "react";
import { Box, BoxProps, useToken } from "@chakra-ui/react";
import Highstock from "highcharts/highstock";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import { useTranslation } from "next-i18next";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { isServerSide, getObjectCopy, useIsomorphicLayoutEffect } from "src/utils/common-util";
import { ChartLoadingState } from "../chart-shared-components/chart-loading-state";
import { ChartNoDataState } from "../chart-shared-components/chart-no-data-state";
import { CHART_TOOLTIP_STYLES } from "./chart-tooltip-style";
import { getChartConfig, getChartGlobalConfig } from "./configs";

interface IChartProps extends BoxProps {
    chartRef: MutableRefObject<Highstock.Chart | null>;
    isLoading: boolean;
    baseCurrency: string;
    chartAriaLabel?: string;
    onZoom: () => void;
    series?: Highstock.SeriesOptionsType;
    // Callback when chart finishes animation or has no data
    onChartReady?: () => void;
}

export const Chart = ({ chartRef, isLoading, baseCurrency, chartAriaLabel, onZoom, onChartReady, series, ...boxProps }: IChartProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const [gray200, gray500, gray800] = useToken("colors", ["gray.200", "gray.500", "gray.800"]);
    const dateTimeFormatter = useDateTimeFormatter();
    const numberFormatter = useNumberFormatter();
    const { t } = useTranslation("date-range");

    const chartHasNoData = !isLoading && !series;

    if (chartHasNoData) {
        onChartReady?.();
    }

    useEffect(() => {
        if (!isServerSide()) {
            HighchartsAccessibility(Highstock);
        }
    }, []);

    useIsomorphicLayoutEffect(() => {
        if (!series) {
            return;
        }

        // Dev-Note: The `series` object has to serialized!
        // Otherwise by replacing the series data with new one (e.g. `series[0].setData(newSeries)`)
        // It UPDATES the original series object with the new one!
        const _series = getObjectCopy(series);

        Highstock.setOptions(getChartGlobalConfig(t, chartAriaLabel));
        chartRef.current = Highstock.chart(
            ref.current!,
            getChartConfig(
                { dateTimeFormatter, numberFormatter },
                {
                    gray200,
                    gray500,
                    gray800,
                    series: {
                        ..._series,
                        events: {
                            afterAnimate: onChartReady,
                        },
                    },
                    baseCurrency,
                    onZoom,
                }
            )
        );
    }, [series]);

    return (
        <Box pos="relative" pointerEvents="fill">
            <Box ref={ref} maxW="100%" sx={CHART_TOOLTIP_STYLES} {...boxProps} />
            {isLoading && <ChartLoadingState {...boxProps} />}
            {chartHasNoData && <ChartNoDataState {...boxProps} />}
        </Box>
    );
};

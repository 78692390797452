import { RefObject, useEffect, useState } from "react";
import { usePortfolioTableQuery } from "src/api/client/portfolio-table.api";
import { ISortDirection } from "src/components/table/sort-table";
import useViewportSpy from "src/hooks/dom/use-viewport-spy";
import { EnumJumpMarkSection } from "../jump-marks/consts";
import { useJumpMarkAnchorRefs } from "../jump-marks/jump-mark-anchor-refs-provider";
import { sortPortfolioTableDataByColumn, Sorting, sortPortfolioTableDataGroups } from "./sort-utils";

export const usePortfolioTable = (fullName: string, sectionRef: RefObject<HTMLDivElement>) => {
    const isVisible = useViewportSpy(sectionRef, { threshold: 0.1 });
    const { scrollName } = useJumpMarkAnchorRefs();
    const isReadyToLoadPortfolioTable = scrollName === null || scrollName === EnumJumpMarkSection.Portfolio;

    const { data: rawData, isLoading, isError } = usePortfolioTableQuery(fullName, { enabled: isVisible && isReadyToLoadPortfolioTable });
    const [sortedData, setSortedData] = useState(rawData);
    const [sorting, setSorting] = useState<Sorting>();

    useEffect(() => {
        if (rawData) {
            const dataWithSortedGroups = sortPortfolioTableDataGroups(rawData);
            const sortedTableData = sorting ? sortPortfolioTableDataByColumn(dataWithSortedGroups, sorting) : dataWithSortedGroups;
            setSortedData(sortedTableData);
        }
    }, [rawData, sorting]);

    const onSortName = (direction: ISortDirection) => {
        setSorting({ column: "name", direction });
    };

    const onSortPerfSinceBuy = (direction: ISortDirection) => {
        setSorting({ column: "performanceSinceBuy", direction });
    };

    const onSortPerfToday = (direction: ISortDirection) => {
        setSorting({ column: "performanceToday", direction });
    };

    const onSortWeighting = (direction: ISortDirection) => {
        setSorting({ column: "weighting", direction });
    };

    return {
        isLoading,
        isError,
        data: sortedData,
        onSortName,
        onSortPerfSinceBuy,
        onSortPerfToday,
        onSortWeighting,
    };
};

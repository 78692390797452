import { proxy, useSnapshot } from "valtio";
// DEV-note, because we want a fast loading (non dropping frames) benchmark/chart and smooth animation we can delay
// heavy browser work on first page load, that are not on the page view
// use this state when you need to perform tasks that blocks main thread.
const benchmarkReadyStore = proxy({
    isReady: false,

    get isBenchmarkReady() {
        return this.isReady;
    },

    setIsReady() {
        if (!benchmarkReadyStore.isReady) {
            benchmarkReadyStore.isReady = true;
        }
    },
});

export const setIsBenchmarkReady = () => {
    benchmarkReadyStore.setIsReady();
};

export function useBenchmarkReadyStore() {
    return useSnapshot(benchmarkReadyStore);
}

import { Box, BoxProps } from "@chakra-ui/react";
import { EnumJumpMarkSection } from "./consts";
import { useAnchorOffset } from "./hooks/use-anchor-offset";
import { useJumpMarkAnchorRefs } from "./jump-mark-anchor-refs-provider";

interface IProps extends Omit<BoxProps, "id" | "mt" | "pt" | "marginTop" | "paddingTop" | "margin" | "padding"> {
    section: EnumJumpMarkSection;
}

export const JumpMarkAnchor = ({ section, ...boxProps }: IProps) => {
    const { setRef } = useJumpMarkAnchorRefs();
    const { ANCHOR_OFFSET_NEGATIVE_PX, ANCHOR_OFFSET_PX } = useAnchorOffset();
    // Dev-Note: we are working with the negative marginTop and positive paddingTop hack here, because scrollMarginTop is only supported in Safari since April
    // with this we have the best browser support
    return (
        <Box
            id={section}
            ref={(jumpMarkRef: HTMLDivElement) => {
                setRef(section, jumpMarkRef);
            }}
            mt={ANCHOR_OFFSET_NEGATIVE_PX}
            pt={ANCHOR_OFFSET_PX}
            pointerEvents="none"
            {...boxProps}
        />
    );
};

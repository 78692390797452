import { UseQueryOptions } from "react-query";
import { createApiWikifolioPortfolioUrl } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";
import { EnumIssuerType } from "src/types/common-enum";

export enum EnumAssetType {
    Stocks = 620,
    ETFs = 630,
    Derivatives = 640,
    Wikifolios = 650,
    Cash = 0,
}

export interface IPortfolioItem {
    name: string;
    isin: string;
    quantity: number;
    bid: number;
    ask: number;
    averagePurchasePrice: number;
    isTicking: boolean;
    isLeveraged: boolean;
    isLtsuActive: boolean;
    link: string;
    close: number | null;
    issuer: EnumIssuerType | null;
    mid: number | null;
    openLinkInSameTab: boolean;
    percentage: number | null;
    partnerName: string;
}

export interface IPortfolioGroup {
    items: IPortfolioItem[];
    type: EnumAssetType;
    value: number;
    percentage: number;
}

export interface IPortfolioResponse {
    groups: IPortfolioGroup[];
    currency: string;
    isSuperWikifolio: boolean;
    totalValue: number | null;
}

export const usePortfolioTableQuery = (fullName: string, options: UseQueryOptions<IPortfolioResponse>) =>
    useQuery<IPortfolioResponse>(createApiWikifolioPortfolioUrl(fullName), {
        // Dev-Note: we want to refetch data every 10 seconds for the portfolio table
        staleTime: 10_000,
        refetchInterval: 10_000,
        retry: 1,
        ...options,
    });

import { useState } from "react";
import { Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalProps, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { EnumActivityType, useGenericActivityMutation } from "src/api/client/generic-activity.api";
import { useSavingsPlanModalQuery, ISavingsPlanResponse } from "src/api/client/savings-plan.api";
import { Button } from "src/components/base/button";
import { ComboBox, ComboBoxItem } from "src/components/base/combo-box";
import { WfLink, WfLinkUnstyled } from "src/components/base/wf-link";
import { WfModalCloseButton } from "src/components/base/wf-modal-close-button";
import { BrokerImage, MAX_HEIGHT_BROKER_LIST } from "src/components/modals/invest-now-modal/invest-now-modal";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { triggerCallbackOnMiddleClickEvent } from "src/utils/dom/mouse-util";
import { SAVINGS_PLAN_MODAL_CONTINUE_BUTTON_TEST_ID } from "./savings-plan-modal.test-ids";

interface ISavingsPlanModalProps extends Omit<ModalProps, "children"> {
    isin: string;
    wikifolioId: string;
    wikifolioSecurityId: string;
    wkn: string;
    onRequestSettled: () => void;
}

export const SavingsPlanModal = (props: ISavingsPlanModalProps) => {
    const { isin, wikifolioId, wikifolioSecurityId, wkn, onRequestSettled, ...modalProps } = props;
    const { t } = useTranslation("common");
    const toast = useWfToast();
    const {
        data: responseData,
        isLoading,
        isError,
    } = useSavingsPlanModalQuery(
        {
            wikifolioSecurityId,
            isin,
            wkn,
        },
        {
            enabled: modalProps.isOpen,
            onError: () => {
                toast({ status: EnumToastStatus.Error, title: t("general-error") });
                modalProps.onClose();
            },
            onSettled: onRequestSettled,
        }
    );

    if (!modalProps.isOpen || isLoading || isError || !responseData) {
        return null;
    }

    if (responseData) {
        onRequestSettled();
    }

    return <SavingsPlanModalContent {...{ isin, wikifolioId, responseData }} {...modalProps} />;
};

interface IContentProps extends Omit<ModalProps, "children"> {
    isin: string;
    wikifolioId: string;
    responseData: ISavingsPlanResponse;
}

export const SavingsPlanModalContent = ({ isin, wikifolioId, responseData, ...modalProps }: IContentProps) => {
    const {
        data: { preferredBroker, brokers, settingsLink },
        dict,
    } = responseData;

    const hasPreferredSavingsPlanBroker = preferredBroker !== null;
    const genericActivity = useGenericActivityMutation();

    const [selectedSavingsPlan, setSelectedSavingsPlan] = useState(preferredBroker);

    const onNextButtonClick = () => {
        if (selectedSavingsPlan !== null) {
            modalProps.onClose();
            genericActivity({
                activityTypeId: EnumActivityType.CreateSavingsPlanViaBrokerLinkClicked,
                parameter: JSON.stringify({ wikifolioId: wikifolioId, broker: selectedSavingsPlan.name }),
            });
        }
    };

    return (
        <Modal {...modalProps}>
            <ModalOverlay />
            <ModalContent maxW={["full", "750px", "750px", "708px"]}>
                <ModalHeader pb={1}>{dict.header}</ModalHeader>
                <WfModalCloseButton />
                <ModalBody pb={5}>
                    <Text fontSize={["md", "xl"]} mb={[2, 3]}>
                        {dict.subHeader}
                    </Text>
                    <VStack w={["100%", "50%"]} spacing={2} align="flex-start">
                        {hasPreferredSavingsPlanBroker ? (
                            <Flex align="center">
                                <BrokerImage broker={preferredBroker!} />
                            </Flex>
                        ) : (
                            <ComboBox
                                value={selectedSavingsPlan}
                                onChange={value => setSelectedSavingsPlan(value)}
                                placeholder={dict.placeholder}
                                menuListProps={{ maxH: MAX_HEIGHT_BROKER_LIST }}
                            >
                                {brokers.map(broker => (
                                    <ComboBoxItem key={broker.name} value={broker} h={5}>
                                        <BrokerImage broker={broker} h={3} />
                                    </ComboBoxItem>
                                ))}
                            </ComboBox>
                        )}
                        <Button
                            as={WfLinkUnstyled}
                            href={selectedSavingsPlan?.url}
                            isDisabled={selectedSavingsPlan === null}
                            target="_blank"
                            size={["md", "sm"]}
                            w={["100%", "auto"]}
                            onClick={onNextButtonClick}
                            onAuxClick={triggerCallbackOnMiddleClickEvent(() => onNextButtonClick())}
                            isExternal
                            className="gtm-invest-modal__savings-plan-broker-button"
                            data-gtm-isin={isin}
                            data-test-id={SAVINGS_PLAN_MODAL_CONTINUE_BUTTON_TEST_ID}
                        >
                            {dict.button}
                        </Button>
                    </VStack>
                    {hasPreferredSavingsPlanBroker && (
                        <VStack spacing={1} align="flex-start" mt={4}>
                            <Text fontSize="sm">{dict.notYourPreferredBroker}</Text>
                            <WfLink size="sm" href={settingsLink.url} target={settingsLink.target}>
                                {settingsLink.name}
                            </WfLink>
                        </VStack>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

import { VStack } from "@chakra-ui/react";
import { Button } from "src/components/base/button";
import { WfLink, WfLinkUnstyled } from "src/components/base/wf-link";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { IconClosed } from "src/components/icons";
import { CBDetailsAccordion } from "../components/details-accordion";
import { CBHeader } from "../components/header";
import { CBInvestedCapital } from "../components/invested-capital";
import { CBPrices } from "../components/prices";
import { CBRatioTabBar } from "../components/ratio-tab-bar";
import { CBCertificateFee, CBEmission, CBExchangeRatio, CBLiquidationFigure, CBPerformanceFee, CBTradingVolume } from "../components/statistics";
import { useCertificateBoxContext } from "../context";

const CLOSING_MESSAGE_TEST_ID = "closing-message";
const CLOSING_BUTTON_TEST_ID = "closing-button";

export const CertificateBoxClosingInProgress = () => {
    const { dict, data, selectedCertificate: certificate } = useCertificateBoxContext();
    const isHiddenCertificate = certificate.isHidden;

    return (
        <>
            <CBHeader />
            {data.certificates.length > 1 && <CBRatioTabBar />}
            <CBPrices />
            <CBInvestedCapital />
            <CBDetailsAccordion>
                <VStack w="100%" px={2}>
                    <CBEmission />
                    <CBCertificateFee />
                    <CBPerformanceFee />
                    <CBLiquidationFigure />
                    <CBTradingVolume />
                    {isHiddenCertificate && <CBExchangeRatio />}
                </VStack>
                <VStack spacing={2} mt={4} mb={1} px={2}>
                    <IconClosed boxSize={10} />
                    <SimpleRichText fontSize="sm" textAlign="center" text={dict.closingMessage} data-test-id={CLOSING_MESSAGE_TEST_ID} />
                    {certificate.closingLinkUrl && <WfLink href={certificate.closingLinkUrl}>{dict.closingLink}</WfLink>}
                </VStack>
            </CBDetailsAccordion>
            <Button
                as={WfLinkUnstyled}
                href={certificate.closingClosedButtonUrl}
                w="100%"
                colorScheme="green"
                size="sm"
                data-test-id={CLOSING_BUTTON_TEST_ID}
            >
                {dict.closingClosedButton}
            </Button>
        </>
    );
};

import { UseQueryOptions } from "react-query";
import { createApiWikifolioTradeHistoryUrl } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";
import { ITradeHistoryResponse } from "./trade-history.api.types";

const PAGE_SIZE = 10;

export const useTradeHistoryTableQuery = (wikifolioId: string, page: number, options: UseQueryOptions<ITradeHistoryResponse>) => {
    const queryParams = new URLSearchParams();
    queryParams.set("page", `${page}`);
    queryParams.set("pageSize", `${PAGE_SIZE}`);
    return useQuery<ITradeHistoryResponse>(createApiWikifolioTradeHistoryUrl(wikifolioId), {
        retry: 0,
        keepPreviousData: true,
        queryParams,
        ...options,
    });
};

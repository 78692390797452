import { Text, VStack } from "@chakra-ui/react";
import { Button } from "src/components/base/button";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { DeclareInterestButton } from "src/components/common/buttons/declare-interest-button/declare-interest-button";
import { IconNotAvailable } from "src/components/icons";
import { CBDetailsAccordion } from "../components/details-accordion";
import { CBHeader } from "../components/header";
import { CBInvestedCapital } from "../components/invested-capital";
import { CBPrices } from "../components/prices";
import { CBRatioTabBar } from "../components/ratio-tab-bar";
import { CBCertificateFee, CBEmission, CBExchangeRatio, CBLiquidationFigure, CBPerformanceFee, CBTradingVolume } from "../components/statistics";
import { useCertificateBoxContext } from "../context";

const NOT_LICENSED_MESSAGE_TEST_ID = "not-licensed-message";
const FIND_INVESTABLE_BUTTON_TEST_ID = "find-investable-button";

export const CertificateBoxNotLicensedAll = () => {
    const { dict, data, selectedCertificate: certificate } = useCertificateBoxContext();
    const isHiddenCertificate = certificate.isHidden;

    return (
        <>
            <CBHeader />
            {data.certificates.length > 1 && <CBRatioTabBar />}
            <CBPrices />
            <CBInvestedCapital />
            <CBDetailsAccordion>
                <VStack w="100%" px={2}>
                    <CBEmission />
                    <CBCertificateFee />
                    <CBPerformanceFee />
                    <CBLiquidationFigure />
                    <CBTradingVolume />
                    {isHiddenCertificate && <CBExchangeRatio />}
                </VStack>
            </CBDetailsAccordion>
            <VStack spacing={2} py={2}>
                <IconNotAvailable boxSize={10} />
                <Text fontSize="sm" textAlign="center" data-test-id={NOT_LICENSED_MESSAGE_TEST_ID}>
                    {dict.notLicensedMessage}
                </Text>
            </VStack>
            {certificate.findInvestableUrl && (
                <Button
                    as={WfLinkUnstyled}
                    href={certificate.findInvestableUrl}
                    w="100%"
                    colorScheme="green"
                    size="sm"
                    data-test-id={FIND_INVESTABLE_BUTTON_TEST_ID}
                >
                    {dict.findInvestableButton}
                </Button>
            )}
            <DeclareInterestButton w="100%" variant="outline" size="sm" wikifolioId={data.wikifolio.id}>
                {dict.declareInterestButton}
            </DeclareInterestButton>
        </>
    );
};

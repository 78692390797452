import { IBestsellerData } from "src/components/modules/bestseller-module/bestseller.types";
import { IImage, ILink, IWikifolioDescription } from "src/types/common";
import { IBasePageProps } from "src/types/layout";

export interface IFeedData {
    followerFeedMobileHeadlineLink: ILink | null;
    recommendations: IBestsellerData[];
    traderDashboardCardImage: IImage | null;
    traderDashboardCardLink: ILink | null;
    traderFeedMobileDashboardLink: ILink | null;
    traderFeedMobileWatchlistLink: ILink | null;
    userWikifolioDescriptions: ReadonlyArray<IWikifolioDescription> | null;
    watchlistCardImage: IImage;
    watchlistCardLink: ILink;
    isTrendingNewsToggleActive: boolean;
    userFeedFilters: IUserFeedFilter[];
}

export interface IUserFeedFilter {
    feedType: EnumFeedType;
    hash: string;
    label: string;
}

export enum EnumFeedType {
    UserFeed,
    UserFeedTraderComments,
    UserFeedWikifolioPortfolioChanges,
    UserFeedTradingMotives,
    UserFeedHighlights,
    AllNews,
    WikifolioNews,
    DailyLunchbox,
    WeeklyLunchbox,
    WikifolioEntity,
    TraderEntity,
    UnderlyingEntity,
}

export interface IFeedDict {
    commentingModalEditorPlaceholder: string;
    followerCardsHeadline: string;
    followerFeedMobileHeadline: string;
    headline: string;
    mobileHeadline: string;
    feedTab: string;
    newsTab: string;
    allNewsToggle: string;
    commentReportDescription: string;
    trendingNewsToggle: string;
    recommendationsHeadline: string;
    traderCardsHeadline: string;
    traderDashboardCardText: string;
    traderFeedMobileHeadline: string;
    watchlistCardText: string;
}

export interface IFeedProps extends IBasePageProps<IFeedData, IFeedDict> {}

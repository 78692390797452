import { Box, BoxProps, MenuButton } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button } from "src/components/base/button";
import { IconShare } from "src/components/icons";
import { ShareButton } from "./share-button";
import { useShareLinksBasic } from "./share-button-util";

export interface IShareOption {
    link: string;
    label: string;
    icon?: JSX.Element;
    isHandheld?: boolean;
}

export interface IShareWikifolioButtonProps extends BoxProps {
    /** The text content of `<title>` in `<head>` */
    metaTitle: string;
    /** The text content of `<meta name="description">` in `<head>` */
    metaDescription: string;
    /** URL to wikifolio */
    url: string;
}

export const ShareWikifolioButton = ({ metaTitle, metaDescription, url, ...boxProps }: IShareWikifolioButtonProps) => {
    const shareOptions = useShareLinksBasic(metaTitle, metaDescription, url);
    const { t } = useTranslation("common");

    return (
        <Box {...boxProps}>
            <ShareButton link={url} shareOptions={shareOptions} webShareData={{ title: metaTitle, url, text: metaDescription }}>
                <MenuButton
                    as={Button}
                    size="sm"
                    variant="ghost"
                    leftIcon={<IconShare boxSize={3} />}
                    w="100%"
                    sx={{
                        span: { flex: "0 1 auto" },
                    }}
                    className="gtm-share"
                    data-gtm-action="share wikifolio click"
                >
                    {t("share-wikifolio")}
                </MenuButton>
            </ShareButton>
        </Box>
    );
};

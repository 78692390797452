import { Box, Flex } from "@chakra-ui/react";
import { FullSizeFlexCentered } from "src/components/base";
import { IconCheckCircle } from "src/components/icons";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";

const CERTIFICATE_BOX_PROGRESS_BAR_TEST_ID = "certificate-box-progress-bar";

interface IProps {
    prefix?: string;
    currentValue: number;
    requiredValue: number;
    description: string;
}

export const CBProgressBar = ({ prefix, currentValue, requiredValue, description }: IProps) => {
    const { formatNumber } = useNumberFormatter();

    const percentage = currentValue < requiredValue ? Math.round((100 * currentValue) / requiredValue) : 100;
    const isCompleted = percentage === 100;

    return (
        <Box pos="relative" w="100%" bg="gray.100" data-test-id={CERTIFICATE_BOX_PROGRESS_BAR_TEST_ID}>
            <FullSizeFlexCentered h="100%" w={`${percentage}%`} bg="green.200" transition="width 0.4s" />
            <Flex w="100%" fontSize="xs" py={1} px={2} align="center" pos="relative">
                <Box mr={1}>
                    {isCompleted ? (
                        <IconCheckCircle fill="white" boxSize={3} color="green.600" />
                    ) : (
                        <Box rounded="full" boxSize="20px" m="2px" border="2px solid" borderColor="white" />
                    )}
                </Box>
                <strong>
                    {prefix ? `${prefix} ` : ""}
                    {formatNumber(currentValue)} / {formatNumber(requiredValue)}
                </strong>
                &nbsp;{description}
            </Flex>
        </Box>
    );
};

import { SelectedWikifoliosModule } from "src/components/modules/selected-wikifolios/selected-wikifolios-module";
import { ISelectedWikifolioData } from "src/components/modules/selected-wikifolios/selected-wikifolios-module.types";
import { chunkArrayEqually } from "src/utils/array-util";

export interface ISelectedWikifoliosProps {
    headline: string;
    selectedWikifolios: Array<ISelectedWikifolioData> | null;
    canCalculateScroll?: boolean;
}

export const SelectedWikifolios = ({ headline, selectedWikifolios, canCalculateScroll }: ISelectedWikifoliosProps) => {
    if (!selectedWikifolios) {
        return null;
    }

    const wikifolioColumns = chunkArrayEqually(selectedWikifolios, 3);

    return (
        <SelectedWikifoliosModule
            colorScheme="gray"
            title={headline}
            wfCardProps={{ className: "gtm-top-wikifolios-link" }}
            canCalculateScroll={canCalculateScroll}
            wikifolioColumns={[
                {
                    wikifolios: wikifolioColumns[0],
                },
                {
                    wikifolios: wikifolioColumns[1],
                },
                {
                    wikifolios: wikifolioColumns[2],
                },
            ]}
        />
    );
};

import { ISortDirection } from "src/components/table/sort-table";

type IOptionalString = string | null | undefined;
type IOptionalNumber = number | null | undefined;

/**
 * Sorts passed two strings `a` and `b` for given direction.
 * - case-insensitive
 * - `null`, `undefined` and empty string will **always** put as last (independent of direction!)
 */
export const compareString = (a: IOptionalString, b: IOptionalString, direction: ISortDirection) => {
    if (a === b) {
        return 0;
    }

    if (!a) {
        return 1;
    }

    if (!b) {
        return -1;
    }

    return a.localeCompare(b) * (direction === "desc" ? 1 : -1);
};

/**
 * Sorts passed two number `a` and `b` for given direction.
 * - `null` and `undefined` will **always** put as last (independent of direction!)
 */
export const compareNumber = (a: IOptionalNumber, b: IOptionalNumber, direction: ISortDirection) => {
    if (a === b) {
        return 0;
    }

    if (a === undefined || a === null) {
        // 0 is valid sort number!
        return 1;
    }

    if (b === undefined || b === null) {
        // 0 is valid sort number!
        return -1;
    }

    return (a - b) * (direction === "desc" ? 1 : -1);
};

import { useCallback, useEffect, useRef } from "react";
import { Flex, VStack } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { EnumFeedType } from "src/api/server/feed.types";
import { IWikifolioFeedDict } from "src/api/server/wf-detail.types";
import { Section } from "src/components/base";
import { Button } from "src/components/base/button";
import { WfHeading } from "src/components/base/wf-heading";
import { EmptyState } from "src/components/common/empty-state";
import { FeedReportModal } from "src/components/feed-card/feed-report-modal";
import { FeedCardSkeleton } from "src/components/feed-card/skeleton/feed-card-skeleton";
import { EnumPageType, initializeFeedTrackingStore } from "src/components/feed-card/store/feed-tracking-store";
import { IFeedCardListProps } from "src/components/feed-card-list/feed-card-list";
import { useFeedItemSeenTracking } from "src/components/pages/feed/hooks/use-feed-item-seen-tracking";
import { useInfiniteFeed } from "src/components/pages/feed/hooks/use-infinite-feed";
import { API_FEED_V3_WIKIFOLIO } from "src/consts/urls";
import { ContentSeenItem } from "../../feed/types/types";
import { useCallbackOnVisibility } from "./use-callback-on-visibility";

export interface IFeedSectionProps {
    wikifolioId: string;
    dict: IWikifolioFeedDict;
}

const LoadingState = () => (
    <>
        <FeedCardSkeleton />
        <FeedCardSkeleton />
        <FeedCardSkeleton />
    </>
);

const FeedView = dynamic<IFeedCardListProps>(() => import("src/components/feed-card-list/feed-card-list").then(component => component.FeedCardList), {
    loading: () => <LoadingState />,
});

export const FeedSection = ({ wikifolioId, dict }: IFeedSectionProps) => {
    const { t } = useTranslation("common");
    const { feed, isLoading, isError, hasNextPage, fetchNextPage, refetch } = useInfiniteFeed({
        feedUrl: API_FEED_V3_WIKIFOLIO,
        feedType: EnumFeedType.WikifolioEntity,
        wikifolioId,
        enabled: false,
    });
    const sectionRef = useRef<HTMLDivElement>(null);
    const seenItemsRef = useRef<Map<string, ContentSeenItem>>(new Map());
    const { trackSeenItem } = useFeedItemSeenTracking(seenItemsRef);

    useEffect(() => initializeFeedTrackingStore({ page: EnumPageType.WikifolioDetail, wikifolioId: wikifolioId }), [wikifolioId]);

    useCallbackOnVisibility({ refetch, sectionRef });

    const onFirstSeen = useCallback(
        (contentId: string) => {
            trackSeenItem(contentId);
        },
        [trackSeenItem]
    );

    return (
        <Section ref={sectionRef} position="relative" zIndex={1}>
            <WfHeading as="h2" fontSize="2xl" mb={2}>
                {dict.title}
            </WfHeading>
            <VStack spacing={2} align="left">
                {!feed && isError && <EmptyState title={t("generic-error-heading")} description={t("generic-error-message")} />}
                {(!feed || isLoading) && !isError && <LoadingState />}
                {feed && !feed.length && <EmptyState {...dict.empty} />}
                {feed && (
                    <>
                        <FeedView onItemFirstSeen={onFirstSeen} feedCards={feed} />
                        <FeedReportModal commentReportDescription={dict.commentingModalEditorPlaceholder} />
                    </>
                )}
                {hasNextPage && (
                    <Flex justify="center" mt={6}>
                        <Button variant="outline" onClick={() => fetchNextPage()}>
                            {t("show-more")}
                        </Button>
                    </Flex>
                )}
            </VStack>
        </Section>
    );
};

import { logger } from "../logger-util";
import InMemoryStorage from "./in-memory-storage";

interface IStorage {
    get<T>(key: string): T | null;
    set(key: string, value: unknown): void;
    remove(key: string): void;
}

export const canUseLocalStorage = (): boolean => {
    try {
        if (!window || !window.localStorage) {
            return false;
        }

        const testKey = "@wikifolio/storage/test";
        const testVal = "1";

        window.localStorage.setItem(testKey, testVal);

        const storageAvailable = window.localStorage.getItem(testKey) === testVal;

        window.localStorage.removeItem(testKey);

        return storageAvailable;
    } catch (e) {
        return false;
    }
};

class LocalStorage implements IStorage {
    storage: Storage;

    constructor(storage: Storage) {
        this.storage = storage;
    }

    get<T>(key: string): T | null {
        try {
            return JSON.parse(this.storage.getItem(key) || "null") as T | null;
        } catch (e) {
            logger.error({ message: "LocalStorage:get failed", error: e });
            return null;
        }
    }

    set(key: string, value: unknown): void {
        try {
            this.storage.setItem(key, JSON.stringify(value));
        } catch (e) {
            logger.error({ message: "LocalStorage:set failed", error: e });
        }
    }

    remove(key: string): void {
        try {
            this.storage.removeItem(key);
        } catch (e) {
            logger.error({ message: "LocalStorage:remove failed", error: e });
        }
    }
}

export const localStorage = new LocalStorage(canUseLocalStorage() ? window.localStorage : new InMemoryStorage());

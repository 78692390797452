import { getIconWikifolioHtml } from "src/utils/icon-html-string";
import { CLASS_BENCHMARK_HEADER } from "./configs";
import { CLASS_BENCHMARK_TOOLTIP, CLASS_CHART_TOOLTIP } from "./utils/chart-util";

export const CHART_TOOLTIP_STYLES = {
    ["& *"]: {
        fontFamily: "body",
        color: "white",
        textAlign: "center",
    },
    ["." + CLASS_CHART_TOOLTIP]: {
        fontSize: "md",
        bg: "gray.800",
        minW: "165px",
        p: 1,
        rounded: "8px",
        div: {
            fontSize: "xs",
            mt: "2px",
        },
    },
    ["." + CLASS_BENCHMARK_TOOLTIP]: {
        fontSize: "md",
        display: "flex",
        alignItems: "center",
        px: 2,
        py: 1,
        rounded: "8px",
        strong: {
            ml: 2,
        },
        [".chart-wf-chart-icon"]: {
            _before: {
                content: `url("data:image/svg+xml;utf8,${getIconWikifolioHtml({ fill: "white" })}")`,
                display: "inline-block",
                boxSize: "1em",
                mt: 0.5,
            },
        },
    },
    ".highcharts-label-box": {
        fill: "transparent",
    },
    [".highcharts-tooltip-header"]: {
        top: "4px !important", // Benchmark date tooltip on top
    },
    ["." + CLASS_BENCHMARK_HEADER]: {
        color: "gray.800",
        bg: "white",
        fontWeight: "bold",
        fontSize: "12px",
    },
};

import { createVisitDetailPageActivityUrl } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { useRouterLocale } from "src/utils/router/use-router-locale";

interface IRequest {
    parameter?: string | null;
    referrer?: string | null;
}

export const useDetailPageActivityMutation = () => {
    const { language, country } = useRouterLocale();
    const { mutatePromise } = useMutation<void, void, IRequest>(createVisitDetailPageActivityUrl(language, country));

    return mutatePromise;
};

import { Flex, Text } from "@chakra-ui/react";
import { IMasterDataItem } from "src/api/server/wf-detail.types";
import { PopoverButtonIconInfo, WfPopover } from "src/components/base/wf-popover";

interface IMasterDataItemProps extends IMasterDataItem<string> {}

export const MasterDataItem = ({ label, value, tooltip }: IMasterDataItemProps) => (
    <Flex justifyContent="space-between">
        <Flex align="center">
            {label}
            {tooltip && (
                <WfPopover content={tooltip.content} link={tooltip.link}>
                    <PopoverButtonIconInfo ariaLabel={label} ml={1} />
                </WfPopover>
            )}
        </Flex>
        <Text fontWeight="semibold">{value}</Text>
    </Flex>
);

import { BoxProps, Flex, forwardRef } from "@chakra-ui/react";
import { IWfLinkProps, WfLinkUnstyled } from "src/components/base/wf-link";
import { JUMPMARK_HEIGHT } from "./consts";

interface IProps extends Omit<IWfLinkProps, "onClick" | "onFocus"> {
    isActive?: boolean;
    onClick?: BoxProps["onClick"];
    onFocus?: BoxProps["onFocus"];
}

export const JumpMarkTab = forwardRef<IProps, "div">(({ isActive, href, children, id, onClick, onFocus }: IProps, ref) => (
    <Flex
        ref={ref}
        px={[2, 2, 3]}
        h={JUMPMARK_HEIGHT}
        as={WfLinkUnstyled}
        href={href}
        color={isActive ? "gray.800" : "gray.400"}
        _hover={{ color: "gray.800" }}
        _focus={{ color: isActive ? "gray.800" : "gray.400" }}
        borderBottom="1px solid"
        borderColor="gray.200"
        fontSize={["sm", "md"]}
        fontWeight="semibold"
        onClick={onClick}
        onFocus={onFocus}
        className="gtm-jumpmark"
        data-gtm-action={id}
    >
        <Flex align="center" borderBottom="3px solid" borderColor={isActive ? "gray.800" : "transparent"} whiteSpace="nowrap">
            {children}
        </Flex>
    </Flex>
));

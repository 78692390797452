import { HStack, List, ListItem, SkeletonText } from "@chakra-ui/react";

export const NewsListSkeleton = () => (
    <List>
        <ListItem py={2} borderBottom="1px" borderColor="gray.200">
            <HStack spacing={2}>
                <SkeletonText width={5} skeletonHeight="20px" noOfLines={1} />
                <SkeletonText noOfLines={1} skeletonHeight="20px" width="80%" />
            </HStack>
        </ListItem>
        <ListItem py={2} borderBottom="1px" borderColor="gray.200">
            <HStack spacing={2}>
                <SkeletonText width={5} skeletonHeight="20px" noOfLines={1} />
                <SkeletonText noOfLines={1} skeletonHeight="20px" width="65%" />
            </HStack>
        </ListItem>
        <ListItem py={2} borderBottom="1px" borderColor="gray.200">
            <HStack spacing={2}>
                <SkeletonText width={5} skeletonHeight="20px" noOfLines={1} />
                <SkeletonText noOfLines={2} spacing="1px" skeletonHeight="20px" width="87%" />
            </HStack>
        </ListItem>
    </List>
);

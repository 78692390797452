import { UseQueryOptions } from "react-query";
import { API_DECLARE_INTEREST_URL, API_DECLARE_INTEREST_MODAL_URL } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { useQuery } from "src/hooks/react-query/use-query";

interface ICountry {
    label: string;
    value: string;
}

export interface IDeclareInterestResponse {
    data: {
        countries: ICountry[];
    };
    dict: {
        headline: string;
        description: string;
        sendButton: string;
    };
}

export function useDeclareInterestQuery(options: UseQueryOptions<IDeclareInterestResponse>) {
    return useQuery<IDeclareInterestResponse>(API_DECLARE_INTEREST_MODAL_URL, options);
}

interface IMutationRequest {
    wikifolioId: string;
    email: string;
    country: string;
}

interface IMutationResponse {
    message: string;
}

export interface IDeclareInterestMutationError {
    [fieldName: string]: string;
}

export const useDeclareInterestMutation = () => {
    const { mutatePromise } = useMutation<IMutationResponse, IDeclareInterestMutationError, IMutationRequest>(API_DECLARE_INTEREST_URL);
    return mutatePromise;
};

import { createApiWikifolioPriceUrl } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";

export interface IPriceResponse {
    id: string;
    bid: number | null;
    ask: number | null;
    midPrice: number | null;
    isTicking: boolean;
    showMidPrice: boolean;
    calculationDate: string;
    validUntilDate: string;
    wikifolioId: string | null;
    isin: string | null;
    instrument: string | null;
    currency: string | null;
    isCurrencyConverted: boolean;
    quantityLimitBid: number;
    quantityLimitAsk: number;
}

export function useWikifolioPriceQuery(wikifolioId: string, nonPrimaryIsin?: string, retry = 0) {
    const queryParams = new URLSearchParams();
    if (nonPrimaryIsin) {
        queryParams.set("nonPrimaryIsin", nonPrimaryIsin);
    }
    return useQuery<IPriceResponse>(createApiWikifolioPriceUrl(wikifolioId), { queryParams, retry: retry });
}

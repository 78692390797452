import { ChangeEvent } from "react";
import { BoxProps, HStack, Box } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfSelect } from "src/components/base/wf-select";
import { TimeRangeButton } from "src/components/common/buttons/time-range-button/time-range-button";
import { ITimeRangeSelectItem } from "src/types/common";

interface ITimeRangeSelectProps extends Omit<BoxProps, "onChange"> {
    value: number;
    isDisabled?: boolean;
    onChange: (value: number) => void;
    items: ITimeRangeSelectItem[];
}

export const TimeRangeSelect = ({ value, isDisabled, items, onChange, ...boxProps }: ITimeRangeSelectProps) => {
    const { t } = useTranslation("common");

    const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const parsedValue = value === "Infinity" ? Infinity : isNaN(parseInt(value)) ? 0 : parseInt(value);
        onChange(parsedValue);
    };
    return (
        <Box {...boxProps}>
            <WfSelect
                size="sm"
                display={["initial", "none"]}
                value={value.toString()}
                isDisabled={isDisabled}
                onChange={handleOnChange}
                aria-label={t("labels.select-timeframe")}
            >
                {items.map(item => (
                    <option key={item.value} value={item.value} disabled={item.isDisabled}>
                        {item.labelLong}
                    </option>
                ))}
            </WfSelect>
            <HStack spacing={1} display={["none", "flex"]}>
                {items.map(item => (
                    <TimeRangeButton
                        key={item.value}
                        {...item}
                        active={value === item.value}
                        isDisabled={isDisabled || Boolean(item.isDisabled)}
                        onClick={onChange}
                    />
                ))}
            </HStack>
        </Box>
    );
};

import { BoxProps } from "@chakra-ui/react";
import { IWikifolioDetailDict } from "src/api/server/wf-detail.types";
import { Section } from "src/components/base";
import { WfHeading } from "src/components/base/wf-heading";
import { PortfolioTable } from "./portfolio-table";

interface IProps extends BoxProps {
    fullName: string;
    dict: IWikifolioDetailDict["portfolioTable"];
}

export const PortfolioTableSection = ({ fullName, dict, ...boxProps }: IProps) => {
    return (
        <Section {...boxProps}>
            <WfHeading as="h2" fontSize="2xl" mb={3}>
                {dict.title}
            </WfHeading>
            <PortfolioTable fullName={fullName} dict={dict} />
        </Section>
    );
};

import { createContext, useContext, ReactNode, useRef, useState } from "react";
import { EnumJumpMarkSection } from "./consts";

interface IJumpMarkAnchorRefsProvider {
    children: ReactNode;
}

type TRefs = Partial<Record<EnumJumpMarkSection, HTMLDivElement>>;
interface IProviderValue {
    setRef: (jumpMarkAnchor: EnumJumpMarkSection, jumpMarkRef: HTMLDivElement) => void;
    getRef: (jumpMarkAnchor: EnumJumpMarkSection) => HTMLDivElement | undefined;
    scrollName: EnumJumpMarkSection | null;
    setScrollName: (scrollToName: EnumJumpMarkSection | null) => void;
}

const JumpMarkAnchorRefsContext = createContext<IProviderValue>({} as IProviderValue);

function JumpMarkAnchorRefsProvider({ children }: IJumpMarkAnchorRefsProvider): React.ReactElement {
    const ref = useRef<TRefs>({});
    // The scrollName is used to keep track to what anchor
    // was clicked and keeps it as soon as hits scrollIntoView element.
    const [scrollName, setScrollName] = useState<EnumJumpMarkSection | null>(null);

    const getRef = (jumpMarkAnchor: EnumJumpMarkSection) => ref.current[jumpMarkAnchor];
    const setRef = (jumpMarkAnchor: EnumJumpMarkSection, jumpMarkRef: HTMLDivElement) => {
        ref.current[jumpMarkAnchor] = jumpMarkRef;
    };

    return <JumpMarkAnchorRefsContext.Provider value={{ getRef, setRef, setScrollName, scrollName }}>{children}</JumpMarkAnchorRefsContext.Provider>;
}

function useJumpMarkAnchorRefs() {
    const context = useContext(JumpMarkAnchorRefsContext);
    if (context === undefined) {
        throw new Error("useJumpMarkAnchorRefs must be used within a JumpMarkAnchorRefsContext");
    }
    return context;
}

export { JumpMarkAnchorRefsProvider, useJumpMarkAnchorRefs };

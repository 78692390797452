import { ReactNode } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { FullSizeFlexCentered } from "src/components/base";
import { IconHidden } from "src/components/icons";

interface IErrorStateProps {
    children?: ReactNode;
}

export const ErrorState = ({ children }: IErrorStateProps) => {
    const { t } = useTranslation("common");

    return (
        <Box pos="relative">
            {children}
            <FullSizeFlexCentered>
                <Flex
                    position="relative"
                    justify="center"
                    align="center"
                    direction="column"
                    px={8}
                    pt={8}
                    pb={3}
                    bg="white"
                    boxShadow="sm"
                    rounded="8px"
                >
                    <IconHidden pos="absolute" top="-70px" boxSize="136px" />
                    <Text textAlign="center" fontSize="lg" fontWeight="semibold" bg="white" p={1} mt={1}>
                        {t("no-data")}
                    </Text>
                </Flex>
            </FullSizeFlexCentered>
        </Box>
    );
};

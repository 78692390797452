import React from "react";
import { HStack, List, ListItem, Text } from "@chakra-ui/react";
import { isToday } from "date-fns";
import { useTranslation } from "next-i18next";
import { Span } from "src/components/base";
import { WfLinkGhost } from "src/components/base/wf-link";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";
import { ILink } from "src/types/common";

export interface INewsListItem {
    date: string;
    link: ILink;
}
export interface INewsListProps {
    items: INewsListItem[];
}

export const NewsList = ({ items }: INewsListProps) => {
    const { t } = useTranslation("common");
    const { formatWeekDayShortWithTimeShort, formatTimeShort } = useDateTimeFormatter();
    const formatArticleTime = (time: string) => {
        const articleTime = new Date(time);
        const isTodayDate = isToday(articleTime);

        if (isTodayDate) {
            return `${t("today")}, ${formatTimeShort(articleTime)}`;
        }

        return formatWeekDayShortWithTimeShort(articleTime);
    };

    return (
        <List>
            {items.map(({ date, link }) => (
                <ListItem key={`${link.url}${date}`} py={2} borderBottom="1px" borderColor="gray.200">
                    <HStack spacing={2}>
                        <Span flexShrink={0} fontSize="sm" w={6}>
                            {formatArticleTime(date)}
                        </Span>
                        <WfLinkGhost href={link.url}>
                            <Text fontSize="sm" noOfLines={2}>
                                {link.name}
                            </Text>
                        </WfLinkGhost>
                    </HStack>
                </ListItem>
            ))}
        </List>
    );
};

import { Divider, StackDivider, Text, VStack } from "@chakra-ui/react";
import { TOptions } from "i18next";
import { useTranslation } from "next-i18next";
import { ICertificateData } from "src/api/server/wf-detail.types";
import { IconContinue } from "src/components/icons";
import { useRouterLocale } from "src/utils/router/use-router-locale";
import { CBDetailsAccordion } from "../components/details-accordion";
import { CBHeader } from "../components/header";
import { CBInvestedCapital } from "../components/invested-capital";
import { CBPrices } from "../components/prices";
import { CBRatioTabBar } from "../components/ratio-tab-bar";
import { CBCertificateFee, CBEmission, CBLiquidationFigure, CBPerformanceFee, CBTradingVolume } from "../components/statistics";
import { useCertificateBoxContext } from "../context";
import { certificateRatio } from "../utils";

const CERTIFICATE_COUNTRY_AVAILABILITY_TEST_ID = "certificate-country-availability";

const generateAvailableLicensedRatiosTranslationOptions = (countryText: string, certificates: readonly ICertificateData[]) => {
    const translationOptions: TOptions = {
        country: countryText,
    };

    // create indices (used in wf-detail.json) with ratios of licensed certificates (e.g., {{0}}: "10:1", {{1}}: "100:1").
    certificates.filter(cert => cert.isLicensed).forEach((cert, i) => (translationOptions[i] = certificateRatio(cert.exchangeRatioMultiplier)));

    return translationOptions;
};

export const CertificateBoxNotLicensedSingle = () => {
    const {
        data: { certificates },
    } = useCertificateBoxContext();
    const { country } = useRouterLocale();
    const { t } = useTranslation(["wf-detail"]);

    const countryText = t(`countries.${country.toLowerCase()}`);
    const translationOptions = generateAvailableLicensedRatiosTranslationOptions(countryText, certificates);
    const areMultiLicensedCertificates = translationOptions[0] && translationOptions[1];

    return (
        <>
            <CBHeader />
            <CBRatioTabBar />
            <CBPrices />
            <CBInvestedCapital />
            <CBDetailsAccordion>
                <VStack spacing={2} divider={<StackDivider borderColor="gray.100" />} px={2}>
                    <VStack w="100%">
                        <CBEmission />
                        <CBCertificateFee />
                        <CBPerformanceFee />
                        <CBLiquidationFigure />
                        <CBTradingVolume />
                    </VStack>
                </VStack>
            </CBDetailsAccordion>
            <Divider borderColor="gray.100" />
            <VStack spacing={2} mt={4}>
                <IconContinue boxSize={12} />
                <Text fontSize="sm" textAlign="center" color="gray.800" data-test-id={CERTIFICATE_COUNTRY_AVAILABILITY_TEST_ID}>
                    {t(`certificate.country-availability${areMultiLicensedCertificates ? "-multi" : ""}`, {
                        ...translationOptions,
                    })}
                </Text>
            </VStack>
        </>
    );
};

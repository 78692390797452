import { Button } from "src/components/base/button";

interface ITimeRangeButton {
    active: boolean;
    label: string;
    value: number;
    isDisabled: boolean;
    onClick: (value: number) => void;
}

export const TimeRangeButton = ({ label, value, active, isDisabled, onClick }: ITimeRangeButton) =>
    active ? (
        <Button
            size="xs"
            variant="solid"
            rounded="16px"
            userSelect="none"
            cursor={isDisabled ? "default" : "initial"}
            px={2}
            opacity={isDisabled ? 0.5 : 1}
        >
            {label}
        </Button>
    ) : (
        <Button size="xs" variant="ghost" rounded="16px" color="gray.500" px={2} isDisabled={isDisabled} onClick={() => onClick(value)}>
            {label}
        </Button>
    );

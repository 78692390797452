import { IBenchmarkProps, WikifolioDetailBenchmark } from "src/components/common/benchmark/wikifolio-detail-benchmark";
import { setIsBenchmarkReady } from "./store/benchmark-ready-store";

export const WikifolioDetailBenchmarkWrapper = ({ wikifolioId, wikifolioStatus, creationDate, dict, baseCurrency, ...boxProps }: IBenchmarkProps) => {
    const onChartReady = () => {
        setIsBenchmarkReady();
    };

    return (
        <WikifolioDetailBenchmark
            wikifolioId={wikifolioId}
            wikifolioStatus={wikifolioStatus}
            creationDate={creationDate}
            baseCurrency={baseCurrency}
            dict={dict}
            onChartReady={onChartReady}
            {...boxProps}
        />
    );
};

import { API_COPY_ISIN_MODAL_URL } from "src/consts/urls";
import { useQuery } from "src/hooks/react-query/use-query";
export interface ICopyIsinModalResponse {
    headline1: string;
    description1: string;
    headline2: string;
    description2: string;
    hideModalCheckbox: string;
}

export const useCopyIsinModalQuery = (isModalOpen: boolean) =>
    useQuery<ICopyIsinModalResponse>(API_COPY_ISIN_MODAL_URL, {
        enabled: isModalOpen,
    });

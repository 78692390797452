import { Box, Flex, Text } from "@chakra-ui/react";
import { CopyIsinButton } from "src/components/common/buttons/copy-isin-button/copy-isin-button";
import { CopyIsinModal } from "src/components/modals/copy-isin-modal/copy-isin-modal";
import { useCertificateBoxContext } from "../context";
import { CBLabel } from "./label";

export const CBIsin = () => {
    const { dict, data, selectedCertificate: certificate } = useCertificateBoxContext();
    const isin = certificate.isin;

    return (
        <Flex w="100%" align="center" justify="space-between">
            <Box mr={3}>
                <CBLabel>{dict.isin}</CBLabel>
                <Text as="strong" className="gtm-copy-isin">
                    {isin}
                </Text>
            </Box>
            <CopyIsinModal wikifolioId={data.wikifolio.id} wikifolioShortDescription={data.wikifolio.shortDescription}>
                {openModal => (
                    <CopyIsinButton onClick={openModal} size="xs" variant="outline" isin={isin}>
                        {dict.copy}
                    </CopyIsinButton>
                )}
            </CopyIsinModal>
        </Flex>
    );
};

import { Box, Flex } from "@chakra-ui/react";
import { IWikifolioWatchlistCtaData } from "src/api/server/wf-detail.types";
import { ArrowLink } from "src/components/base/arrow-link";
import { WatchlistButton } from "src/components/common/buttons/watchlist-button/watchlist-button";
import { IconWikifolioCta } from "src/components/icons";
import { useWikifolioStore } from "src/stores/wikifolio-store";

interface IWatchlistCtaProps {
    wikifolioId: string;
    wikifolioShortDescription: string;
    data: IWikifolioWatchlistCtaData;
}

export const WatchlistCta = ({ wikifolioId, wikifolioShortDescription, data: { headline, description, link } }: IWatchlistCtaProps) => {
    const { watchlist, setOnWatchlist } = useWikifolioStore();
    const isOnWatchlist = watchlist[wikifolioId] ?? false;

    return (
        <Flex direction={["column", "column", "row-reverse"]} alignItems="center" py={[6, 6, 8]} gap={[3, 3, 3, 14]}>
            <Box>
                <IconWikifolioCta width={["143px", "143px", "263px"]} height={["80px", "80px", "146px"]} opacity={0.5} />
            </Box>
            <Flex direction="column" gap={3}>
                <Box fontSize={["3xl", "3xl", "4xl"]} fontWeight={["bold", "bold", "heavy"]}>
                    {headline}
                </Box>
                <Box fontSize={["xl", "xl", "2xl"]}>{description}</Box>
                <Flex direction={["column", "row"]} gap="3" alignItems={["initial", "center"]}>
                    <WatchlistButton
                        size="md"
                        wikifolioId={wikifolioId}
                        wikifolioShortDescription={wikifolioShortDescription}
                        isOnWatchlist={isOnWatchlist}
                        setOnWatchlist={setOnWatchlist}
                    />
                    <ArrowLink href={link.url} fontWeight="semibold" target={link.target} textAlign={["center", "initial"]}>
                        {link.name}
                    </ArrowLink>
                </Flex>
            </Flex>
        </Flex>
    );
};

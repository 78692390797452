import { Fragment } from "react";
import { Box, Divider, Grid } from "@chakra-ui/react";
import { IWikifolioKeyFigure } from "src/api/server/wf-detail.types";
import { Section } from "src/components/base";
import { Ranking } from "src/components/common/ranking/ranking";

interface ITopKpiProps {
    keyFigures: IWikifolioKeyFigure[];
}

export const TopKpi = ({ keyFigures }: ITopKpiProps) => {
    return (
        <Section display="flex" justifyContent="center">
            <Grid
                position="relative"
                templateColumns={["1fr 1fr", "repeat(3, 1fr auto) 1fr", "repeat(4, 1fr auto) 1fr"]}
                rowGap={[4, "unset"]}
                w="100%"
                justifyContent={["initial", "center"]}
                zIndex={1}
            >
                {keyFigures.map((keyFigure, index) => {
                    const isLast = index === keyFigures.length - 1;
                    return (
                        <Fragment key={index}>
                            <Ranking
                                key={index}
                                display={isLast ? ["none", "none", "flex"] : ["flex"]}
                                size="sm"
                                data={keyFigure.ranking}
                                tooltip={keyFigure.tooltip}
                            />
                            <Divider display={["none", "block"]} orientation="vertical" borderColor="gray.100" />
                        </Fragment>
                    );
                })}

                {/* Display 2 divider lines between 4 ranking kpi on Mob and Tab */}
                <Box zIndex={-1} position="absolute" height="100%" width="100%" display={["block", "none"]}>
                    <Divider orientation="horizontal" borderColor="gray.100" pt={8} />
                    <Divider position="absolute" orientation="vertical" borderColor="gray.100" top={0} ml="-1px" left="50%" />
                </Box>
            </Grid>
        </Section>
    );
};
